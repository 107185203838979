import { ChangeEvent, useMemo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import {
  type TextFieldProps as BaseTextFieldProps,
  TextField as BaseTextField,
} from '@mui/material'

import { type BaseFormControlProps } from './index.types'
import { getErrorMessage, splitProps, useReadOnlyForm } from '../utils'
import { useTranslation } from '../../utils/hooks'

export type TextFieldProps = BaseFormControlProps &
  Omit<BaseTextFieldProps, 'required' | 'error'>

export function TextField({
  name,
  required,
  helperText,
  type = 'text',
  label,
  hideLabel = false,
  setValueAs,
  errorFieldName,
  readOnly,
  ...rest
}: TextFieldProps) {
  const { register, getFieldState } = useFormContext()
  const { error } = getFieldState(name)

  const { registerProps, fieldProps } = useMemo(() => splitProps(rest), [rest])
  const [t] = useTranslation()

  const isReadOnlyForm = useReadOnlyForm().readOnly
  const isReadOnly = readOnly === undefined ? isReadOnlyForm : readOnly

  const readOnlyProps = isReadOnly
    ? {
        readOnly: true,
      }
    : {}
  const readOnlyVariantProps = isReadOnly ? 'standard' : undefined

  const errorOrHelper = useMemo(() => {
    if (error)
      return getErrorMessage(error, t, {
        field: errorFieldName || String(label),
        ...registerProps,
      })
    return helperText
  }, [error, helperText, t, errorFieldName, label, registerProps])

  return (
    <BaseTextField
      {...register(name, {
        ...registerProps,
        required,
        // trim the value by default
        setValueAs,
      })}
      type={type}
      InputLabelProps={{ required: !!required }}
      {...fieldProps}
      label={!hideLabel && label}
      helperText={errorOrHelper}
      error={!!error}
      InputProps={{
        ...fieldProps.InputProps,
        ...readOnlyProps,
      }}
      variant={readOnlyVariantProps || rest.variant}
    />
  )
}

export type ControlledTextFieldProps = TextFieldProps & {
  mask?: (
    val: string,
    e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => string
}

/** allows you to pass a mask function. If we need to add other functionality that requires a controlled componenent we can do so here */
export function ControlledTextField({
  name,
  required,
  helperText,
  mask,
  InputProps,
  type = 'text',
  label,
  hideLabel = false,
  setValueAs = (v) => v,
  readOnly,
  errorFieldName,
  ...rest
}: ControlledTextFieldProps) {
  const { registerProps, fieldProps } = splitProps(rest)
  const { t } = useTranslation()

  const isReadOnlyForm = useReadOnlyForm().readOnly
  const isReadOnly = readOnly === undefined ? isReadOnlyForm : readOnly

  const readOnlyProps = isReadOnly
    ? {
        readOnly: true,
      }
    : {}
  const readOnlyVariantProps = isReadOnly ? 'standard' : undefined

  return (
    <Controller
      name={name}
      rules={{
        ...registerProps,
        required,
      }}
      render={({
        field: { onChange, value = '', ref, ...field },
        fieldState: { error },
      }) => {
        const errorOrHelper = error
          ? getErrorMessage(error, t, {
              field: errorFieldName || String(label),
              ...registerProps,
            })
          : helperText
        return (
          <BaseTextField
            InputLabelProps={{
              required: !!required,
              shrink: value ? true : undefined,
            }}
            value={mask?.(value) || setValueAs(value)}
            error={!!error}
            type={type}
            variant={readOnlyVariantProps || rest.variant}
            onChange={(e) => {
              if (isReadOnly) return
              mask?.(e.target.value, e)
              onChange(setValueAs(e.target.value))
            }}
            helperText={errorOrHelper}
            {...field}
            {...fieldProps}
            label={!hideLabel && label}
            InputProps={{
              ...InputProps,
              ...readOnlyProps,
              inputRef: ref,
            }}
          />
        )
      }}
    />
  )
}
