import { createContext, useMemo } from 'react'
import {
  FormProvider as BaseFormProvider,
  FieldValues,
  FormProviderProps,
} from 'react-hook-form'

export const ReadOnlyContext = createContext({ readOnly: false })

const FormProvider = <TFieldValues extends FieldValues, TContext = any>(
  props: FormProviderProps<TFieldValues, TContext> & {
    readOnly?: boolean
  },
) => {
  const { children, readOnly, ...methods } = props

  const contextValue = useMemo(
    () => (readOnly ? { readOnly: true } : { readOnly: false }),
    [readOnly],
  )

  return (
    <ReadOnlyContext.Provider value={contextValue}>
      <BaseFormProvider {...methods}>{children}</BaseFormProvider>
    </ReadOnlyContext.Provider>
  )
}

export default FormProvider
