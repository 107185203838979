import { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  CopyButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemSecondaryAction,
  Stack,
  Typography,
  useNotify,
} from '@valerahealth/ui-components'
import {
  ChatBubbleOutline,
  EmailOutlined,
  PeopleOutline,
  ScreenShareOutlined,
  SettingsOutlined,
  StopScreenShareOutlined,
} from '@valerahealth/ui-components/icons'
import {
  MicAudioLevelIndicator,
  MicSourceSelect,
  PublisherMuteControls,
  SpeakerSourceSelect,
  VideoSourceSelect,
} from 'components/AudioVideoControls'
import { LabeledFab } from 'components/LabeledFab'
import { ParticipantListItem } from 'components/participant/ListItem'
import { useReduxDispatch, useReduxSelector } from 'store'
import {
  selectCallInfo,
  selectOwnParticipantInfo,
  selectParticipantInfo,
} from 'store/selectors'
import { telehealthApi } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import { actions } from 'store/appSlice'
import { getCallEndRoute } from 'routes/utils'
import { VideoState } from './utils'

const EmailInvite = ({ email }: { email: string }) => {
  const notify = useNotify()
  const { roomId } = useParams<{ roomId: string }>()
  const [invite, { isLoading }] = telehealthApi.useSendEmailInviteMutation()

  return (
    <ListItemSecondaryAction>
      <IconButton
        isLoading={isLoading}
        onClick={async () => {
          if (isLoading) return
          const res = await invite({
            roomId: roomId!,
            email,
          })
          if ('error' in res) {
            notify({
              severity: 'error',
              message:
                ('data' in res.error && res.error.data.message) ||
                'Failed to send email invite',
            })
          } else {
            notify({ severity: 'success', message: `Invite sent to ${email}` })
          }
        }}
        title="Email Invite"
      >
        <EmailOutlined />
      </IconButton>
    </ListItemSecondaryAction>
  )
}

const ParticipantsButton = () => {
  const [isOpen, setIsOpen] = useState(false)
  const link = useReduxSelector(selectCallInfo)?.link
  const me = useReduxSelector(selectOwnParticipantInfo)
  const other = useReduxSelector(selectParticipantInfo)
  return (
    <>
      <LabeledFab
        size="small"
        label="Participants"
        onClick={() => setIsOpen(true)}
      >
        <PeopleOutline />
      </LabeledFab>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="sm"
        onClose={() => setIsOpen(false)}
      >
        <DialogTitle>Participants</DialogTitle>
        <DialogContent dividers>
          {me?.role === 'organizer' && link && (
            <Box>
              <Typography gutterBottom variant="subtitle1" color="GrayText">
                Copy Session Invite
              </Typography>
              <Stack direction="row" gap={1} alignItems="center">
                <CopyButton onClick={() => link} />
                <Typography>{link}</Typography>
              </Stack>
            </Box>
          )}
          <Typography gutterBottom variant="subtitle1" color="GrayText">
            Participants
          </Typography>
          <List>
            {me && <ParticipantListItem participant={me} />}
            {other && (
              <ParticipantListItem participant={other}>
                {other.role === 'owner' && other.email && (
                  <EmailInvite email={other.email} />
                )}
              </ParticipantListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const SettingsButton = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <LabeledFab size="small" label="Settings" onClick={() => setIsOpen(true)}>
        <SettingsOutlined />
      </LabeledFab>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="sm"
        onClose={() => setIsOpen(false)}
      >
        <DialogTitle>Settings</DialogTitle>
        <DialogContent
          dividers
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <VideoSourceSelect />
          <SpeakerSourceSelect />
          <MicSourceSelect />
          <MicAudioLevelIndicator />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const EndCallButton = ({
  session,
  participantConnection,
}: Pick<VideoState, 'participantConnection' | 'session'>) => {
  const navigate = useNavigate()
  const [isEndingCallForPatient, setIsEndingCall] = useState(false)
  const myRole = useReduxSelector(selectOwnParticipantInfo)?.role
  return (
    <Button
      disabled={isEndingCallForPatient}
      startIcon={
        isEndingCallForPatient ? (
          <CircularProgress size={20} color="inherit" />
        ) : undefined
      }
      onClick={async () => {
        if (myRole === 'organizer' && session && participantConnection) {
          await new Promise((res) => {
            setIsEndingCall(true)
            session.forceDisconnect(participantConnection, res)
          })
          setIsEndingCall(false)
        }
        navigate(getCallEndRoute())
      }}
    >
      {myRole === 'organizer' ? 'End Call' : 'Hang Up'}
    </Button>
  )
}

export function VideoCallControlBar({
  toggleScreenShare,
  isScreenSharing,
  session,
  participantConnection,
}: {
  isScreenSharing: boolean
  toggleScreenShare: () => void
} & Pick<VideoState, 'participantConnection' | 'session'>) {
  const dispatch = useReduxDispatch()
  const toggleChat = () => dispatch(actions.toggleChat())
  const unreadCount = useReduxSelector((state) => state.app.unreadChatMsgCount)

  return (
    <Box
      sx={{
        gridColumnStart: 1,
        gridColumnEnd: 5,
        gridRowStart: 2,
        alignSelf: 'end',
        opacity: unreadCount ? 1 : 0,
        transition: 'opacity 0.2s',
        '&:hover': {
          opacity: 1,
        },
        zIndex: 1000,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          mt: '5vh',
          p: 1,
          bgcolor: 'rgba(0,0,0,078)',
          color: (theme) => theme.palette.grey[200],
        }}
      >
        <Stack direction="row" justifyItems="center" gap={2}>
          <SettingsButton />
          <PublisherMuteControls />
        </Stack>
        <EndCallButton
          session={session}
          participantConnection={participantConnection}
        />
        <Stack direction="row" justifyItems="center" gap={2}>
          <LabeledFab
            size="small"
            label={isScreenSharing ? 'Stop Present' : 'Present'}
            onClick={toggleScreenShare}
          >
            {isScreenSharing ? (
              <StopScreenShareOutlined />
            ) : (
              <ScreenShareOutlined />
            )}
          </LabeledFab>
          <ParticipantsButton />
          <LabeledFab
            size="small"
            label="Chat"
            onClick={toggleChat}
            color={unreadCount ? 'primary' : undefined}
          >
            <Badge color="success" badgeContent={unreadCount} max={9}>
              <ChatBubbleOutline />
            </Badge>
          </LabeledFab>
        </Stack>
      </Stack>
    </Box>
  )
}
