import { TelehealthParticipant } from 'api'

export const getParticipantName = ({
  firstName,
  lastName,
  preferredName,
}: TelehealthParticipant) => `${preferredName || firstName} ${lastName}`

export const getParticipantInitials = ({
  firstName,
  lastName,
  preferredName,
}: TelehealthParticipant) =>
  `${preferredName?.[0] || firstName[0]}${lastName[0]}`
