import { Palette } from '@mui/material/styles'
import { memoize } from 'lodash'

export function parseRgb(
  rgb: string,
): null | { r: number; g: number; b: number; a?: number } {
  const result = /rgba?\(([ ,\d.]+)\)/.exec(rgb)
  if (result) {
    const [r, g, b, a] = result[1]!.split(',').map((s) => Number(s))
    if (Number.isNaN(r! + g! + b!)) return null
    return {
      r: r!,
      g: g!,
      b: b!,
      a,
    }
  }
  return result
}

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{1,2})$/i.exec(hex)
  const twoDigit = (s: string) => (s.length === 1 ? s + s : s)
  return result
    ? {
        r: parseInt(twoDigit(result[1]!), 16),
        g: parseInt(twoDigit(result[2]!), 16),
        b: parseInt(twoDigit(result[3]!), 16),
      }
    : null
}
const noAlph = (bg: number, a: number, fg: number) => (1 - a) * bg + a * fg
/** takes a color with alpha and changes it to one without */
export const removeAlpha = memoize(
  function removeAlpha(
    this: Palette,
    {
      color,
      alpha = 1,
      background,
    }: {
      /** hex, rgb, rgba */
      color: string
      /* the alpha, required if color is rgb or hex */
      alpha?: number
      /* hex, rgb, rgba - the background, defaults to theme.paper.background */
      background?: string
    },
  ) {
    const result = (parseRgb(color) || hexToRgb(color)) as ReturnType<
      typeof parseRgb
    >
    if (!result) return null
    const { r, g, b, a = alpha } = result
    const backgroundColor = background || this.background.paper
    const bg = (parseRgb(backgroundColor) || hexToRgb(backgroundColor))!

    return `rgb(${noAlph(bg.r, a, r)},${noAlph(bg.g, a, g)},${noAlph(
      bg.b,
      a,
      b,
    )})`
  },
  ({ color, alpha, background }) => `${color}${alpha}${background}`,
)

export const withAlpha = memoize(
  function withAlpha(this: Palette, color: string, alpha: number) {
    const result = (parseRgb(color) || hexToRgb(color)) as ReturnType<
      typeof parseRgb
    >
    if (!result) return ''
    const { r, g, b } = result

    return `rgba(${r},${g},${b},${alpha})`
  },
  (color, alpha) => `${color}${alpha}`,
)
