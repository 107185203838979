import {
  CircularProgress,
  Box,
  type BoxProps,
  CircularProgressProps,
} from '@mui/material'

export type CenteredSpinnerProps = BoxProps & {
  circularProgressProps?: CircularProgressProps
}

export default function CenteredSpinner({
  sx,
  circularProgressProps,
  ...rest
}: CenteredSpinnerProps) {
  return (
    <Box
      {...rest}
      sx={{
        height: '100%',
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <CircularProgress {...circularProgressProps} />
    </Box>
  )
}
