export default function Sentiment1() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.3 21.35C32.0667 21.35 32.7083 21.0917 33.225 20.575C33.7417 20.0583 34 19.4167 34 18.65C34 17.8833 33.7417 17.2417 33.225 16.725C32.7083 16.2083 32.0667 15.95 31.3 15.95C30.5333 15.95 29.8917 16.2083 29.375 16.725C28.8583 17.2417 28.6 17.8833 28.6 18.65C28.6 19.4167 28.8583 20.0583 29.375 20.575C29.8917 21.0917 30.5333 21.35 31.3 21.35ZM16.7 21.35C17.4667 21.35 18.1083 21.0917 18.625 20.575C19.1417 20.0583 19.4 19.4167 19.4 18.65C19.4 17.8833 19.1417 17.2417 18.625 16.725C18.1083 16.2083 17.4667 15.95 16.7 15.95C15.9333 15.95 15.2917 16.2083 14.775 16.725C14.2583 17.2417 14 17.8833 14 18.65C14 19.4167 14.2583 20.0583 14.775 20.575C15.2917 21.0917 15.9333 21.35 16.7 21.35ZM17.7 31.05H30.35V28.6H17.7V31.05ZM24 44C21.2667 44 18.6833 43.475 16.25 42.425C13.8167 41.375 11.6917 39.9417 9.875 38.125C8.05833 36.3083 6.625 34.1833 5.575 31.75C4.525 29.3167 4 26.7167 4 23.95C4 21.2167 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05833 11.65 9.875 9.85C11.6917 8.05 13.8167 6.625 16.25 5.575C18.6833 4.525 21.2833 4 24.05 4C26.7833 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7333 43.475 29.3167 42.425 31.75C41.375 34.1833 39.95 36.3083 38.15 38.125C36.35 39.9417 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44ZM24 41C28.7333 41 32.75 39.3417 36.05 36.025C39.35 32.7083 41 28.7 41 24C41 19.2667 39.35 15.25 36.05 11.95C32.75 8.65 28.7333 7 24 7C19.3 7 15.2917 8.65 11.975 11.95C8.65833 15.25 7 19.2667 7 24C7 28.7 8.65833 32.7083 11.975 36.025C15.2917 39.3417 19.3 41 24 41Z"
        fill="currentColor"
      />
    </svg>
  )
}
