import { SerializedError } from '@reduxjs/toolkit'
import {
  fetchBaseQuery,
  createApi,
  BaseQueryFn,
  FetchArgs,
} from '@reduxjs/toolkit/query/react'
import { AUTH_REDUCER_KEY, RootStateWithAuth } from '@valerahealth/redux-auth'
import {
  CMChannelItem,
  UnknownEvent,
  WSCommunicationItemCreatedEvent,
} from '@valerahealth/rtk-query'
import { sortOnProperty } from '@valerahealth/ui-components'
import { isProviderRoute } from 'routes/utils'
import { io } from 'socket.io-client'
import { AppState, reducerPath, actions } from 'store/appSlice'

export type TelehealthParticipant = {
  id: string
  firstName: string
  preferredName?: string
  lastName: string
  picture?: string | null
  email?: string | null
  /** organizer=provider owner=patient */
  role: 'organizer' | 'owner'
}

export type TelehealthCallInfo = {
  isWellness: boolean
  link: string
  programId: string
  progressNotesActive?: boolean
  // roomId: string
  // Don't use this as it may return an expired session
  // sessionId: string
  participants: TelehealthParticipant[]
}

export type ConfirmTelehealthOTPReq = {
  code: string
  phone: string
  roomId: string
}

export type ConfirmTelehealthOTPRes = {
  roomId: string
  sessionId: string
  participantId: string
  accessToken: string
}

export type SendTelehealthOTPReq = {
  phone: string
  roomId: string
}

export type GetCallCredentailsRes = {
  /** the tokbox api key */
  apiKey: string
  openTokSessionId: string
  /** the tokbox token to connect to the openTokSessionId */
  token: string
  sessionId: string
  roomId: string
}

export type GetCallCredentialsReq = {
  sessionId: string
  roomId: string
}

export type WebsocketEvents = Array<
  WSCommunicationItemCreatedEvent | UnknownEvent
>

export type TelehealthChatMessageItem = {
  id: string
  body: string //message
  sender: 'Patient' | 'CareManager'
  senderId: string
  createdAt: string
}

type SessionParams = { sessionId: string; roomId: string }

export type FeedbackTag = {
  type: string
  name: string
  freetext?: boolean
}
export type FeedbackConfigRes = {
  needToImproveRate: 3
  videoSessionTimeSec: 10
  tags: FeedbackTag[]
}

export type FeedbackInput = {
  rating: number
  tags?: FeedbackTag[]
}

export const CALL_CREDENTIALS_FIXED_CACHE_KEY = 'CALL_CREDENTIALS'

const channelItemToChatMessage = (
  item: CMChannelItem,
): TelehealthChatMessageItem => {
  const { id, message, sender, ts } = item
  return {
    id,
    body: message ?? '',
    sender: sender?.type === 'Patient' ? 'Patient' : 'CareManager',
    senderId: sender?.id ?? '',
    createdAt: ts,
  }
}

type TelehealthApiServerError = {
  data: {
    statusCode?: number
    error?: string
    message?: string
    meta?: any
    validation?: any
  }
}

export const telehealthApi = createApi({
  reducerPath: 'telehealthApi',
  keepUnusedDataFor: 120,
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${process.env.CARE_MANAGER_API_DOMAIN}/api`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootStateWithAuth & {
        [reducerPath]: AppState
      }
      const accessToken =
        state[AUTH_REDUCER_KEY].session?.idToken.jwt ||
        state[reducerPath].patientAuth?.token
      headers.set('Authorization', `Bearer ${accessToken}`)
      return headers
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, TelehealthApiServerError, {}>,
  tagTypes: ['ROOM_INFO', 'CALL_CREDENTIALS', 'CHAT_MESSAGE'],
  endpoints: (build) => ({
    /** TELEHEALTH ENDPOINTS */

    sendTelehealthOTP: build.mutation<void, SendTelehealthOTPReq>({
      query: (body) => ({
        method: 'POST',
        url: `/telehealth/auth?type=sms`,
        body,
      }),
    }),
    confirmTelehealthOTP: build.mutation<
      ConfirmTelehealthOTPRes,
      ConfirmTelehealthOTPReq
    >({
      query: (body) => ({
        method: 'POST',
        url: `/telehealth/auth/verify`,
        body,
      }),
    }),
    getTelehealthCallInfo: build.query<
      TelehealthCallInfo,
      { sessionId: string; roomId: string }
    >({
      // 1 week... basically a permenant cache this (its in session storage)
      keepUnusedDataFor: 604800,
      providesTags: ['ROOM_INFO'],
      query: ({ roomId, sessionId }) => ({
        url: `/telehealth/room/${roomId}/session/${sessionId}/waiting`,
      }),
    }),
    getCallCredentials: build.mutation<
      GetCallCredentailsRes,
      GetCallCredentialsReq
    >({
      query: (body) => ({
        method: 'POST',
        url: '/telehealth/enter',
        body,
      }),
      transformResponse: (
        res: Omit<GetCallCredentailsRes, 'roomId'>,
        meta,
        { roomId },
      ): GetCallCredentailsRes => {
        return {
          ...res,
          roomId, //store on res for convenience
        }
      },
    }),
    getChatMessages: build.query<TelehealthChatMessageItem[], SessionParams>({
      providesTags: ['CHAT_MESSAGE'],
      query: ({ roomId, sessionId }) => ({
        url: `/telehealth/room/${roomId}/session/${sessionId}/chat`,
      }),
      transformResponse: (value: TelehealthChatMessageItem[]) =>
        sortOnProperty(value, 'createdAt', 'asc'),
      async onCacheEntryAdded(
        { roomId },
        {
          getState,
          dispatch,
          cacheDataLoaded,
          cacheEntryRemoved,
          updateCachedData,
        },
      ) {
        const state = getState() as unknown as RootStateWithAuth & {
          [reducerPath]: AppState
        }
        const token =
          state[AUTH_REDUCER_KEY].session?.idToken.jwt ||
          state[reducerPath].patientAuth?.token
        const ws = io(`https://${process.env.CARE_MANAGER_API_DOMAIN}`, {
          transports: ['websocket', 'polling'],
          auth: { token },
          query: {
            roomId,
            type: 'telehealth',
          },
        })
        try {
          await cacheDataLoaded

          ws.on('event', (data: WebsocketEvents) => {
            const messages = data
              .filter(
                (v): v is WSCommunicationItemCreatedEvent => v.event === 'communication_item_created',
              )
              .filter(
                (v) => v.data.channelItem.domainEvent === 'chat_message_sent',
              )
              .map(({ data }) => channelItemToChatMessage(data.channelItem))
            if (messages.length) {
              updateCachedData((draft) => {
                const uniqueMessages = messages
                  // ignore if message already in draft (we are inserting from send message mutation )
                  .filter(({ id }) => !draft.some((v) => v.id === id))
                draft.push(...uniqueMessages)
                if (
                  // if we have a new message and chat is not open then increment unread
                  uniqueMessages.length &&
                  !(
                    getState() as unknown as RootStateWithAuth & {
                      [reducerPath]: AppState
                    }
                  )[reducerPath].isChatOpen
                ) {
                  dispatch(actions.incrementUnreadChatMessages())
                }
              })
            }
          })
        } catch (e) {
          console.error(e)
        }
        await cacheEntryRemoved
        ws.disconnect()
      },
    }),
    sendChatMessage: build.mutation<
      CMChannelItem,
      SessionParams & { message: string }
    >({
      query: ({ roomId, sessionId, message }) => ({
        method: 'post',
        url: `/telehealth/room/${roomId}/session/${sessionId}/chat`,
        body: {
          body: message,
        },
      }),
      async onQueryStarted(payload, { dispatch, getState, queryFulfilled }) {
        const res = await queryFulfilled
          .catch(() => ({
            data: null,
          }))
          .then((r) => r.data)
        if (res) {
          telehealthApi.util
            .selectInvalidatedBy(getState() as any, ['CHAT_MESSAGE'])
            .forEach(({ endpointName, originalArgs }) => {
              dispatch(
                telehealthApi.util.updateQueryData(
                  endpointName as 'getChatMessages',
                  originalArgs,
                  (draft) => {
                    draft.push(channelItemToChatMessage(res))
                  },
                ),
              )
            })
        }
      },
    }),
    sendEmailInvite: build.mutation<void, { roomId: string; email: string }>({
      query: (body) => ({
        method: 'POST',
        url: '/telehealth/sendemail',
        body,
      }),
    }),
    // doesnt work
    terminateSession: build.mutation<void, SessionParams>({
      query: (body) => ({
        method: 'POST',
        url: '/telehealth/terminate',
        body,
      }),
    }),

    //feedback
    getFeedbackConfig: build.query<FeedbackConfigRes, void>({
      query: () => ({
        method: 'GET',
        url: '/telehealth/feedback/config',
      }),
    }),

    postFeedback: build.mutation<void, FeedbackInput>({
      query: (body) => ({
        method: 'POST',
        url: '/telehealth/feedback',
        body: {
          source: 'web',
          ...body,
        },
      }),
    }),
  }),
})

export type ErrorCodeOverrides = {
  'Missing authentication'?: string
  participant_already_connected?: string
  session_expired?: string
  session_not_started_or_activated?: string
  session_already_terminated?: string
  ctm_not_in_care_team?: string
  organizer_already_added?: string
}
export type ErrorCodes = keyof ErrorCodeOverrides

export const getErrorDetails = (
  error: TelehealthApiServerError | SerializedError,
  fallback?: string,
  overrides?: ErrorCodeOverrides,
) => {
  const message = 'data' in error ? error.data.message : error.message

  switch (message) {
    case 'Missing authentication':
      return (
        overrides?.['Missing authentication'] ||
        'You need to be signed in to access this page.'
      )
    case 'participant_already_connected':
      return (
        overrides?.participant_already_connected ||
        'You are already connected to the video session in another tab or device.'
      )
    case 'session_expired':
      return (
        overrides?.session_expired ||
        'Video Session Expired. Please close the window and wait for your provider to initiate a new call.'
      )
    case 'session_not_started_or_activated':
      return (
        overrides?.session_not_started_or_activated ||
        'Please wait for the provider to start the session and then rejoin.'
      )
    case 'session_already_terminated':
      return (
        overrides?.session_already_terminated ||
        `The video call has already ended. ${
          isProviderRoute()
            ? 'Initiate a new call with the patient from Care Manager.'
            : 'Please close the window and wait for you provider to initiate a new call.'
        }`
      )
    case 'ctm_not_in_care_team':
      return (
        overrides?.ctm_not_in_care_team ||
        "You need to belong to a patient's care team in order to initiate a video session with them."
      )
    case 'organizer_already_added':
      return (
        overrides?.organizer_already_added ||
        'Another provider is already in the video session.'
      )
    default:
      return message || fallback || 'An error occured'
  }
}
