import { useNavigate } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useNotify,
} from '@valerahealth/ui-components'
import {
  parseIntlPhoneNumber,
  setIntlPhoneNumber,
} from '@valerahealth/ui-components/utils'

import {
  FormProvider,
  IntlPhoneInput,
  SaveButton,
  TextField,
  useForm,
} from '@valerahealth/ui-components/form'

import { useTranslation } from '@valerahealth/ui-translation'
import { useReduxDispatch } from 'store'
import { actions } from 'store/appSlice'
import { getRoutePaths } from 'routes/utils'
import { telehealthApi } from 'api'
import { useEffect } from 'react'

const { useSendTelehealthOTPMutation, useConfirmTelehealthOTPMutation } =
  telehealthApi

type FormType = {
  phone: string
  otp: string
}

const defaultValues: FormType = {
  phone: '',
  otp: '',
}

export function PatientSignInForm({ roomId }: { roomId: string }) {
  const notify = useNotify()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()

  const [sendOTP, sendOTPRes] = useSendTelehealthOTPMutation()
  const [confirmOTP] = useConfirmTelehealthOTPMutation()

  const methods = useForm<FormType>({
    defaultValues,
  })
  const { reset, setFocus, setError, handleSubmit, watch } = methods

  const sendCode = async (phone: string) => {
    const res = await sendOTP({
      phone: setIntlPhoneNumber(phone),
      roomId,
    })

    if ('error' in res) {
      if ('data' in res.error) {
        notify({
          severity: 'error',
          message: `An error occured sending a one time passcode: ${
            res.error.data?.message ||
            t(`networkError.${res.error.data?.statusCode || 500}`)
          }`,
        })
        return
      }
      notify({
        message:
          res.error.message || 'An error occured sending a one time passcode',
        severity: 'error',
      })
      reset(defaultValues)
      sendOTPRes.reset()
    }
  }

  const confirmCode = async ({ phone, otp }: FormType) => {
    const res = await confirmOTP({
      phone: setIntlPhoneNumber(phone),
      code: otp,
      roomId,
    })

    if ('data' in res) {
      const {
        accessToken: token,
        participantId: patientId,
        sessionId,
        roomId,
      } = res.data
      dispatch(
        actions.setPatientAuth({
          token,
          roomId,
          patientId,
        }),
      )
      navigate(getRoutePaths({ roomId, sessionId }).patientWaitingRoom)
    }

    if ('error' in res) {
      if ('data' in res.error) {
        if (res.error.data.statusCode === 401) {
          setError(
            'otp',
            {
              message:
                'The code you entered is incorrect, or the video session you are trying to enter does not exist. Please try again.',
            },
            {
              shouldFocus: true,
            },
          )
          return
        }
        notify({
          severity: 'error',
          message: `An error occured confirming the passcode: ${
            res.error.data?.message ||
            t(`networkError.${res.error.data?.statusCode || 500}`)
          }`,
        })
        return
      }
      notify({
        message:
          res.error.message || 'An error occured sending a one time passcode',
        severity: 'error',
      })
    }
  }

  const onSubmit = handleSubmit(async ({ phone, otp }: FormType) => {
    if (otp) {
      await confirmCode({ phone, otp })
      return
    }
    await sendCode(phone)
  })

  const phoneNumber = parseIntlPhoneNumber(watch('phone'))

  useEffect(() => {
    if (!sendOTPRes.isUninitialized) {
      setFocus('otp')
    }
  }, [setFocus, sendOTPRes.isUninitialized])

  return (
    <Dialog open fullWidth maxWidth="xs">
      <DialogTitle>Sign In</DialogTitle>
      <form onSubmit={onSubmit}>
        <FormProvider {...methods}>
          <DialogContent dividers>
            {sendOTPRes.isUninitialized && (
              <IntlPhoneInput
                name="phone"
                label="Phone Number"
                required
                fullWidth
                helperText="You will recieve a text message with a one time passcode"
              />
            )}
            {!sendOTPRes.isUninitialized && (
              <TextField
                fullWidth
                name="otp"
                label="One Time Passcode"
                required
                helperText={`A code was sent to ${phoneNumber}.
                    Please enter the code you've received`}
              />
            )}
          </DialogContent>
          <DialogActions>
            {!sendOTPRes.isUninitialized && (
              <>
                <Button
                  variant="text"
                  onClick={() => {
                    sendOTPRes.reset()
                    methods.reset(defaultValues)
                  }}
                >
                  Back
                </Button>
                <Button
                  sx={{ mr: 'auto' }}
                  onClick={() => {
                    sendCode(phoneNumber)
                  }}
                  variant="outlined"
                  color="info"
                  disabled={!sendOTPRes.isSuccess}
                  startIcon={
                    sendOTPRes.isLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : undefined
                  }
                >
                  Send the code again
                </Button>
              </>
            )}

            <SaveButton
              label="Next"
              isError={sendOTPRes.isError}
              isSuccess={false}
            />
          </DialogActions>
        </FormProvider>
      </form>
    </Dialog>
  )
}
