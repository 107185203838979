import { useEffect, useState, MouseEvent } from 'react'
import { useTranslation } from '@valerahealth/ui-translation'
import { Fade, SvgIconProps, Tooltip } from '@mui/material'
import { Check, ContentCopy } from '../icons'
import IconButton, { IconButtonProps } from './IconButton'

export type CopyButtonProps = {
  // if presented with a response the button will copy it to the browser clipboard
  onClick: (
    event: MouseEvent<HTMLButtonElement>,
  ) => string | undefined | void | Promise<string | undefined | void>
  tooltip?: string
  iconProps?: Pick<SvgIconProps, 'sx' | 'fontSize'>
} & Omit<IconButtonProps, 'onClick' | 'isLoading' | 'children'>

export function CopyButton({
  onClick,
  tooltip,
  iconProps,
  disabled,
  sx,
  ...rest
}: CopyButtonProps) {
  const [t] = useTranslation()
  const [copied, setCopied] = useState(false)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined

    if (copied) {
      if (timeout !== undefined) clearTimeout(timeout)
      timeout = setTimeout(() => {
        setCopied(false)
      }, 5000)
    }

    return () => {
      clearTimeout(undefined)
    }
  }, [copied])

  return (
    <IconButton
      onClick={async (...params) => {
        // allow to wait for a onsync
        if (onClick) {
          const res = onClick(...params)
          if (res instanceof Promise) setLoading(true)
          const val = await res
          if (res instanceof Promise) setLoading(false)
          if (val) navigator.clipboard.writeText(val)
        }
        setCopied(true)
      }}
      disabled={disabled || copied}
      disableRipple
      sx={{
        position: 'relative',
        ':hover, :hover *': {
          cursor: 'pointer !important',
        },
        ...sx,
      }}
      isLoading={isLoading}
      {...rest}
    >
      {copied && (
        <Tooltip title={tooltip || t('copied_sucessfully')} open>
          <Fade in={copied} timeout={1000}>
            <Check color="success" {...iconProps} />
          </Fade>
        </Tooltip>
      )}
      {!copied && (
        <ContentCopy
          sx={{
            alignSelf: 'center',
            color: (theme) => theme.palette.action.active,
          }}
          {...iconProps}
        />
      )}
    </IconButton>
  )
}
