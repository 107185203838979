export default {
  gridFilterInputLabel: '數值',
  appsList: 'Valera預約',
  UserAccount: {
    formTitle: '更新帳戶資訊',
    accountFormMenuItemLabel: '帳戶',
    introduction: '您好，{{name}}',
    iconTitle: '帳戶菜單',
  },
  UserAccountForm: {
    updateSuccess: '您的帳戶設置已更新。',
    currentPassword: '當前密碼',
    proposedPassword: '新密碼',
    repeatProposedPassword: '重複新密碼',
    passwordsDoNotMatch: '密碼不匹配',
    mustContainNumber: '{{field}}必須包含數字。',
    mustContainSpecialCharacter: '{{field}}必須包含特殊字元。',
    mustContainUppercase: '{{field}}必須包含大寫字元。',
    mustContainLowercase: '{{field}}必須包含小寫字元。',
    updatePasswordFailure: '無法更新密碼。',
    updatePasswordSuccess: '您的密碼已成功更新。',
    incorrectCurrentPassword: '您的當前密碼不正確。',
  },
  FileUpload: {
    allowedFileTypes: '允許的文檔類型: {{fileTypes}}',
    placeholder: '點擊上傳文檔',
    dialogTitle: '上傳{{field}}的文檔',
    invalidFileName: '無效的文檔名。',
    invalidCharacters:
      '文檔名中不允許有下列字元：# % & $ < > * ? \\ / { } $ ! \' " + ` | = : @',
    invalidStartOfFile: '文檔開頭必須是字母或數字字元',
    invalidEndOfFile: '文檔結尾必須是字母、數字或括弧字元',
    invalidEmptyFileName: '需要文檔名',
    Dropzone: {
      helperText: '<click>點擊以上傳</click>或拖放',
      validationText: '{{接受，列出(type: disjunction)}}',
      hasErrorsTitle: '有一個或多個錯誤',
      maxSize: '最大{{maxSize, bytes}}',
      minSize: '最小{{minSize, bytes}}',
      'file-invalid-type': '無效的文檔類型',
      'file-too-large': '文檔太大',
      'file-too-small': '文檔太小',
      tooManyFiles_one: '只允許一個文檔',
      tooManyFiles_other: '最多允許{{count}}個文檔',
    },
  },
  authMonitor: {
    signedOut: '您已成功登出。',
    sessionExpired: '您的會話已過期。',
    ctaSignBackIn: '重新登入',
    inactivityWarningTitle: '不活動警告',
    inactivityWarningMessage:
      '如果您保持不活動狀態，那麼將在{{seconds}}秒後退出。',
  },
  notificationsMoreInfo: '更多資訊',
  persistentDataGrid: {
    defaultView: '默認視圖',
    isDefault: '是預設值',
    setAsDefault: '設為預設值',
    editView: '編輯視圖',
    presetViewsGroupLabel: '預設視圖',
    savedViewsGroupLabel: '您保存的視圖',
    createNewSelectionOption: '創建新的"{{name}}"視圖',
    savedViewsTextFieldLabel: '保存的視圖',
    savedViewsTextFieldPlaceholder: '選擇視圖',
    viewDialogTitleAdd: '添加新的網格視圖',
    viewDialogTitleEdit: '編輯{{name}}視圖',
    viewDialogNameIsEmptyErr: '姓名是必填欄位。',
    viewDialodNameIsNotUniqueErr: '名為"{{name}}"的視圖已存在。',
  },
  editableDataGrid: {
    actionsColumn: '操作',
    genericInvalidRowMessage: '這一行有問題。',
  },
  removeSort: '移除',
  ascendingString: 'A-Z',
  descendingString: 'Z-A',
  ascendingNumber: '數字',
  descendingNumber: '數字',
  ascendingDate: '日期',
  descendingDate: '日期',
  ascendingCount: '計數',
  descendingCount: '計數',
  CommentAuthor: '評論人: {{author}}',
  Protected: {
    title: '拒絕訪問',
    message:
      '您沒有權限查看此頁。請發送電子郵件至provider-support@valerahealth.com請求訪問。',
    description: '您需要"{{Permission}}"訪問權限才能查看此頁。',
  },
  export: '導出',
  density: '密度',
  emailSupport: '電子郵箱支援',
    PatientTypeAhead: {
    placeholder: '在此尋查患者',
  },
}
