import { Box, Typography } from '@valerahealth/ui-components'
import { Outlet } from 'react-router-dom'

export default function Page() {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        backgroundImage:
          "url('https://cdn.valerahealth.com/images/telehealth-background.jpg')",
        backgroundSize: 'cover',
      }}
    >
      <Box
        sx={{
          height: '2rem',
          width: 'auto',
          top: '2rem',
          left: '2rem',
          position: 'fixed',
        }}
        component="img"
        src="https://cdn.valerahealth.com/images/valera-logo-white.svg"
        alt="Valera Health"
      />
      <Outlet />
      <Typography
        position="fixed"
        width="100%"
        bottom="0"
        textAlign="center"
        variant="caption"
      >
        v.{process.env.APP_VERSION}
      </Typography>
    </Box>
  )
}
