import _en from './en'
import _zhCHS from './zh-CHS'
import _zhTW from './zh-TW'
import _es from './es'

export const UI_COMPONENTS_LOCALS_NAMESPACE = 'ui-components'

function withNamespace<T>(v: T) {
  return {
    [UI_COMPONENTS_LOCALS_NAMESPACE]: v,
  }
}

export const en = withNamespace(_en)
export const es = withNamespace(_es)
export const zhCHS = withNamespace(_zhCHS)
export const zhTW = withNamespace(_zhTW)
