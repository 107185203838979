import { TFunction } from '@valerahealth/ui-translation'
import {
  AttachmentAnnotationInput,
  FileCreate,
  FileUpdate,
} from '@valerahealth/rtk-query'
import { FieldError, type RegisterOptions } from 'react-hook-form'
import { useContext } from 'react'
import { isAfter } from '../utils/date'
import { ReadOnlyContext } from './FormProvider'
import {
  type AttachmentAnnotationInputValueType,
  type FileInputValueType,
} from './form.types'
import { type FileType, type MIMEType } from '../base/FileUpload'

const registerPropKeys: (keyof RegisterOptions)[] = [
  'maxLength',
  'minLength',
  'max',
  'min',
  'pattern',
  'validate',
  'valueAsNumber',
  'valueAsDate',
  'setValueAs',
  'onChange',
  'onBlur',
  'value',
  'shouldUnregister',
  'deps',
  'required',
]
export type SplitRegisterProps = (typeof registerPropKeys)[number]

/* segments props that are meant for RHF register from the props meant for a component */
export function splitProps<T extends {}>(props: RegisterOptions & T) {
  const registerProps: RegisterOptions = {}
  const fieldProps: Partial<T> = {}

  Object.entries(props).forEach(([key, value]) => {
    if (registerPropKeys.includes(key as keyof RegisterOptions)) {
      registerProps[key as keyof RegisterOptions] = value
    } else {
      fieldProps[key as keyof T] = value
    }
  })

  return {
    registerProps,
    fieldProps,
  } as {
    registerProps: RegisterOptions
    fieldProps: T
  }
}

type DirtyFields = {
  [x: string | number]: boolean | Array<any> | DirtyFields
}

type DeepPartial<T> = T extends Array<any>
  ? T | undefined
  : T extends {}
  ? { [P in keyof T]?: DeepPartial<T[P]> }
  : T

/** recursive function that returns only the valued fields that are dirty in React Hook Form */
export function onlyDirtyFields<T extends {}>(
  dirtyFields: DirtyFields,
  allValues: T,
): DeepPartial<T> {
  // NOTE: Recursive function.
  const val = Object.entries(dirtyFields).reduce((sum, [key, value]) => {
    if (
      (value === true || Array.isArray(value)) &&
      Object.hasOwn(allValues, key)
    ) {
      // @ts-ignore
      sum[key] = allValues[key]
      return sum
    }
    if (value !== false && Object.hasOwn(allValues, key)) {
      // @ts-ignore
      sum[key] = onlyDirtyFields(value, allValues[key])
    }
    return sum
  }, {} as Record<string | number, any>) as DeepPartial<T>
  return val
}

/* standard error messages when someone passes a generic validator like min={8} to our React hook form fields */
export const getErrorMessage = (
  error: FieldError,
  t: TFunction,
  {
    field,
    min = 0,
    max = 0,
    minLength = 0,
    maxLength = 0,
  }: {
    field?: string
  } & RegisterOptions,
) => {
  if (error.message) return error.message
  switch (error.type) {
    case 'required':
      return t('form_required_field', { field })
    case 'min':
      return t('form_invalid_field_min', { field, min })
    case 'max':
      return t('form_invalid_field_max', { field, max })
    case 'minLength':
      return t('form_invalid_field_minLength', {
        field,
        count: minLength as number,
      })
    case 'maxLength':
      return t('form_invalid_field_maxLength', {
        field,
        count: maxLength as number,
      })
    case 'characters':
      return t('form_invalid_field_characters', { field })
    default:
      return t('form_invalid_field', { field })
  }
}

/** common validation functions */

type ValidateDateOneGreaterDate2Props = {
  date1: null | Date
  /* gets passed to TFunction to translate */
  date1TArg: string
  date2: null | Date
  /* gets passed to TFunction to translate */
  date2TArg: string
  t: TFunction
}

/** generic validation functions */
export const validate = {
  dateOneGreaterThanDate2: ({
    date1,
    date1TArg,
    date2,
    date2TArg,
    t,
  }: ValidateDateOneGreaterDate2Props) => {
    if (date1 && date2 && !isAfter(date1, date2)) {
      return t('form_date1MustBeGreaterThanDate2', {
        date1: t(date1TArg),
        date2: t(date2TArg),
      })
    }
    return true
  },
  validEmail: (val?: string | null, validationMessage?: string) =>
    !val ||
    /^[\w-.+]+@([\w-]+\.)+[\w-]{2,}$/.test(val) ||
    validationMessage ||
    false,
  hasOneNumber: (value: string, validationMessage?: string) =>
    !value || /\d/.test(value) || validationMessage || false,
  hasOneSpecialCharacter: (value: string, validationMessage?: string) =>
    !value || /[^a-zA-Z0-9]/.test(value) || validationMessage || false,
  withoutSpecialCharacter: (value: string, validationMessage?: string) =>
    !value || !/[\\/"[\]:|<>=;,?*@]/.test(value) || validationMessage || false,
  hasOneUppercase: (value: string, validationMessage?: string) =>
    !value || /[A-Z]/.test(value) || validationMessage || false,
  hasOneLowercase: (value: string, validationMessage?: string) =>
    !value || /[a-z]/.test(value) || validationMessage || false,
  validPostalCode: (value: string, validationMessage?: string) =>
    !value || /^[0-9]{5}$/.test(value) || validationMessage || false,
  validNPICode: (value: string, validationMessage?: string) =>
    !value || /^[0-9]{10}$/.test(value) || validationMessage || false,
}

export const useReadOnlyForm = () => {
  return useContext(ReadOnlyContext)
}

export function fileToInput(value?: FileInputValueType | null) {
  let v: FileCreate | FileUpdate
  if (!value) return value
  if (!value.s3Key || !value.mimeType) {
    v = {
      name: value.name,
    }
  } else {
    v = {
      mimeType: value.mimeType,
      name: value.name,
      s3Key: value.s3Key,
    }
  }
  return v
}

export const fileTypeToInputType = ({
  name,
  mimeType,
  s3Key,
  size,
  url,
}: FileType): FileInputValueType => ({
  name,
  extension: /\.([^.]+$)/.exec(name)?.[1] ?? '',
  mimeType: mimeType as MIMEType,
  size,
  s3Key,
  url,
})

/** used in combobox prop filterOptions if you want to wrap with other logic */
export function filterComboboxOptions<T>(
  options: T[],
  {
    getOptionLabel,
    inputValue,
  }: { getOptionLabel: (v: T) => string; inputValue: string },
) {
  const matchWords = inputValue
    .trim()
    .toLowerCase()
    .split(' ')
    .filter((v) => v)

  const displayedOptions = options.map((option) => ({
    option,
    label: getOptionLabel(option).toLowerCase(),
  }))

  return displayedOptions
    .filter(({ label }) => matchWords.every((word) => label.includes(word)))
    .map(({ option }) => option)
}

/** takes the form AttachmentAnnotationInputValueType and converts it to rtk-query AttachmentAnnotationInput */
export function attachmentAnnotationToInput(
  comment: AttachmentAnnotationInputValueType,
): AttachmentAnnotationInput | undefined {
  return comment?.text.trim()
    ? {
        text: comment.text.trim(),
        attachments: comment?.files?.length
          ? comment.files
              .map(({ name, s3Key, mimeType }) => ({
                name,
                s3Key,
                mimeType: mimeType as string,
              }))
              .filter(
                (v): v is FileCreate => !!(v.name && v.mimeType && v.s3Key),
              )
          : undefined,
      }
    : undefined
}
