import { Overwrite } from 'utility-types'
import { Stack, Typography } from '@valerahealth/ui-components'
import { format } from '@valerahealth/ui-components/utils/date'
import { getParticipantName } from 'components/participant/utils'
import { useMemo } from 'react'
import { useReduxSelector } from 'store'
import { selectParticipantInfo, selectUserId } from 'store/selectors'
import { TelehealthChatMessageItem } from 'api'

export const ChatMessage = ({
  messageItem: { senderId, body, createdAt },
  isSending,
}: {
  isSending?: boolean
  messageItem: Overwrite<
    Pick<TelehealthChatMessageItem, 'createdAt' | 'senderId' | 'body'>,
    { createdAt: string | number | Date }
  >
}) => {
  const userId = useReduxSelector(selectUserId)
  const isOwn = senderId === userId
  const participant = useReduxSelector(selectParticipantInfo)
  const time = useMemo(() => format(new Date(createdAt), 'p'), [createdAt])

  return (
    <Stack
      sx={{
        alignSelf: isOwn ? 'flex-end' : 'flex-start',
        alignItems: 'flex-start',
        p: `0 ${!isOwn ? '2rem' : '0'} 0 ${isOwn ? '2rem' : '0'}`,
      }}
    >
      <Typography gutterBottom variant="caption">
        {!isOwn && participant && getParticipantName(participant)}
        &nbsp;
        {time}
      </Typography>
      <Typography
        sx={{
          borderRadius: '.375rem',
          backgroundColor: (theme) => theme.palette.grey[200],
          p: 1,
          wordBreak: 'break-word',
        }}
      >
        {body}
      </Typography>
      {isSending && <Typography>... sending</Typography>}
    </Stack>
  )
}
