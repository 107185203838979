import { ReactNode, Ref } from 'react'
import { Clear } from '@mui/icons-material'
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  FormHelperText,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  FilledInput,
  OutlinedInput,
  Box,
  Chip,
  type SelectProps as MuiSelectProps,
  type FormControlProps,
  InputLabelProps,
  CircularProgress,
  Autocomplete,
  TextField,
  TextFieldProps,
  AutocompleteProps,
  useTheme,
  SelectVariants,
} from '@mui/material'
import IconButton from '../IconButton/IconButton'

const variantMapping = {
  outlined: OutlinedInput,
  filled: FilledInput,
  standard: Input,
}

export type SingleSelectProps<T, Clearable extends boolean> = {
  renderValue?: (value: T) => ReactNode
  formControlProps?: FormControlProps
  inputLabelProps?: InputLabelProps
  label?: ReactNode
  helperText?: string
  value: T
  onChange?: Clearable extends true
    ? (value: T | null) => void
    : (value: T) => void
  clearable?: Clearable
  innerRef?: MuiSelectProps['ref']
  isLoading?: boolean
  variant?: SelectVariants
  children: React.ReactNode
} & Omit<
  MuiSelectProps<T>,
  | 'ref'
  | 'renderValue'
  | 'children'
  | 'value'
  | 'input'
  | 'label'
  | 'onChange'
  | 'variant'
>

/** allows you to pass children or renderValue to customize look, also allows loading dynamic data via RTK hook */
export function SingleSelect<OptionType, Clearable extends boolean = false>({
  label,
  helperText,
  formControlProps,
  inputLabelProps,
  innerRef,
  children,
  onFocus,
  onChange,
  isLoading,
  inputProps,
  variant: _variant,
  fullWidth,
  readOnly,
  value,
  clearable,
  ...selectProps
}: SingleSelectProps<OptionType, Clearable>) {
  const theme = useTheme()
  const variant =
    _variant ||
    theme.components?.MuiTextField?.defaultProps?.variant ||
    'outlined'
  const MuiInput = variantMapping[variant]
  return (
    <FormControl {...formControlProps} fullWidth={fullWidth} variant={variant}>
      <InputLabel {...inputLabelProps} variant={variant}>
        {label}
      </InputLabel>
      <MuiSelect
        readOnly={readOnly}
        autoWidth
        {...selectProps}
        sx={{
          ...selectProps.sx,
          '& .MuiSelect-icon': {
            visibility: readOnly ? 'hidden' : 'visible',
          },
        }}
        value={value || ''}
        onChange={(e) => {
          if (onChange) onChange(e.target.value as OptionType)
        }}
        input={
          <MuiInput
            endAdornment={
              <>
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mr: 3,
                    }}
                  >
                    <CircularProgress size={30} />
                  </Box>
                ) : null}
                {clearable && value && !formControlProps?.disabled && (
                  <IconButton
                    onClick={() =>
                      clearable ? onChange?.(null as OptionType) : undefined
                    }
                    sx={{ mr: 2 }}
                  >
                    <Clear />
                  </IconButton>
                )}
              </>
            }
            label={label}
            inputRef={innerRef}
          />
        }
      >
        {children}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

type MultiSelectProps<OptionType> = {
  options: OptionType[]
} & Omit<
  SingleSelectProps<OptionType[], false>,
  'children' | 'onChange' | 'multiple' | 'ref'
> & {
    /** if the options non-primitive types provide a funciton to get a key */
    getKey?: (value: OptionType) => string
    /** if the options non-primitive types provide a funciton to get a key */
    getLabel?: (value: OptionType) => string
    onChange: (values: OptionType[]) => void
  }

/** handles rendering children with a checklist  */
export function MultiSelect<OptionType>({
  options,
  getKey = (val) => String(val),
  getLabel = (val) => String(val),
  onChange,
  ...rest
}: MultiSelectProps<OptionType>) {
  const value = rest.value || []
  const handleChange = (option: OptionType, checked: boolean) => {
    onChange(
      checked
        ? // currently checked so uncheck
          value.filter((v) => getKey(v) !== getKey(option))
        : // currently unchecked so check
          value.concat(option),
    )
  }
  return (
    <SingleSelect multiple {...rest} value={value}>
      {options.map((option) => {
        const checked =
          value.findIndex((v) => getKey(v) === getKey(option)) > -1
        const key = getKey(option)
        return (
          <MenuItem key={key} onClick={() => handleChange(option, checked)}>
            <Checkbox checked={checked} />
            <ListItemText primary={getLabel(option)} />
          </MenuItem>
        )
      })}
    </SingleSelect>
  )
}

export type ChipMultiSelectProps<OptionType> = Omit<
  MultiSelectProps<OptionType>,
  'renderValue'
>

export function ChipMultiSelect<OptionType>({
  getKey = (v) => String(v),
  getLabel = (v) => String(v),
  value,
  sx,
  MenuProps,
  ...props
}: ChipMultiSelectProps<OptionType>) {
  return (
    <MultiSelect
      value={value}
      getKey={getKey}
      getLabel={getLabel}
      sx={{
        ...(value && value.length
          ? {
              '.MuiSelect-select': {
                paddingTop: '0.75rem',
                paddingBottom: '0.75rem',
              },
            }
          : {}),
        ...sx,
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        ...MenuProps,
      }}
      renderValue={(values) => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 0.5,
          }}
        >
          {values.map((v) => (
            <Chip key={getKey(v)} label={getLabel(v)} />
          ))}
        </Box>
      )}
      {...props}
    />
  )
}

export type ComboboxProps<OptionType> = Omit<
  AutocompleteProps<
    OptionType,
    boolean | undefined,
    boolean | undefined,
    undefined
  >,
  'renderInput' | 'getOptionLabel' | 'ref' | 'freeSolo'
> & {
  label?: string | ReactNode
  showTooltip?: boolean
  getOptionLabel?: (option: OptionType) => string
  textFieldProps?: TextFieldProps
  innerRef?: Ref<unknown> | undefined
  getOptionKey?: (option: OptionType) => string
}

export function Combobox<OptionType>({
  getOptionLabel = (val) => String(val),
  label,
  showTooltip = false,
  textFieldProps,
  innerRef,
  renderOption,
  readOnly,
  loading,
  fullWidth = true,
  getOptionKey,
  multiple,
  disableCloseOnSelect,
  ...props
}: ComboboxProps<OptionType>) {
  return (
    <Autocomplete
      fullWidth={fullWidth}
      className={readOnly ? 'MuiAutocomplete-readOnly' : undefined}
      readOnly={readOnly}
      loading={loading}
      renderInput={(params) => {
        const {
          inputProps: { value },
        } = params

        return (
          <TextField
            {...{
              ...params,
              ...(loading
                ? {
                    InputProps: {
                      ...textFieldProps?.InputProps,
                      ...params.InputProps,
                      endAdornment: <CircularProgress size={20} />,
                    },
                  }
                : null),
              ...textFieldProps,
              fullWidth,
            }}
            label={label}
            inputRef={innerRef}
            {...(showTooltip && value
              ? {
                  title: (value as string) || '',
                }
              : null)}
          />
        )
      }}
      disableCloseOnSelect={disableCloseOnSelect ?? multiple}
      multiple={multiple}
      getOptionLabel={getOptionLabel}
      renderOption={
        renderOption ||
        ((spread, option, { selected }) => {
          return (
            <MenuItem
              {...spread}
              key={getOptionKey?.(option) || (spread as any).key}
              dense={props.size === 'small'}
            >
              {multiple && <Checkbox sx={{p:'0px 6px 0px 0px'}} checked={selected} />}
              <ListItemText primary={getOptionLabel(option)} />
            </MenuItem>
          )
        })
      }
      {...props}
      componentsProps={{
        ...props.componentsProps,
        popupIndicator: {
          sx: { visibility: readOnly ? 'hidden' : 'visible' },
        },
        popper: {
          ...props.componentsProps?.popper,
          placement: 'bottom-start',
          style: {
            width: 'auto',
            ...props.componentsProps?.popper?.style,
          },
        },
      }}
    />
  )
}
