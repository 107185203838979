import {
  DayOfWeek,
  type ServiceCategory,
  ServiceCategoryCode,
  ProfessionCode,
  EmploymentRole,
  GenderIdentity,
  Race,
  PatientGenderIdentity,
  DocumentCategory,
  PatientStatus,
  ServiceTypeCode,
} from './generated.types'

// make sure to update SCHEDULE_SERVICE_CATEGORIES and EXPECTED_HOURS_SERVICE_CATEGORIES if we change SERVICE_CATEGORIES
export const SERVICE_CATEGORIES: ServiceCategory[] = [
  { code: ServiceCategoryCode.Patient, text: 'Patient Hours' },
  { code: ServiceCategoryCode.Admin, text: 'Admin Hours' },
  { code: ServiceCategoryCode.Break, text: 'Break Hours' },
  { code: ServiceCategoryCode.Cap, text: 'CAP Hours' },
  { code: ServiceCategoryCode.Consult, text: 'Consult Hours' },
  { code: ServiceCategoryCode.Intake, text: 'Intake Hours' },
  { code: ServiceCategoryCode.Toc, text: 'TOC Hours' },
  { code: ServiceCategoryCode.Group, text: 'Group Hours' },
  { code: ServiceCategoryCode.OutOfOffice, text: 'Out of Office' },
]

// break hours is going to be an appointment behind the scenes
export const SCHEDULE_SERVICE_CATEGORIES: ServiceCategory[] =
  SERVICE_CATEGORIES.slice(0, -1)

// used in provider expected hours fields
export const EXPECTED_HOURS_SERVICE_CATEGORIES = SERVICE_CATEGORIES.filter(
  (x) =>
    ![
      ServiceCategoryCode.Break,
      ServiceCategoryCode.OutOfOffice,
      ServiceCategoryCode.Intake,
      ServiceCategoryCode.Group,
    ].includes(x.code),
)
export const PROVIDER_TYPE = {
  PRESCRIBER: 'prescriber',
  THERAPIST: 'therapist',
} as const

export const EMPLOYMENT_ROLE_TYPE = {
  [EmploymentRole.CareCoordinator]: '',
  [EmploymentRole.CaseManager]: '',
  [EmploymentRole.HealthConnector]: '',
  [EmploymentRole.MedicalAssistant]: PROVIDER_TYPE.PRESCRIBER,
  [EmploymentRole.Prescriber]: PROVIDER_TYPE.PRESCRIBER,
  [EmploymentRole.PrescriberSupervisor]: PROVIDER_TYPE.PRESCRIBER,
  [EmploymentRole.Therapist]: PROVIDER_TYPE.THERAPIST,
  [EmploymentRole.TherapistSupervisor]: PROVIDER_TYPE.THERAPIST,
  [EmploymentRole.AssociateTherapist]: PROVIDER_TYPE.THERAPIST,
  [EmploymentRole.GroupTherapy]: PROVIDER_TYPE.THERAPIST,
} as const

export const PROFESSION = [
  { code: ProfessionCode.Do, text: 'Doctor of Osteopathic Medicine' },
  { code: ProfessionCode.Lcsw, text: 'Licensed Clinical Social Worker' },
  {
    code: ProfessionCode.Lcswa,
    text: 'Licensed Clinical Social Worker Associate',
  },
  {
    code: ProfessionCode.Licsw,
    text: 'Licensed Independent Clinical Social Worker',
  },
  { code: ProfessionCode.Lmhc, text: 'Licensed Mental Health Counselor' },
  { code: ProfessionCode.Lmsw, text: 'Licensed Master Social Worker' },
  { code: ProfessionCode.Lpc, text: 'Licensed Professional Counselor' },
  { code: ProfessionCode.Md, text: 'Medical Doctor' },
  { code: ProfessionCode.Np, text: 'Nurse Practitioner' },
  { code: ProfessionCode.Other, text: 'Other' },
  { code: ProfessionCode.Phd, text: 'Pscychologist' },
  {
    code: ProfessionCode.Pmnph,
    text: 'Psychiatric Mental Health Nurse Practitioner',
  },
  { code: ProfessionCode.Psyd, text: 'Doctor of Psychology' },
]

export const NON_ACTIVE_PATIENT_STATUSES = [
  PatientStatus.Pending,
  PatientStatus.Lead,
  PatientStatus.Waitlist,
].concat(PatientStatus.ClosedDischarged)

export const DAY_TO_NUMBER = {
  [DayOfWeek.Sunday]: 0,
  [DayOfWeek.Monday]: 1,
  [DayOfWeek.Tuesday]: 2,
  [DayOfWeek.Wednesday]: 3,
  [DayOfWeek.Thursday]: 4,
  [DayOfWeek.Friday]: 5,
  [DayOfWeek.Saturday]: 6,
} as const

export const DAY_OF_WEEK_ARRAY = Object.keys(DAY_TO_NUMBER) as DayOfWeek[]

// keys are 0-6 with 1=monday and 7=sunday
export const NUMBER_TO_DAY = Object.fromEntries(
  Object.entries(DAY_TO_NUMBER).map((v) => v.reverse()),
) as Record<number, DayOfWeek>

export type StateType = {
  name: string
  code: string
}

export const STATES: StateType[] = [
  {
    name: 'Alabama',
    code: 'AL',
  },
  {
    name: 'Alaska',
    code: 'AK',
  },
  {
    name: 'American Samoa',
    code: 'AS',
  },
  {
    name: 'Arizona',
    code: 'AZ',
  },
  {
    name: 'Arkansas',
    code: 'AR',
  },
  {
    name: 'California',
    code: 'CA',
  },
  {
    name: 'Colorado',
    code: 'CO',
  },
  {
    name: 'Connecticut',
    code: 'CT',
  },
  {
    name: 'Delaware',
    code: 'DE',
  },
  {
    name: 'District Of Columbia',
    code: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    code: 'FM',
  },
  {
    name: 'Florida',
    code: 'FL',
  },
  {
    name: 'Georgia',
    code: 'GA',
  },
  {
    name: 'Guam',
    code: 'GU',
  },
  {
    name: 'Hawaii',
    code: 'HI',
  },
  {
    name: 'Idaho',
    code: 'ID',
  },
  {
    name: 'Illinois',
    code: 'IL',
  },
  {
    name: 'Indiana',
    code: 'IN',
  },
  {
    name: 'Iowa',
    code: 'IA',
  },
  {
    name: 'Kansas',
    code: 'KS',
  },
  {
    name: 'Kentucky',
    code: 'KY',
  },
  {
    name: 'Louisiana',
    code: 'LA',
  },
  {
    name: 'Maine',
    code: 'ME',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    name: 'Maryland',
    code: 'MD',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    name: 'Michigan',
    code: 'MI',
  },
  {
    name: 'Minnesota',
    code: 'MN',
  },
  {
    name: 'Mississippi',
    code: 'MS',
  },
  {
    name: 'Missouri',
    code: 'MO',
  },
  {
    name: 'Montana',
    code: 'MT',
  },
  {
    name: 'Nebraska',
    code: 'NE',
  },
  {
    name: 'Nevada',
    code: 'NV',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    name: 'New Mexico',
    code: 'NM',
  },
  {
    name: 'New York',
    code: 'NY',
  },
  {
    name: 'North Carolina',
    code: 'NC',
  },
  {
    name: 'North Dakota',
    code: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    name: 'Ohio',
    code: 'OH',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    name: 'Oregon',
    code: 'OR',
  },
  {
    name: 'Palau',
    code: 'PW',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    name: 'South Carolina',
    code: 'SC',
  },
  {
    name: 'South Dakota',
    code: 'SD',
  },
  {
    name: 'Tennessee',
    code: 'TN',
  },
  {
    name: 'Texas',
    code: 'TX',
  },
  {
    name: 'Utah',
    code: 'UT',
  },
  {
    name: 'Vermont',
    code: 'VT',
  },
  {
    name: 'Virgin Islands',
    code: 'VI',
  },
  {
    name: 'Virginia',
    code: 'VA',
  },
  {
    name: 'Washington',
    code: 'WA',
  },
  {
    name: 'West Virginia',
    code: 'WV',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    name: 'Wyoming',
    code: 'WY',
  },
]

export const STATES_REVERSE_HASH = new Map<string, string>(
  STATES.map((s) => [s.name, s.code]),
)

export const locationNameToShorthandName = (l: string) => {
  const [state, t] = l.split('-')
  return `${
    STATES_REVERSE_HASH.get(state!.trim()) || state!.trim()
  } - ${t!.trim()}`
}

export const STATES_HASH: Record<string, string> = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const insuranceEntities = [
  { name: 'Valera Medical PA-FL', groupNPI: '1871248757', tin: '30-1290746' },
  { name: 'Valera Medical PA-NJ', groupNPI: '1306435060', tin: '86-1426048' },
  { name: 'Valera Medical PC-NY', groupNPI: '1437782349', tin: '84-4731421' },
]

export const insurancePlanTypes = [
  'BCBS',
  'EPO',
  'HMO',
  'HMO Medicare Risk',
  'MCO',
  'POS',
  'PPO',
]

export const insurancePlanVendorNames = ['ASPA', 'Beacon', 'CAIPA', 'Multicare']

export const genderList = [
  GenderIdentity.Male,
  GenderIdentity.Female,
  GenderIdentity.NonBinary,
  GenderIdentity.TransMale,
  GenderIdentity.TransFemale,
  GenderIdentity.Genderqueer,
  GenderIdentity.Other,
  GenderIdentity.NonDisclose,
]

export const patientGenderList = [
  PatientGenderIdentity.Female,
  PatientGenderIdentity.Male,
  PatientGenderIdentity.NonBinary,
  PatientGenderIdentity.TransgenderFemale,
  PatientGenderIdentity.TransgenderMale,
  PatientGenderIdentity.Genderqueer,
  PatientGenderIdentity.Other,
  PatientGenderIdentity.NoneDisclose,
]

export const raceList = [
  Race.Native,
  Race.Asian,
  Race.Black,
  Race.PacificIslander,
  Race.White,
]

export const dischargeReasonOptions = [
  'Care Manager discretion',
  'Clinician discretion',
  'Death (excluding suicide)',
  'Death by suicide',
  'Negative screens',
  "Patient's symptoms have resolved",
  'Patient discretion',
  'Patient has completed care management program',
  'Patient no longer engaged or logged in',
  'Patient no longer eligible for Valera',
  'Physician discretion',
  'Other',
] as const

export const genderIdentityArray = [
  PatientGenderIdentity.Male,
  PatientGenderIdentity.Female,
  PatientGenderIdentity.NonBinary,
  PatientGenderIdentity.TransgenderMale,
  PatientGenderIdentity.TransgenderFemale,
  PatientGenderIdentity.Genderqueer,
  PatientGenderIdentity.Other,
  PatientGenderIdentity.NoneDisclose,
]

export const DOCUMENT_CATEGORIES = [
  DocumentCategory.Attestation,
  DocumentCategory.ClinicalLicense,
  DocumentCategory.ClinicalRegistration,
  DocumentCategory.Cme,
  DocumentCategory.Education,
  DocumentCategory.Id,
  DocumentCategory.ProfessionalLiabilityInsurance,
  DocumentCategory.Report,
  DocumentCategory.Specialty,
  DocumentCategory.Other,
]

export const VERIFIED_DOCUMENT_CATEGORIES = [
  DocumentCategory.Attestation,
  DocumentCategory.ClinicalLicense,
  DocumentCategory.ClinicalAcknowledgement,
  DocumentCategory.Cme,
  DocumentCategory.DeaLicense,
  DocumentCategory.Education,
  DocumentCategory.Id,
  DocumentCategory.PrimaryLiabilityInsurance,
  DocumentCategory.Specialty,
  DocumentCategory.ReportsAndVerifications,
  DocumentCategory.Other,
]

export enum ClinicalAges {
  peds = 'Peds',
  teens = 'Teens/Adolescents',
  adult = 'Adult',
  geriatric = 'Geriatric',
}

export const LicensureExamType = [
  'AMFTRB',
  'ASWB - Masters',
  'ASWB - Clinical',
  'ASWB - Advanced Generalist',
  'COMLEX - USA',
  'NBOME',
  'COMVEX',
  'EPPP',
  'FLEX',
  'LMCC',
  'NBDE',
  'NBDHE',
  'NBME Part Exam',
  'NBPM',
  'NCE',
  'NCLEX',
  'NCMHCE',
  'NPTE',
  'PANCE',
  'PANRE',
  'PRAXIS',
  'SPEX',
  'USMLE',
  'Other',
] as const

export const ExternalAccountType = [
  'AMA',
  'AOA',
  'CAQH',
  'ENLC',
  'FSMB / FCVS',
  'IMLC',
  'N/A',
  'NPPES / PECOS',
  ...STATES.map(({ code }) => `${code} - State Licensure Board`),
  'Other',
]

export const NON_PATIENT_APPOINTMENT_SERVICE_TYPES = [
  ServiceTypeCode.Adm,
  ServiceTypeCode.Brk,
]

export const PATIENT_APPOINTMENT_SERVICE_TYPES = Object.values(
  ServiceTypeCode,
).filter(
  (v) =>
    !NON_PATIENT_APPOINTMENT_SERVICE_TYPES.includes(v) &&
    v !== ServiceTypeCode.Other,
)
