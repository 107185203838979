import { type ReactNode, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  CopyButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Skeleton,
  Stack,
  Typography,
  useNotify,
} from '@valerahealth/ui-components'
import {
  CALL_CREDENTIALS_FIXED_CACHE_KEY,
  getErrorDetails,
  telehealthApi,
} from 'api'
import { getRoutePath, isProviderRoute } from 'routes/utils'
import {
  MicAudioLevelIndicator,
  MicSourceSelect,
  PublisherMuteControls,
  SpeakerSourceSelect,
  VideoContainer,
  VideoSourceSelect,
} from 'components/AudioVideoControls'
import { ParticipantListItem } from 'components/participant/ListItem'
import {
  PublisherContextProvider,
  usePublisher,
  usePublisherVideoContainerRef,
} from 'components/PublisherVideoContext'
import { DeviceChangeListener } from 'components/DeviceHandler'
import { useReduxSelector } from 'store'
import { selectOwnParticipantInfo } from 'store/selectors'
import { VideoDisabledOverlay } from './VideoCall/VideoDisabledOverlay'

const StartSessionBtn = ({
  roomId,
  sessionId,
}: {
  roomId: string
  sessionId: string
}) => {
  const isProvider = isProviderRoute()
  const notify = useNotify()
  const navigate = useNavigate()
  const publisher = usePublisher()
  const [accessAllowed, setAccessAllowed] = useState(false)
  useEffect(() => {
    function onAccessAllowed() {
      console.log('access allowed')
      setAccessAllowed(true)
    }
    async function onAccessDenied() {
      console.log('access denied')
      setAccessAllowed(false)
    }
    console.log(publisher)
    if (publisher) {
      console.log(publisher.accessAllowed)
      setAccessAllowed(publisher.accessAllowed)
      //if you block access after initialization these events dont fire :/
      publisher.on('accessAllowed', onAccessAllowed)
      publisher.on('accessDenied', onAccessDenied)
      return () => {
        publisher.off('accessAllowed', onAccessAllowed)
        publisher.off('accessDenied', onAccessDenied)
      }
    }
  }, [publisher])

  const [req, { isLoading, error }] =
    telehealthApi.useGetCallCredentialsMutation({
      fixedCacheKey: CALL_CREDENTIALS_FIXED_CACHE_KEY,
    })

  useEffect(() => {
    if (error) {
      console.error(error)
      notify({
        severity: 'error',
        message: getErrorDetails(
          error,
          'Failed to initialize video call. Please relaunch video call and try again.',
        ),
      })
    }
  }, [error, notify])

  return (
    <Button
      disabled={!accessAllowed}
      onClick={async () => {
        const res = await req({ roomId, sessionId })
        if ('error' in res) return
        // There is an oportunity for a new session to be created, where the waiting room uses the last (possibly expired) session
        const { data } = res
        navigate(
          getRoutePath('VideoRoom', { roomId, sessionId: data.sessionId }),
        )
      }}
      startIcon={isLoading ? <CircularProgress size={20} /> : undefined}
    >
      {isProvider ? 'Start Video Session' : 'Join Video Session'}
    </Button>
  )
}

const PublisherVideoContainer = ({
  children,
}: {
  children?: ReactNode | ReactNode[]
}) => {
  const ref = usePublisherVideoContainerRef()
  return <VideoContainer ref={ref}>{children}</VideoContainer>
}

export default function WaitingRoom() {
  const notify = useNotify()
  const isProvider = isProviderRoute()
  const { roomId = '', sessionId = '' } = useParams<{
    roomId: string
    sessionId: string
  }>()

  const { isLoading, error, data } =
    telehealthApi.useGetTelehealthCallInfoQuery(
      {
        sessionId,
        roomId,
      },
      {
        selectFromResult: ({ isLoading, error, data }) => ({
          isLoading,
          error,
          data,
        }),
      },
    )

  const me = useReduxSelector(selectOwnParticipantInfo)
  const isVideoDisabled = useReduxSelector(
    (state) => state.app.videoState.videoDisabled,
  )

  useEffect(() => {
    if (error) {
      notify({
        severity: 'error',
        message: getErrorDetails(
          error,
          'An error occured retrieving video session settings.',
          {
            session_expired:
              'This video link is no longer valid. Please try relaunching the video call.',
          },
        ),
      })
    }
  }, [error, notify])

  return (
    <Dialog open fullWidth maxWidth="md" hideBackdrop>
      <DialogTitle>
        New Video Session
        {isProvider && (
          <Typography variant="caption" display="block">
            The patient has not yet been notified of this session. When you are
            ready, please click Start Session. When clicked, the patient will
            receive a &quot;Join Now&quot; email and a notification in their
            mobile app that the session has started.
          </Typography>
        )}
      </DialogTitle>
      <PublisherContextProvider>
        <DialogContent dividers>
          <Stack
            direction={['column-reverse', 'column-reverse', 'row']}
            gap={2}
          >
            <Stack
              gap={2}
              sx={{
                flex: '1 1 50%',
              }}
              justifyItems="flex-start"
            >
              {isProvider && (
                <Box>
                  <Typography gutterBottom variant="subtitle1" color="GrayText">
                    Copy Session Invite
                  </Typography>
                  <Stack direction="row" gap={1} alignItems="center">
                    <CopyButton onClick={() => data?.link} />
                    {isLoading ? (
                      <Skeleton
                        width="100%"
                        height="1.5rem"
                        variant="rounded"
                      />
                    ) : (
                      <Typography>{data?.link}</Typography>
                    )}
                  </Stack>
                </Box>
              )}
              <Box>
                <Typography gutterBottom variant="subtitle1" color="GrayText">
                  Participants
                </Typography>
                <List
                  sx={{
                    bgcolor: 'background.default',
                  }}
                >
                  {isLoading ? (
                    <>
                      <ListItem>
                        <Skeleton
                          width="100%"
                          variant="rounded"
                          height="3.5rem"
                        />
                      </ListItem>
                      <ListItem>
                        <Skeleton
                          width="100%"
                          variant="rounded"
                          height="3.5rem"
                        />
                      </ListItem>
                    </>
                  ) : (
                    data?.participants.map((participant) => (
                      <ParticipantListItem
                        key={participant.id}
                        participant={participant}
                      />
                    ))
                  )}
                </List>
              </Box>
            </Stack>

            <Stack gap={2} flex="1 1 50%" position="relative">
              <PublisherVideoContainer>
                <PublisherMuteControls
                  hideLabels
                  sx={{ position: 'absolute', bottom: '1rem', width: '100%' }}
                />
                {me && (
                  <VideoDisabledOverlay
                    videoDisabled={isVideoDisabled}
                    participant={me}
                  />
                )}
              </PublisherVideoContainer>
              <div>
                <Typography gutterBottom variant="subtitle1" color="GrayText">
                  Sources
                </Typography>
                <VideoSourceSelect />
              </div>
              <SpeakerSourceSelect />
              <MicSourceSelect />
              <MicAudioLevelIndicator />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <StartSessionBtn roomId={roomId} sessionId={sessionId} />
        </DialogActions>
      </PublisherContextProvider>
      <DeviceChangeListener />
    </Dialog>
  )
}
