import {
  Connection,
  Event,
  Publisher,
  Session,
  Stream,
  Subscriber,
} from '@opentok/client'
import { SxProps, useNotify } from '@valerahealth/ui-components'
import { NotifyFn } from '@valerahealth/ui-components/features/Notifications/reduxSlice'
import { useEffect } from 'react'

export type VideoState = {
  session: Session | null
  participantConnection: Connection | null
  participantVideoSubscriber: Subscriber | null
  participantScreenshareSubscriber: Subscriber | null
  ownScreensharePublisher: Publisher | null
}
export const defaultVideoState: VideoState = {
  session: null,
  participantConnection: null,
  participantVideoSubscriber: null,
  participantScreenshareSubscriber: null,
  ownScreensharePublisher: null,
}

export const Layout = {
  logo: 'logo',
  main: 'main',
  vid1: 'vid1',
  vid2: 'vid2',
  chat: 'chat',
} as const

export const getVideoContainerSxProps = (
  isSharingOwnScreen: boolean,
  isParticipantSharingScreen: boolean,
) => {
  const mainContentStyle: SxProps = {
    gridArea: Layout.main,
    height: '100%',
    width: '100%',
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }
  const headerVidStyle: SxProps = {
    width: '180px',
    mx: '2px',
  }

  const hasScreenShare = !!(isSharingOwnScreen || isParticipantSharingScreen)

  return {
    // always display publishing vid in vid1 area
    publishingVideoStyle: {
      ...headerVidStyle,
      gridArea: Layout.vid1,
    },
    // if the patient is screen sharing display in main, else hide
    streamingScreenshareStyle: isParticipantSharingScreen
      ? mainContentStyle
      : {
          display: 'none',
        },
    // patients vid will be main unless someone is sharing their screen
    streamingVideoStyle: !hasScreenShare
      ? {
          ...mainContentStyle,
          fontSize: '1.5rem',
          '.MuiAvatar-root': {
            fontSize: '1.5em',
            height: '6rem',
            width: '6rem',
          },
        }
      : {
          ...headerVidStyle,
          gridArea: Layout.vid2,
          fontSize: '.8rem',
        },
    // display in main, unless the patient is sharing screen then hide
    publishingScreenshareStyle:
      !isParticipantSharingScreen && isSharingOwnScreen
        ? mainContentStyle
        : {
            display: 'none',
          },
  }
}

export const usePublishToSession = (
  publisher: Publisher | null | undefined,
  session: Session | null | undefined,
  isParticipantConnected: boolean,
) => {
  const notify = useNotify()
  useEffect(() => {
    if (!publisher || !session) return

    // publish when connected
    if (isParticipantConnected) {
      session.publish(publisher, (err) => {
        if (err) {
          notify({ message: err.message, severity: 'error' })
        }
      })
      //unpublish when disconnected
    } else {
      session.unpublish(publisher)
    }
  }, [publisher, session, isParticipantConnected, notify])
}

/**
 * From TokBox - https://tokbox.com/developer/sdks/js/reference/Session.html
 * Note: If you intend to reuse a Publisher object to publish to different sessions (or the same session) sequentially, add an event listener for the streamDestroyed event dispatched by the Publisher object (when it stops publishing). In the event listener, call the preventDefault() method of the event object to prevent the Publisher's video from being removed from the page.
 */
export function handlePubliserStreamDestroyed(
  session: Session,
  notify: NotifyFn,
) {
  return (
    event: Event<'streamDestroyed', Publisher> & {
      stream: Stream
      reason: string
    },
  ) => {
    event.preventDefault()
    if (event.reason === 'clientDisconnected') {
      // start publishing if participant has connected
      session?.publish(event.target, (error) => {
        if (error) {
          notify({ message: error.message, severity: 'error' })
        }
        console.log(
          'successfully republished publishing camera stream',
          new Date().toString(),
        )
      })
    }
  }
}
