import {
  IconButton as MuiIconButton,
  type IconButtonProps as MuiIconButtonProps,
  Tooltip,
  type TooltipProps,
  IconButtonTypeMap,
  CircularProgress,
} from '@mui/material'
import { forwardRef } from 'react'

export type IconButtonProps<
  D extends React.ElementType = IconButtonTypeMap['defaultComponent'],
  P extends {} = {},
> = MuiIconButtonProps<
  D,
  P & {
    title?: string
    arrow?: boolean
    placement?: TooltipProps['placement']
    isLoading?: boolean
  }
>

/** Mui Icon button wrapped in a tooltip, since we always want to provide a textual description for users */
const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      title,
      placement = 'bottom',
      arrow = false,
      children,
      isLoading,
      sx,
      ...rest
    }: IconButtonProps,
    ref,
  ) => {
    const button = (
      <MuiIconButton {...rest} ref={ref} sx={{ position: 'relative', ...sx }}>
        {children}
        {isLoading && (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            size="100%"
          />
        )}
      </MuiIconButton>
    )

    if (title) {
      return (
        <Tooltip
          describeChild
          title={title}
          placement={placement}
          arrow={arrow}
          enterDelay={750}
          disableInteractive
        >
          {/* If a button is disabled all event handlers are cancelled, and the tooltip needs those event handlers, so wrapping in a span */}
          <span>{button}</span>
        </Tooltip>
      )
    }
    return button
  },
)

export default IconButton
