import { ReactNode } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3'
import { enUS } from 'date-fns/locale'

/* necessary for MUI date based components */
export default function MuiLocalizationProvider({
  children,
}: {
  children: ReactNode
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      {children}
    </LocalizationProvider>
  )
}
