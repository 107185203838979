import {
  useEffect,
  useState,
  useRef,
  useCallback,
  createContext,
  useContext,
  type ReactNode,
  type RefObject,
  useMemo,
} from 'react'
import { useDispatch } from 'react-redux'
import {
  generateEmbedUrl,
  SessionEvent,
  useReduxSelectorWithAuthState,
  type AuthActionPayloads,
  getLoginUrl,
} from '@valerahealth/redux-auth'
import {
  Dialog,
  DialogTitle,
  Button,
  Divider,
  CircularProgress,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from '../../utils/hooks'
import { throttle } from '../../utils'

const sessionOrigin = `http${
  /localhost/.test(process.env.AUTH_UI_DOMAIN) ? '' : 's'
}://${process.env.AUTH_UI_DOMAIN}`

// if target is self, redirects to the page
const handleLogin = (target?: '_self') =>
  window.open(
    getLoginUrl(
      sessionOrigin,
      target ? window.window.location.href : undefined,
    ),
    target,
    target ? undefined : 'height=600,width=600',
  )

type AuthMonitorProps = {
  /* the environment specific session origin, i.e. https://authenticate{.ENV}.valerahealth.com */
  children: ReactNode | ReactNode[]
  subscribeToUpdates?: boolean
}

const LogoutContext = createContext<{
  logout: () => void
  iframeRef: RefObject<HTMLIFrameElement>
}>({
  logout: () => {},
  iframeRef: { current: null },
})
LogoutContext.displayName = 'AuthMonitorLogoutContext'

/** returns a logout function */
export const useAuthMonitorLogout = () => useContext(LogoutContext).logout

/** used to post messages to the auth-ui iframe (at time of writing comment its happening in UserAccount component to notify that a user has updated their info) */
export const useNotifyAuthUI = () => {
  const { iframeRef } = useContext(LogoutContext)
  return (event: SessionEvent) => {
    iframeRef.current?.contentWindow?.postMessage(event, sessionOrigin)
  }
}

/** depends on the app implementing reducerWithAuth, manages auth sync accross hosts, warns when session is about to expire and returns users to login screen upon logout */
export default function AuthMonitor({
  children,
  subscribeToUpdates = true,
}: AuthMonitorProps) {
  const dispatch = useDispatch()

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const { isAuthenticated, showInactivityWarning } =
    useReduxSelectorWithAuthState((state) => state.auth)

  const [isUserInitiatedLogout, setIsUserInitiatedLogout] = useState(false)
  const [warningCountdown, setWarningCountdown] = useState(0)

  const [t] = useTranslation()

  useEffect(() => {
    const postActivity = () =>
      iframeRef.current?.contentWindow?.postMessage(
        SessionEvent.PageActivity,
        sessionOrigin,
      )

    // 30 seconds... we dont need to update activity very often
    const activityHandler = throttle(postActivity, 30000)
    if (isAuthenticated) {
      window.addEventListener('mousemove', activityHandler)
      // iframe doesn't register its event handler in time if we send it as soon as the page mounts.
      setTimeout(postActivity, 300)
    }
    // clean up event handlers on unmount
    return () => {
      activityHandler.cancel()
      window.removeEventListener('mousemove', activityHandler)
    }
  }, [isAuthenticated])

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    if (showInactivityWarning) {
      setWarningCountdown(
        // seconds left until expiration
        Math.floor((showInactivityWarning - Date.now()) / 1000),
      )
      interval = setInterval(() => {
        setWarningCountdown(
          // seconds left until expiration
          Math.floor((showInactivityWarning - Date.now()) / 1000),
        )
      }, 1000)
    } else if (interval !== null) clearInterval(interval)
    return () => {
      if (interval !== null) clearInterval(interval)
    }
  }, [showInactivityWarning, setWarningCountdown])

  // mount/unmount effect
  useEffect(() => {
    // receives the updated session state from the child iframe
    function listener(e: MessageEvent<AuthActionPayloads>) {
      if (e.origin === sessionOrigin) {
        if (isAuthenticated === null && e.data === null) {
          // page is uninitialized and we are not logged in, so start the oauth process
          handleLogin()
        } else if (
          e.data.type === 'auth/setAuthState' &&
          !e.data.payload &&
          isUserInitiatedLogout
        ) {
          // we have logged out and gotten back null session state from the iframe, safe to navigate to logout page
          handleLogin('_self')
        } else {
          dispatch(e.data)
        }
      }
    }
    window.addEventListener('message', listener)
    return () => {
      window.removeEventListener('message', listener)
    }
  }, [
    dispatch,
    isAuthenticated,
    setIsUserInitiatedLogout,
    isUserInitiatedLogout,
    subscribeToUpdates,
  ])

  const logout = useCallback(() => {
    // notify all other connected pages, it should bubble up a null auth object which will trigger the redirect above
    iframeRef.current?.contentWindow?.postMessage(
      SessionEvent.Logout,
      sessionOrigin,
    )
    setIsUserInitiatedLogout(true)
  }, [])

  const contextValue = useMemo(
    () => ({
      logout,
      sessionOrigin,
      iframeRef,
    }),
    [logout],
  )

  return (
    <>
      <iframe
        title="Valera Health Authentication"
        ref={iframeRef}
        src={generateEmbedUrl(sessionOrigin, subscribeToUpdates)}
        style={{
          height: 0,
          width: 0,
          visibility: 'hidden',
          position: 'fixed',
          top: '-10px',
        }}
      />
      <Dialog
        open={
          !!isAuthenticated && !!showInactivityWarning && warningCountdown > 0
        }
      >
        <DialogTitle>{t('authMonitor.inactivityWarningTitle')}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            {t('authMonitor.inactivityWarningMessage', {
              seconds: warningCountdown,
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        sx={{
          '& .MuiBackdrop-root': {
            bgcolor: 'rgb(127,127,127)',
          },
        }}
        open={!isAuthenticated}
      >
        {isAuthenticated === null ? (
          <Box padding={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle>{t('authMonitor.sessionExpired')}</DialogTitle>
            <Divider />
            <Button sx={{ borderRadius: 0 }} onClick={() => handleLogin()}>
              {t('authMonitor.ctaSignBackIn')}
            </Button>
          </>
        )}
      </Dialog>
      {isAuthenticated && (
        <LogoutContext.Provider value={contextValue}>
          {children}
        </LogoutContext.Provider>
      )}
    </>
  )
}
