import * as React from 'react'
import { useTranslation } from '@valerahealth/ui-translation'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  type DialogProps,
  ButtonProps,
  IconButton,
} from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'

export type ConfirmationDialogProps = {
  header?: React.ReactNode | React.ReactNode[]
  body?: React.ReactNode | React.ReactNode[]
  confirmLabel?: string
  cancelLabel?: string
  onConfirm: () => void
  onCancel: () => void
  hideCancelBtn?: boolean
  hideConfirmBtn?: boolean
  confirmButtonColor?: ButtonProps['color']
  cancelButtonColor?: ButtonProps['color']
  extraButtons?: Array<
    ButtonProps & {
      label: string | React.ReactNode
    }
  >
} & Omit<DialogProps, 'children'>

type ConfirmFuncType = (a: boolean) => void

export function ConfirmationDialog(props: ConfirmationDialogProps) {
  const {
    header,
    body,
    onConfirm,
    onCancel,
    cancelLabel,
    confirmLabel,
    maxWidth = 'sm',
    confirmButtonColor = 'primary',
    cancelButtonColor,
    hideCancelBtn,
    hideConfirmBtn,
    extraButtons,
    ...other
  } = props
  const [t] = useTranslation()

  return (
    <Dialog maxWidth={maxWidth} {...other}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {header}
        {onCancel ? (
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseOutlined />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>{body}</DialogContent>
      <DialogActions>
        {!hideCancelBtn && (
          <Button
            color={cancelButtonColor || 'inherit'}
            variant="text"
            autoFocus
            onClick={onCancel}
          >
            {cancelLabel || t('cancel')}
          </Button>
        )}
        {!hideConfirmBtn && (
          <Button color={confirmButtonColor} type="submit" onClick={onConfirm}>
            {confirmLabel || t('confirm')}
          </Button>
        )}
        {extraButtons?.map((p) => (
          <Button
            color={confirmButtonColor}
            type="submit"
            {...p}
            onClick={(e) => {
              if (p.onClick) p.onClick(e)
              onConfirm()
            }}
          >
            {p.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  )
}

export type ConfirmDialogArguments = Omit<
  Partial<ConfirmationDialogProps>,
  'onConfirm' | 'onCancel' | 'open'
>

const initialState = {
  confirmFunc: null,
  header: '',
  body: '',
  cancelLabel: undefined,
  confirmLabel: undefined,
}

export type ConfirmTrigger = (
  props: ConfirmDialogArguments | void,
) => Promise<boolean>

export const useConfirmationDialog = () => {
  const [confirmProps, setConfirmProps] = React.useState<
    {
      confirmFunc: null | ConfirmFuncType
    } & ConfirmDialogArguments
  >(initialState)

  const { confirmFunc } = confirmProps

  /** this function accepts any props of the ConfirmDialog, in case things like the header text, body text, etc need to be specific to a given confirmation */
  const confirm = React.useCallback<ConfirmTrigger>(
    (props) => {
      return new Promise((res) => {
        setConfirmProps(() => ({
          confirmFunc: res,
          ...props,
        }))
      })
    },
    [setConfirmProps],
  )

  const handleConfirm = React.useCallback(() => {
    if (confirmFunc) confirmFunc(true)
    setConfirmProps(() => initialState)
  }, [confirmFunc, setConfirmProps])

  const handleCancel = React.useCallback(() => {
    if (confirmFunc) confirmFunc(false)
    setConfirmProps(() => initialState)
  }, [confirmFunc, setConfirmProps])

  const ConfirmDialog = React.useCallback(
    (props: ConfirmDialogArguments) => {
      const { confirmFunc, ...rest } = confirmProps
      return (
        <ConfirmationDialog
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          open={!!confirmFunc}
          {...props}
          {...rest}
        />
      )
    },
    [handleConfirm, handleCancel, confirmProps],
  )

  return {
    confirm,
    ConfirmationDialog: ConfirmDialog,
  }
}
