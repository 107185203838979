import { SxProps } from '@valerahealth/ui-components'
import { TelehealthParticipant } from 'api'
import { VideoContainerOverlay } from 'components/AudioVideoControls'
import {
  ParticipantAvatar,
  ParticipantAvatarProps,
} from 'components/participant/Avatar'

export function VideoDisabledOverlay({
  videoDisabled,
  participant,
  sx,
  avatarProps,
}: {
  videoDisabled?: boolean
  participant: TelehealthParticipant
  sx?: SxProps
  avatarProps?: Partial<ParticipantAvatarProps>
}) {
  if (!videoDisabled) return null
  return (
    <VideoContainerOverlay sx={sx}>
      <ParticipantAvatar showName participant={participant} {...avatarProps} />
    </VideoContainerOverlay>
  )
}
