export default {
  gridFilterInputLabel: 'Valor',
  appsList: 'Valera Apps',
  UserAccount: {
    formTitle: 'Actualizar información de cuenta',
    accountFormMenuItemLabel: 'Cuenta',
    introduction: 'Hola, {{name}}',
    iconTitle: 'Menú de la cuenta',
  },
  UserAccountForm: {
    updateSuccess: 'Los ajustes de su cuenta han sido actualizados.',
    currentPassword: 'Contraseña actual',
    proposedPassword: 'Contraseña nueva',
    repeatProposedPassword: 'Repita la nueva contraseña',
    passwordsDoNotMatch: 'Las contraseñas no coinciden',
    mustContainNumber: '{{field}} debe contener un número.',
    mustContainSpecialCharacter:
      '{{field}} debe contener un carácter especial.',
    mustContainUppercase: '{{field}} debe contener un carácter en mayúscula',
    mustContainLowercase: '{{field}} debe contener un carácter en minúscula.',
    updatePasswordFailure: 'No se puede actualizar la contraseña.',
    updatePasswordSuccess: 'Su contraseña fue actualizada exitosamente.',
    incorrectCurrentPassword: 'El dato de su contraseña actual es incorrecto.',
  },
  FileUpload: {
    allowedFileTypes: 'Tipos de archivo permitidos: {{fileTypes}}',
    placeholder: 'Haga clic para cargar un archivo',
    dialogTitle: 'Cargar un archivo para {{field}}',
    invalidFileName: 'Nombre de archivo inválido.',
    invalidCharacters:
      'No se permiten los siguientes caracteres en los nombres de archivos: # % & $ < > * ? \\ / { } $ ! \' " + ` | = : @',
    invalidStartOfFile:
      'El nombre del archivo debe empezar con un carácter alfabético o numérico',
    invalidEndOfFile:
      'El nombre del archivo debe terminar en un carácter alfabético o numérico, o con un paréntesis',
    invalidEmptyFileName: 'El archivo debe tener un nombre',
    Dropzone: {
      helperText:
        '<click>Haga clic para cargar</click> o use la función arrastrar y soltar',
      validationText: '{{accept, list(type: disjunction)}}',
      hasErrorsTitle: 'Hay uno o más errores',
      maxSize: 'máximo {{maxSize, bytes}}',
      minSize: 'mínimo {{minSize, bytes}}',
      'file-invalid-type': 'Tipo de archivo inválido',
      'file-too-large': 'El archivo es demasiado grande',
      'file-too-small': 'El archivo es demasiado pequeño',
      tooManyFiles_one: 'Sólo se permite un archivo',
      tooManyFiles_other: 'Sólo se permite un máximo de {{count}} archivos',
    },
  },
  authMonitor: {
    signedOut: 'Ha cerrado sesión satisfactoriamente.',
    sessionExpired: 'Su sesión ha expirado.',
    ctaSignBackIn: 'Vuelva a iniciar sesión',
    inactivityWarningTitle: 'Advertencia de inactividad',
    inactivityWarningMessage:
      'Si permanece inactivo, su sesión se cerrará en {{seconds}}.',
  },
  notificationsMoreInfo: 'Más información',
  persistentDataGrid: {
    defaultView: 'Vista predeterminada',
    isDefault: 'Es predeterminada',
    setAsDefault: 'Establecer como predeterminada',
    editView: 'Editar vista',
    presetViewsGroupLabel: 'Vistas predefinidas',
    savedViewsGroupLabel: 'Sus vistas guardadas',
    createNewSelectionOption: 'Crear nueva vista "{{name}}"',
    savedViewsTextFieldLabel: 'Vistas guardadas',
    savedViewsTextFieldPlaceholder: 'Seleccionar una vista',
    viewDialogTitleAdd: 'Añadir nueva vista de cuadrícula',
    viewDialogTitleEdit: 'Editar vista {{name}}',
    viewDialogNameIsEmptyErr: 'Es necesario un nombre.',
    viewDialodNameIsNotUniqueErr:
      'Ya existe una vista con el nombre "{{name}}".',
  },
  editableDataGrid: {
    actionsColumn: 'Acciones',
    genericInvalidRowMessage: 'Hay un problema con esta fila.',
  },
  removeSort: 'Remover',
  ascendingString: 'A-Z',
  descendingString: 'Z-A',
  ascendingNumber: 'Número',
  descendingNumber: 'Número',
  ascendingDate: 'Fecha',
  descendingDate: 'Fecha',
  ascendingCount: 'Conteo',
  descendingCount: 'Conteo',
  CommentAuthor: 'Por: {{author}}',
  Protected: {
    title: 'Acceso denegado',
    message:
      'Usted no tiene permiso para ver esta página. Por favor, contacte a provider-support@valerahealth.com para solicitar acceso.',
    description:
      'Usted necesita un "{{Permission}}" de acceso para poder ver esta página.',
  },
  export: 'Exportar',
  density: 'Densidad',
  emailSupport: 'Soporte por correo electrónico',
  PatientTypeAhead: {
    placeholder: 'Escriba para buscar pacientes',
  },
}
