/** used to communicate between parent and child auth services */
export enum SessionEvent {
  Logout = 'logout',
  PageActivity = 'page_activity',
  UserUpdate = 'user_update',
}

const URL_PARAM = 'parentOrigin'

export const getParentOrigin = () =>
  new URLSearchParams(window.location.search).get(URL_PARAM) || ''

export enum AuthRoutes {
  OAuth = 'oauth',
  SignIn = 'login',
  Session = 'session',
  StaticSession = 'static-session'
}

export const getLoginUrl = (sessionOrigin: string, returnUrl = '') => `${sessionOrigin}/${AuthRoutes.SignIn}${returnUrl && `?returnUrl=${encodeURIComponent(
  window.location.href,
)}`}`

// if subscribeToUpdates, auth will stay in sync with any other tabs that also subscribe to updates
export const generateEmbedUrl = (sessionOrigin: string, subscribeToUpdates = true) =>
  `${sessionOrigin}/${subscribeToUpdates ? AuthRoutes.Session : AuthRoutes.StaticSession}?${new URLSearchParams({
    [URL_PARAM]: window.location.origin,
  }).toString()}`
