import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  useDispatch,
  useSelector,
  type TypedUseSelectorHook,
} from 'react-redux'
import sessionStorage from 'redux-persist/lib/storage/session'
import localStorage from 'redux-persist/lib/storage'
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistReducer,
  createTransform,
} from 'redux-persist'
import { notificationsReducer } from '@valerahealth/ui-components/features'
import {
  reducerPath,
  reducer,
  authTransform,
  AuthState,
} from '@valerahealth/redux-auth'
import { telehealthApi } from 'api'
import {
  reducer as appReducer,
  reducerPath as appReducerPath,
} from './appSlice'
import {
  reducer as callStatsReducer,
  reducerPath as callStatsReducerPath,
  type CallStatsState,
} from './callStatsSlice'

/** handles the redux-persist configuration, if you want to configure yourself use the exported reducer directly */
const persistedAuthReducer = persistReducer<AuthState>(
  {
    key: reducerPath,
    version: 1,
    storage: sessionStorage,
    transforms: [authTransform],
  },
  reducer,
)

const persistedCallStatsReducer = persistReducer<CallStatsState>(
  {
    key: callStatsReducerPath,
    version: 1,
    // want to persist this even if user closes browser and jumps back on call
    storage: localStorage,
  },
  callStatsReducer,
)

// no good way to 'blacklist' nested state, so wrote this. Cant have chat messages be persisted because the websocket is constructed when the first cache key is added which is skipped during a rehydrate
const telehealthApiTransformer = createTransform(
  (inboundState: ReturnType<typeof telehealthApi.reducer>) => {
    const { provided, queries, subscriptions, ...rest } = inboundState
    const keysToRemove = new Set(
      Object.values(provided.CHAT_MESSAGE || {}).flat() as string[],
    )
    return {
      ...rest,
      provided: Object.fromEntries(
        Object.entries(provided).filter(([key]) => key !== 'CHAT_MESSAGE'),
      ),
      queries: Object.fromEntries(
        Object.entries(queries).filter(([key]) => !keysToRemove.has(key)),
      ),
      subscriptions: Object.fromEntries(
        Object.entries(subscriptions).filter(([key]) => !keysToRemove.has(key)),
      ),
    }
  },
  null,
  { whitelist: [telehealthApi.reducerPath] },
)

const rootReducer = combineReducers({
  [telehealthApi.reducerPath]: telehealthApi.reducer,
  [appReducerPath]: appReducer,
  [reducerPath]: persistedAuthReducer,
  [callStatsReducerPath]: persistedCallStatsReducer,
  ...notificationsReducer,
})

export type RootReducer = typeof rootReducer

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage: sessionStorage,
    blacklist: [...Object.keys(notificationsReducer)],
    transforms: [telehealthApiTransformer],
  },
  rootReducer,
) as RootReducer

export default persistedReducer
