import { useParams } from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@valerahealth/ui-components'
import { useReduxSelector } from 'store'
import { selectCallCredentials } from 'store/selectors'
import { PublisherContextProvider } from 'components/PublisherVideoContext'
import { DeviceChangeListener } from 'components/DeviceHandler'
import { VideoCall } from './VideoCall'

export default function VideoRoom() {
  const callCreds = useReduxSelector(selectCallCredentials)
  const { roomId } = useParams<{ roomId: string }>()
  if (!callCreds || !roomId)
    return (
      <Dialog open>
        <DialogTitle>Video Call Unauthenticated</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Direct access to this page is not allowed. Please relaunch the video
            call and try again.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )
  return (
    <PublisherContextProvider>
      <VideoCall {...callCreds} />
      <DeviceChangeListener />
    </PublisherContextProvider>
  )
}
