/* eslint no-param-reassign:0 */

import { removeUndefinedKeysRecursive } from '@valerahealth/ui-components/utils/data'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type PatientAuthState = {
  token: string
  /** dont confuse patient id for treatment id */
  patientId: string
  roomId: string
}

export type DeviceInfo = Pick<
  MediaDeviceInfo,
  'kind' | 'deviceId' | 'groupId' | 'label'
>

export type VideoState = {
  micSourceId?: string
  speakerSourceId?: string
  videoSourceId?: string
  audioDisabled: boolean
  videoDisabled: boolean
  audioVideoSources: DeviceInfo[]
}

export type AppState = {
  patientAuth: PatientAuthState | null
  videoState: VideoState
  isChatOpen: boolean
  unreadChatMsgCount: number
}

const initialState: AppState = {
  patientAuth: null,
  isChatOpen: false,
  unreadChatMsgCount: 0,
  videoState: {
    audioDisabled: false,
    videoDisabled: false,
    audioVideoSources: [],
  },
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPatientAuth: (
      state,
      { payload }: PayloadAction<PatientAuthState | null>,
    ) => {
      state.patientAuth = payload
    },
    setVideoState: (state, { payload }: PayloadAction<Partial<VideoState>>) => {
      state.videoState = {
        ...state.videoState,
        ...removeUndefinedKeysRecursive(payload),
      }
    },
    toggleAudio: (state) => {
      state.videoState.audioDisabled = !state.videoState.audioDisabled
    },
    toggleVideo: (state) => {
      state.videoState.videoDisabled = !state.videoState.videoDisabled
    },
    changeMicSource: (state, { payload }: PayloadAction<string>) => {
      state.videoState.micSourceId = payload
    },
    changeSpeakerSource: (state, { payload }: PayloadAction<string>) => {
      state.videoState.speakerSourceId = payload
    },
    changeVideoSource: (state, { payload }: PayloadAction<string>) => {
      state.videoState.videoSourceId = payload
    },
    toggleChat: (state) => {
      state.isChatOpen = !state.isChatOpen
      if (state.isChatOpen) {
        state.unreadChatMsgCount = 0
      }
    },
    incrementUnreadChatMessages: (state) => {
      state.unreadChatMsgCount++
    },
  },
})

export const { actions, reducer, name: reducerPath } = appSlice

export type AppActionPayloads = ReturnType<
  (typeof actions)[keyof typeof actions]
>

export default appSlice
