import { Routes, Route, Outlet } from 'react-router-dom'
import {
  AuthMonitor,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@valerahealth/ui-components'
import Page from 'layouts/Page'
import { useReduxSelector } from 'store'
import PatientSignIn from './PatientSignIn'
import WaitingRoom from './WaitingRoom'
import {
  basePatientPath,
  baseProviderPath,
  callPath,
  roomPath,
  waitingPath,
} from './utils'
import VideoCall from './VideoCall'
import { ProviderEndCall } from './ProviderEndCall'
import { PatientEndCall } from './PatientEndCall'

const ProtectPatientRoute = () => {
  const isAuthenticated = useReduxSelector((store) => !!store.app.patientAuth)

  if (isAuthenticated) return <Outlet />
  return (
    <Dialog open>
      <DialogTitle>Unauthorized</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Access to this page requires you to be signed in.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

const ProtectedProviderRoute = () => {
  return (
    <AuthMonitor subscribeToUpdates={false}>
      <Outlet />
    </AuthMonitor>
  )
}

export default function Router() {
  return (
    <Routes>
      <Route element={<Page />}>
        <Route
          path={
            /** THIS MUST NOT CHANGE AS WE BACKEND DEPENDENCIES THAT TIE TO THIS ROUTE */
            `${basePatientPath}/login`
          }
          element={<PatientSignIn />}
        />

        <Route path={basePatientPath} element={<ProtectPatientRoute />}>
          <Route path={roomPath}>
            <Route path={callPath} element={<VideoCall />} />
            <Route path={waitingPath} element={<WaitingRoom />} />
          </Route>
          <Route path="end" element={<PatientEndCall />} />
        </Route>

        <Route path={baseProviderPath} element={<ProtectedProviderRoute />}>
          <Route path={roomPath}>
            <Route path={callPath} element={<VideoCall />} />
            <Route path={waitingPath} element={<WaitingRoom />} />
          </Route>
          <Route path="end" element={<ProviderEndCall />} />
        </Route>

        <Route
          path="*"
          element={
            <Dialog open fullWidth maxWidth="xs" hideBackdrop>
              <DialogTitle>404 Error</DialogTitle>
              <DialogContent dividers>
                <DialogContentText>Page not found</DialogContentText>
              </DialogContent>
            </Dialog>
          }
        />
      </Route>
    </Routes>
  )
}
