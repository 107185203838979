/* eslint no-param-reassign:0 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { createTransform, persistReducer } from 'redux-persist'
import { AuthState, AuthSession, AuthUser } from './model'

export const AUTH_REDUCER_KEY = 'auth'

export type RootStateWithAuth = {
  [AUTH_REDUCER_KEY]: AuthState
}

const initialState: AuthState = {
  session: null,
  showInactivityWarning: null,
  isAuthenticated: null,
}

const authSlice = createSlice({
  name: AUTH_REDUCER_KEY,
  initialState,
  reducers: {
    toggleInactivityWarning: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.showInactivityWarning = payload
    },
    setAuthState: (state, { payload }: PayloadAction<AuthSession | null>) => {
      state.isAuthenticated = !!payload
      state.session = payload
      if (!payload) state.showInactivityWarning = null
    },
    updateUser: (state, { payload }: PayloadAction<Partial<AuthUser>>) => {
      if (state.session?.user) {
        state.session.user = {
          ...state.session?.user,
          ...payload,
        }
      }
    },
  },
})

export const { actions, reducer, name: reducerPath } = authSlice

export type AuthActionPayloads = ReturnType<
  (typeof actions)[keyof typeof actions]
>

// this is a hack, but oh well, cant access the whole state object in a transform :(. Make sure the session key is defined before isAuthenticated or else this doesnt work
let accessTokenExp: number = 0
let refreshTokenExp: number = 0
/** need to incorporate into the root reducer  when we rehydrate we should recheck if isAuthenticated */
export const authTransform = createTransform(
  null,
  (state: AuthState[keyof AuthState], key: keyof AuthState) => {
    if (key === 'session') {
      accessTokenExp = (state as AuthSession | null)?.accessToken.exp ?? 0
      refreshTokenExp = (state as AuthSession | null)?.refreshToken.exp ?? 0
    }
    if (key === 'isAuthenticated') {
      // our accessToken may be expired but our
      const isRefreshValid = refreshTokenExp > Date.now() / 1000
      const isAccessTokenValid = accessTokenExp > Date.now() / 1000
      accessTokenExp = 0 // reset them it
      refreshTokenExp = 0
      return isAccessTokenValid
        ? // instant access to the app
          true
        : isRefreshValid
        ? // if access token expired, but we have a valid refresh token, we'll get a new access token from auth. null presents a loading popup
          null
        : // if all tokens expired have isAuth = false will present a modal to log in again
          false
    }
    return state as AuthState[keyof AuthState]
  },
)

const persistAuthConfig = {
  key: AUTH_REDUCER_KEY,
  version: 3,
  storage,
  transforms: [authTransform],
}
/** handles the redux-persist configuration, if you want to configure yourself use the exported reducer directly */
const persistedReducer = persistReducer<AuthState>(persistAuthConfig, reducer)

export const authReducer = {
  [authSlice.name]: persistedReducer,
}
