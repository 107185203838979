import {
  type ReactNode,
  forwardRef,
  useEffect,
  useState,
  ComponentPropsWithoutRef,
} from 'react'
import {
  Box,
  SxProps,
  Stack,
  SingleSelect,
  MenuItem,
  throttle,
  LinearProgress,
} from '@valerahealth/ui-components'
import {
  VideocamOutlined,
  VideocamOffOutlined,
  MicNone,
  MicOffOutlined,
  VolumeUpOutlined,
} from '@valerahealth/ui-components/icons'
import { usePublisher } from 'components/PublisherVideoContext'
import { useReduxDispatch, useReduxSelector } from 'store'
import { actions } from 'store/appSlice'
import {
  selectMicSources,
  selectSpeakerSources,
  selectVideoSources,
} from 'store/selectors'
import { LabeledFab } from './LabeledFab'

export const PublisherMuteControls = ({
  sx,
  hideLabels,
}: {
  sx?: SxProps
  hideLabels?: boolean
}) => {
  const dispatch = useReduxDispatch()
  const { audioDisabled, videoDisabled } = useReduxSelector(
    (state) => state.app.videoState,
  )
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
        ...sx,
      }}
    >
      <LabeledFab
        onClick={() => dispatch(actions.toggleVideo())}
        label={hideLabels ? undefined : 'Camera'}
      >
        {videoDisabled ? <VideocamOffOutlined /> : <VideocamOutlined />}
      </LabeledFab>
      <LabeledFab
        onClick={() => dispatch(actions.toggleAudio())}
        label={hideLabels ? undefined : 'Mic'}
      >
        {audioDisabled ? <MicOffOutlined /> : <MicNone />}
      </LabeledFab>
    </Box>
  )
}

export const VideoContainer = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'> & {
    sx?: SxProps
    children?: ReactNode | ReactNode[]
    micMuted?: boolean
  }
>(({ sx, micMuted, children, ...rest }, ref) => (
  <Box
    sx={{
      width: '100%',
      height: '0px',
      paddingTop: '75%',
      position: 'relative',
      ...sx,
    }}
    {...rest}
  >
    <Box
      ref={ref}
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
      }}
    />
    {micMuted && (
      <Box
        sx={{
          position: 'absolute',
          top: '0.5rem',
          right: '0.5rem',
          zIndex: 3,
          //fontSize scales both the padding and icon size
          fontSize: '1.25rem',
          padding: '0.25em',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          backgroundColor: (theme) =>
            `rgba(0,0,0,${theme.palette.action.disabledOpacity})`,
          color: (theme) => theme.palette.common.white,
        }}
      >
        <MicOffOutlined sx={{ fontSize: '1em' }} color="inherit" />
      </Box>
    )}
    {children}
  </Box>
))

export const VideoContainerOverlay = ({
  children,
  sx,
}: {
  children: ReactNode | ReactNode[]
  sx?: SxProps
}) => {
  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        zIndex: 2,
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: (theme) => theme.palette.grey[800],
        color: (theme) => theme.palette.common.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export const VideoSourceSelect = () => {
  const dispatch = useReduxDispatch()
  const value = useReduxSelector(
    ({ app }) => app.videoState.videoSourceId ?? null,
  )
  const options = useReduxSelector(selectVideoSources)

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <VideocamOutlined />
      <SingleSelect
        fullWidth
        value={value}
        onChange={(value) => {
          if (value) {
            dispatch(actions.changeVideoSource(value))
          }
        }}
      >
        {options.map(({ deviceId, label }) => (
          <MenuItem key={deviceId} value={deviceId}>
            {label}
          </MenuItem>
        ))}
      </SingleSelect>
    </Stack>
  )
}

export const MicSourceSelect = () => {
  const dispatch = useReduxDispatch()
  const value = useReduxSelector(
    ({ app }) => app.videoState.micSourceId ?? null,
  )
  const options = useReduxSelector(selectMicSources)

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <MicNone />
      <SingleSelect
        fullWidth
        value={value}
        onChange={(value) => {
          if (value) {
            dispatch(actions.changeMicSource(value))
          }
        }}
      >
        {options.map(({ deviceId, label }) => (
          <MenuItem key={deviceId} value={deviceId}>
            {label}
          </MenuItem>
        ))}
      </SingleSelect>
    </Stack>
  )
}

export const SpeakerSourceSelect = () => {
  const dispatch = useReduxDispatch()
  const value = useReduxSelector(
    ({ app }) => app.videoState.speakerSourceId ?? null,
  )
  const options = useReduxSelector(selectSpeakerSources)

  const handleChange = (value: string | null) => {
    if (value) {
      dispatch(actions.changeSpeakerSource(value))
    }
  }

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <VolumeUpOutlined />
      <SingleSelect fullWidth value={value} onChange={handleChange}>
        {options.map(({ deviceId, label }) => (
          <MenuItem key={deviceId} value={deviceId}>
            {label}
          </MenuItem>
        ))}
      </SingleSelect>
    </Stack>
  )
}

export const MicAudioLevelIndicator = () => {
  const publisher = usePublisher()
  const [audioLevel, setAudioLevel] = useState(0)
  useEffect(() => {
    const handleAudioLevelUpdated = throttle(
      ({ audioLevel }: { audioLevel: number }) => {
        setAudioLevel(Math.min(audioLevel * 100 * 2, 100))
      },
      100,
    )
    publisher?.on('audioLevelUpdated', handleAudioLevelUpdated)
    return () => {
      publisher?.off('audioLevelUpdated', handleAudioLevelUpdated)
    }
  }, [publisher])

  return (
    <LinearProgress
      sx={{ transition: 'none' }}
      variant="determinate"
      value={audioLevel}
    />
  )
}
