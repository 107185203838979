export const baseProviderPath = 'provider'
export const basePatientPath = 'telehealth'

export const roomPath = 'room/:roomId'
export const waitingPath = 'waiting/:sessionId'
export const callPath = 'call'

type RouteParams = {
  roomId: string
  sessionId: string
}

export const getRoutePaths = ({ roomId, sessionId }: RouteParams) => ({
  providerWaitingRoom: `/${baseProviderPath}/room/${roomId}/waiting/${sessionId}`,
  patientWaitingRoom: `/${basePatientPath}/room/${roomId}/waiting/${sessionId}`,
  providerVideoRoom: `/${baseProviderPath}/room/${roomId}/${callPath}`,
  patientVideoRoom: `/${basePatientPath}/room/${roomId}/${callPath}`,
})

export const isProviderRoute = () =>
  window.location.pathname.startsWith(`/${baseProviderPath}`)
export const isPatientRoute = () =>
  window.location.pathname.startsWith(`/${basePatientPath}`)

export const getRoutePath = (
  route: 'WaitingRoom' | 'VideoRoom',
  params: RouteParams,
) => {
  const p = isProviderRoute() ? 'provider' : 'patient'
  const routes = getRoutePaths(params)
  return routes[`${p}${route}`]
}

export const getCallEndRoute = (reason?: string) => {
  return `/${isProviderRoute() ? baseProviderPath : basePatientPath}/end${
    reason ? `?reason=${reason}` : ''
  }`
}
