import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import {
  TranslationProvider,
  type TranslationProviderPropsType,
} from '@valerahealth/ui-translation'
import baseTranslations from '@valerahealth/ui-translation/locales/en'
import {
  theme,
  ThemeProvider,
  LocalizationProvider,
  en as uiComponentLocalesEn,
  UI_COMPONENTS_LOCALS_NAMESPACE,
  Notifications,
} from '@valerahealth/ui-components'
import { store, persistor } from 'store/index'
import appTranslations from 'locales/en'
import Router from 'routes'

const TranslationProps: Omit<TranslationProviderPropsType, 'children'> = {
  ns: ['appTranslations', 'baseTranslations', UI_COMPONENTS_LOCALS_NAMESPACE],
  fallbackNS: 'baseTranslations',
  defaultNS: 'appTranslations',
  staticResources: {
    en: {
      appTranslations,
      baseTranslations,
      ...uiComponentLocalesEn,
    },
  },
}

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<div>...loading</div>} persistor={persistor}>
        <TranslationProvider {...TranslationProps}>
          <LocalizationProvider>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <Router />
                <Notifications />
              </ThemeProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </TranslationProvider>
      </PersistGate>
    </Provider>
  )
}
