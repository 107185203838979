import { useSelector, useDispatch } from 'react-redux'
import { Snackbar, Alert, Button, IconButton, Box } from '@mui/material'
import { Close } from '@mui/icons-material'
import { actions, selectLastActiveNotification } from './reduxSlice'
import { useTranslation } from '../../utils/hooks'

export type NotificationProps = {
  /** number of miliseconds to wait before notification is hidden - defaults to 3000 */
  autoHideDuration?: number
  onAdditionalInfoClick?: (contents: {
    additionalInfo: any
    additionalInfoType: string
  }) => void
}

/** Put me in the root of your app :) You must include the reducer in your application before you use this! */
export default function Notifications({
  autoHideDuration = 3000,
  onAdditionalInfoClick,
}: NotificationProps) {
  const notification = useSelector(selectLastActiveNotification)
  const dispatch = useDispatch()
  const [t] = useTranslation()

  if (!notification) return null

  const {
    id,
    message,
    severity,
    additionalInfo,
    additionalInfoType,
    verticalPosition,
    sticky,
  } = notification || {}

  const dismiss = () => dispatch(actions.dismiss(id))

  return (
    <Snackbar
      key={message}
      open
      anchorOrigin={{
        vertical: verticalPosition || 'top',
        horizontal: 'center',
      }}
      onClose={dismiss}
      autoHideDuration={sticky ? null : autoHideDuration}
    >
      <Alert
        elevation={8}
        severity={severity}
        action={
          <Box display="flex" gap={1}>
            {onAdditionalInfoClick && additionalInfo && additionalInfoType && (
              <Button
                variant="text"
                color="inherit"
                size="small"
                onClick={() =>
                  onAdditionalInfoClick({
                    additionalInfo,
                    additionalInfoType,
                  })
                }
              >
                {t('notificationsMoreInfo')}
              </Button>
            )}
            <IconButton size="small" onClick={dismiss}>
              <Close />
            </IconButton>
          </Box>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
