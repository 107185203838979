import { Dispatch, SetStateAction, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  CenteredSpinner,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Box,
  IconButton,
  Stack,
  DialogActions,
  Button,
  Chip,
  TextField,
} from '@valerahealth/ui-components'
import { telehealthApi, FeedbackConfigRes, FeedbackInput } from 'api'
import { useReduxSelector } from 'store'
import { selectCallDuration } from 'store/selectors'

import Sentiment1 from 'images/Sentiment1'
import Sentiment2 from 'images/Sentiment2'
import Sentiment3 from 'images/Sentiment3'
import Sentiment4 from 'images/Sentiment4'
import Sentiment5 from 'images/Sentiment5'

const sentiments = [
  {
    value: 1,
    Icon: Sentiment1,
    reaction: (
      <>
        <Typography gutterBottom>
          We&apos;re very sorry to hear that.
        </Typography>
        <Typography gutterBottom>What could we have done better?</Typography>
      </>
    ),
    color: 'error' as const,
    title: 'Very Poor',
    showTags: true,
  },
  {
    value: 2,
    Icon: Sentiment2,
    reaction: (
      <>
        <Typography gutterBottom>
          We&apos;re very sorry to hear that.
        </Typography>
        <Typography gutterBottom>What could we have done better?</Typography>
      </>
    ),
    color: 'warning' as const,
    title: 'Poor',
    showTags: true,
  },
  {
    value: 3,
    Icon: Sentiment3,
    reaction: (
      <>
        <Typography gutterBottom>Thank you for the feedback.</Typography>
        <Typography gutterBottom>Where could we improve?</Typography>
      </>
    ),
    color: 'info' as const,
    title: 'Neutral',
    showTags: true,
  },
  {
    value: 4,
    Icon: Sentiment4,
    reaction: (
      <>
        <Typography gutterBottom>
          Thanks! We would love to to learn more.
        </Typography>
        <Typography gutterBottom>Where could we improve?</Typography>
      </>
    ),
    color: 'success' as const,
    title: 'Good',
    showTags: true,
  },
  {
    value: 5,
    Icon: Sentiment5,
    showTags: false,
    color: 'success' as const,
    title: 'Very Good',
  },
]

type FeedbackFormType = Required<FeedbackInput> & {
  freetext: string
}

function Feedback({
  config,
  feedback,
  setFeedback,
}: {
  config?: FeedbackConfigRes | null
  feedback: FeedbackFormType
  setFeedback: Dispatch<SetStateAction<FeedbackFormType>>
}) {
  if (!config) return null

  const { showTags, reaction } = sentiments[feedback.rating - 1] || {
    reaction: null,
  }

  const showFreetextInput = feedback.tags.some((t) => t.freetext)

  return (
    <Box>
      <Typography gutterBottom>How did the session go?</Typography>
      <Stack direction="row" gap={1} alignItems="center">
        {sentiments.map(({ Icon, value, color, title }) => {
          const selected = feedback.rating === value

          return (
            <IconButton
              key={value}
              title={title}
              onClick={() => {
                setFeedback((f) => ({ ...f, rating: value }))
              }}
              color={color}
              sx={
                selected
                  ? {
                      backgroundColor: (theme) =>
                        theme.palette.withAlpha(
                          theme.palette[color].main,
                          theme.palette.action.selectedOpacity,
                        ),
                    }
                  : {
                      color: (theme) => theme.palette.text.secondary,
                    }
              }
            >
              <Icon />
            </IconButton>
          )
        })}
      </Stack>
      {reaction}
      {showTags && (
        <>
          <Stack direction="row" gap={1} my={1}>
            {config.tags.map((tag) => {
              const valueIndex = feedback.tags.findIndex(
                (t) => t.type === tag.type,
              )
              return (
                <Chip
                  label={tag.name}
                  variant={valueIndex > -1 ? 'filled' : 'outlined'}
                  color="primary"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setFeedback((f) => ({
                      ...f,
                      tags:
                        valueIndex > -1
                          ? f.tags.filter((v, i) => i !== valueIndex)
                          : f.tags.concat(tag),
                    }))
                  }}
                />
              )
            })}
          </Stack>
          {showFreetextInput && (
            <>
              <Typography mb={2}>
                Detailed Feedback will allow us to address the problem:
              </Typography>
              <TextField
                fullWidth
                multiline
                label="Add a note for our Team"
                value={feedback.freetext}
                onChange={(e) =>
                  setFeedback((v) => ({ ...v, freetext: e.target.value }))
                }
              />
            </>
          )}
        </>
      )}
    </Box>
  )
}

export const PatientEndCall = () => {
  const [params] = useSearchParams()
  const reason = params.get('reason')
  const durationSeconds = useReduxSelector(selectCallDuration) / 1000
  const { data: config, ...configRes } =
    telehealthApi.useGetFeedbackConfigQuery(undefined, {
      skip: !durationSeconds,
    })
  const [postFeedback, postFeedbackRes] =
    telehealthApi.usePostFeedbackMutation()
  const [feedback, setFeedback] = useState<FeedbackFormType>({
    rating: 0,
    tags: [],
    freetext: '',
  })

  const hasMetSessionThreshold =
    config &&
    (!config.videoSessionTimeSec ||
      durationSeconds > config.videoSessionTimeSec)

  return (
    <Dialog open>
      <DialogTitle>Your Video Session Has Ended</DialogTitle>
      <DialogContent dividers>
        {reason && (
          <DialogContentText>
            Your provider ended the session.
          </DialogContentText>
        )}
        {(configRes.isLoading || postFeedbackRes.isLoading) && (
          <CenteredSpinner />
        )}
        {hasMetSessionThreshold && postFeedbackRes.isUninitialized && (
          <Feedback config={config} {...{ feedback, setFeedback }} />
        )}

        {
          /* session ended and we dont need feedback*/ !hasMetSessionThreshold &&
            config && (
              <Typography gutterBottom>
                You may now close your browser window.
              </Typography>
            )
        }
        {
          /** its not worth showing an error response at this point since feedback is an optional nice to have from patients. if the API call has completed we show the confirmation page */
          (postFeedbackRes.isSuccess || postFeedbackRes.isError) && (
            <>
              <Typography gutterBottom>Thank you!</Typography>
              <Typography gutterBottom>
                We appreciate and welcome opportunities to improve our services.
              </Typography>
              <Typography gutterBottom>
                You may now close your browser window.
              </Typography>
            </>
          )
        }
      </DialogContent>
      {hasMetSessionThreshold && postFeedbackRes.isUninitialized && (
        <DialogActions>
          <Button
            color="success"
            disabled={!feedback.rating || postFeedbackRes.isLoading}
            onClick={async () => {
              const { freetext: _freetext, tags: _tags, rating } = feedback
              const freetext = _freetext.trim()
              const tags = freetext.length
                ? _tags.map((t) => (t.freetext ? { ...t, name: freetext } : t))
                : _tags
              await postFeedback({
                rating,
                // freetext goes into name property of tag (pattern is weird i know!)
                tags: tags.length ? tags : undefined,
              })
            }}
          >
            Submit
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
