/* eslint-disable no-param-reassign, @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { type AlertColor } from '@mui/material'

export const NOTIFICATIONS_REDUCER_KEY = 'notifications'

export type Notification = {
  /** main message displayed */
  message: string
  severity: AlertColor
  /** if present, alert will dislay a "more info" button */
  additionalInfo?: any
  additionalInfoType?: string
  /** internally set */
  isDismissed: boolean
  verticalPosition?: 'top' | 'bottom'
  sticky?: boolean
  id: number
}

export type NotificationsState = Notification[]

const initialState: NotificationsState = []

const notifications = createSlice({
  name: NOTIFICATIONS_REDUCER_KEY,
  initialState,
  reducers: {
    notify: (
      state,
      { payload }: PayloadAction<Omit<Notification, 'isDismissed' | 'id'>>,
    ) => {
      state.push({
        ...payload,
        isDismissed: false,
        id: state.length,
      })
    },
    dismiss: (state, { payload }: PayloadAction<number | undefined>) => {
      if (payload !== undefined) {
        const notification = state[payload]
        if (notification) notification.isDismissed = true
      } else {
        //dismiss all
        state.forEach((s) => {
          if (!s.isDismissed) s.isDismissed = true
        })
      }
    },
  },
})

type RootState = {
  [NOTIFICATIONS_REDUCER_KEY]: NotificationsState
}
const selectNotifications = (state: RootState) =>
  state[NOTIFICATIONS_REDUCER_KEY]

export const selectActiveNotifications = createSelector(
  selectNotifications,
  (notifications) => notifications.filter((n) => !n.isDismissed),
)
export const selectNotification = createSelector(
  selectNotifications,
  (state: RootState, id: number) => id,
  (notifications, id) => notifications[id],
)
export const selectLastActiveNotification = createSelector(
  selectActiveNotifications,
  (notes): Notification | null => notes[notes.length - 1] || null,
)

export const { actions, reducer, name: reducerPath } = notifications

export type NotifyFn = (...args: Parameters<typeof actions.notify>) => void

export const useNotify = () => {
  const dispatch = useDispatch()
  const notifyFn: NotifyFn = useCallback(
    (...args) => {
      dispatch(actions.notify(...args))
    },
    [dispatch],
  )
  return notifyFn
}

export const useDismiss = () => {
  const dispatch = useDispatch()
  const notifyFn = useCallback(
    (...args: Parameters<typeof actions.dismiss>) => {
      dispatch(actions.dismiss(...args))
    },
    [dispatch],
  )
  return notifyFn
}
