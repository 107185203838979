import {
  type TypedUseSelectorHook,
  useSelector,
  shallowEqual,
} from 'react-redux'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import { type AuthState } from './model'
import { Permission } from './generated'
import { AUTH_REDUCER_KEY, type RootStateWithAuth } from './reduxSlice'

const createShallowEqualSelector = createSelectorCreator(defaultMemoize, {
  equalityCheck: shallowEqual,
  resultEqualityCheck: shallowEqual,
  maxSize: 100,
})

export const selectUser = (state: RootStateWithAuth) =>
  state[AUTH_REDUCER_KEY].session?.user

export const selectUserPermissions = createSelector(
  selectUser,
  (user) => user?.permissions || [],
)

export const checkPermissions = createShallowEqualSelector(
  selectUserPermissions,
  (state: RootStateWithAuth, perms: Permission[]) => perms,
  (userPermissions, perms) => {
    return perms.map((p) => userPermissions.includes(p))
  },
)
export const checkPermission = createSelector(
  selectUserPermissions,
  (state: RootStateWithAuth, perm: Permission) => perm,
  (userPermissions, perm) => {
    return userPermissions.includes(perm)
  },
)

/** checks if the current user has ALL of the permissions specified */
export const hasAllPermissions = createShallowEqualSelector(
  selectUserPermissions,
  (state: RootStateWithAuth, perms: Permission | Permission[]) => perms,
  (userPermissions, perms) =>
    typeof perms === 'string'
      ? userPermissions.includes(perms)
      : perms.every((v) => userPermissions.includes(v)),
)

/** checks if the current user has ANY of the permissions specified */
export const hasAnyPermission = createShallowEqualSelector(
  selectUserPermissions,
  (state: RootStateWithAuth, perms: Permission | Permission[]) => perms,
  (userPermissions, perms) =>
    typeof perms === 'string'
      ? userPermissions.includes(perms)
      : perms.some((v) => userPermissions.includes(v)),
)

/** compares a provdier or care manager id against the current logged in user */
export const doesUserMatchProviderId = createSelector(
  selectUser,
  (state: RootStateWithAuth, providerId: string) => providerId,
  (
    state: RootStateWithAuth,
    providerId: string,
    /** defaults to providerId */
    idType?: 'providerId' | 'careManagerId',
  ) => idType || 'providerId',
  (user, providerId, idType) =>
    // make sure this returns false if user has an empty string as an id
    !!user?.[idType] && user?.[idType] === providerId,
)

/** checks if a user is a provider by the existance of a providerId */
export const checkIsUserAProvider = createSelector(
  selectUser,
  (user) => !!user?.providerId,
)

const providerPermissions = [
  Permission.Provider_Update,
  Permission.Provider_UpdateOwn,
]
/** Only checks that the user is a provider and has UpdateOwn permissions */
export const selectCanUpdateOwnProviderProfile = createSelector(
  checkIsUserAProvider,
  (state: RootStateWithAuth) => hasAnyPermission(state, providerPermissions),
  (isProvider, hasPerms) => isProvider && hasPerms,
)

/** given a provider id and an array of 'own' permissions, checks if the specified id is the current user and they have any of the permissions provided */
export const checkOwnPermissions = createShallowEqualSelector(
  (
    state: RootStateWithAuth,
    permissions: Permission[],
    providerId: string,
    idType?: 'providerId' | 'careManagerId',
  ) => doesUserMatchProviderId(state, providerId, idType),
  checkPermissions,
  (userIsProvider, hasPermisssions) =>
    hasPermisssions.map((p) => userIsProvider && p),
)
/**
 *
 */
export const selectIsAuthorizedOwn = createShallowEqualSelector(
  (
    state: RootStateWithAuth,
    permission: Permission | Permission[],
    providerId: string,
    /** defaults to provider id */
    idType?: 'providerId' | 'careManagerId',
  ) => doesUserMatchProviderId(state, providerId, idType),
  (
    state: RootStateWithAuth,
    permission: Permission | Permission[],
    providerId: string,
    idType?: 'providerId' | 'careManagerId',
    /** default to any */
    multipleLogic?: 'any' | 'all',
  ) =>
    multipleLogic === 'any'
      ? hasAnyPermission(state, permission)
      : hasAllPermissions(state, permission),
  (userMatchProvider, hasPermission) => userMatchProvider && hasPermission,
)

export const useReduxSelectorWithAuthState: TypedUseSelectorHook<{
  auth: AuthState
}> = useSelector

export const programIdSelector = (state: RootStateWithAuth) =>
  state.auth.session?.user.programId || ''
