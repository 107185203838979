import {
  ReactNode,
  RefObject,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import OT, { Publisher } from '@opentok/client'
import {
  Typography,
  useConfirmationDialog,
  useNotify,
  Link,
} from '@valerahealth/ui-components'
import {
  getParticipantInitials,
  getParticipantName,
} from 'components/participant/utils'
import { getState, useReduxDispatch, useReduxSelector } from 'store'
import { DeviceInfo, actions } from 'store/appSlice'
import {
  selectMicSources,
  selectOwnParticipantInfo,
  selectVideoSources,
} from 'store/selectors'
import LABELS from 'locales/en'
import { shallowEqual } from 'react-redux'
import { getDevices } from './DeviceHandler'

type PublisherContextType = {
  publisher: Publisher | undefined
  videoContainerRef: RefObject<HTMLDivElement>
}

const initialState: PublisherContextType = {
  publisher: undefined,
  videoContainerRef: { current: null },
}

const PublisherContext = createContext<PublisherContextType>(initialState)
PublisherContext.displayName = 'TokboxAudioVideoPublisher'

export const PublisherContextProvider = memo(
  ({ children }: { children: ReactNode | ReactNode[] }) => {
    const notify = useNotify()

    const {
      audioDisabled,
      videoDisabled,
      micSourceId,
      videoSourceId,
      videoSources,
      micSources,
    } = useReduxSelector((state) => {
      const { speakerSourceId, audioVideoSources, ...rest } =
        state.app.videoState
      const videoSources = selectVideoSources(state)
      const micSources = selectMicSources(state)
      return {
        ...rest,
        videoSources,
        micSources,
      }
    }, shallowEqual)

    const dispatch = useReduxDispatch()
    const usrInfo = useReduxSelector(selectOwnParticipantInfo)
    const [publisher, setPublisher] = useState<Publisher | undefined>()
    const { confirm, ConfirmationDialog } = useConfirmationDialog()
    const videoContainerRef = useRef<HTMLDivElement>(null)

    // Initialize the publisher
    useEffect(() => {
      if (!videoContainerRef.current || !usrInfo) return
      console.log('Publisher initialing effect running')
      const { audioDisabled, videoDisabled, micSourceId, videoSourceId } =
        getState().app.videoState

      const publisher = OT.initPublisher(
        videoContainerRef.current,
        {
          width: '100%',
          height: '100%',
          resolution: '640x480',
          frameRate: 15,
          showControls: false,
          publishAudio: !audioDisabled,
          publishVideo: !videoDisabled,
          enableDtx: true,
          audioSource: micSourceId,
          videoSource: videoSourceId,
          audioBitrate: 28000,
          audioFallback: {
            publisher: true,
            subscriber: true,
          },
          name: getParticipantName(usrInfo),
          initials: getParticipantInitials(usrInfo),
          style: {
            nameDisplayMode: 'off',
          },
        },
        async (error) => {
          //This function resolves once browser access to camera/microphone is allowed/not allowed

          // anything rendered within our context provider can subscribe to events by interacting with the publisher directly, these are event listeners we want to control globally whenever someone uses the publisher context

          publisher.on('accessDenied', async () => {
            await confirm({
              header: 'Access Error',
              body: (
                <>
                  <Typography gutterBottom>
                    Your browser permissions have denied Valera Health access to
                    the camera and/or microphone. You will be unable to join a
                    session unless both the camera and microphone access have
                    been granted.
                  </Typography>
                  <Typography gutterBottom>
                    Click{' '}
                    <Link
                      to="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop"
                      target="_blank"
                    >
                      here
                    </Link>{' '}
                    to learn how to update your browser permissions.
                  </Typography>
                  <Typography>
                    Once you have changed your permissions, refresh the page to
                    continue.
                  </Typography>
                </>
              ),
              confirmLabel: 'Refresh Page',
              hideCancelBtn: true,
              confirmButtonColor: 'primary',
            })
            window.location.reload()
          })

          if (error) {
            console.error(error)
            notify({
              severity: 'warning',
              message: error.message,
            })
            return
          }

          const audioSource = publisher.getAudioSource()
          const videoSource = publisher.getVideoSource()
          const deviceInfo = await getDevices()
          // update state to what got actually used by the publisher, plus our initial list of devices
          dispatch(
            actions.setVideoState({
              audioVideoSources: deviceInfo.audioVideoSources,
              speakerSourceId: deviceInfo.speakerSource?.deviceId ?? undefined,
              micSourceId: audioSource.getSettings().deviceId,
              videoSourceId: videoSource.deviceId ?? undefined,
              videoDisabled: !videoSource.track?.enabled,
              audioDisabled: !audioSource.enabled,
            }),
          )
          setPublisher(publisher)
        },
      )

      return () => {
        publisher.destroy()
        setPublisher(undefined)
      }
    }, [usrInfo, confirm, dispatch, notify])

    /**
     *
     * Effects to keep the mic sources up to date
     *
     * */

    // respond to mute/unmute
    useEffect(() => {
      publisher?.publishAudio(!audioDisabled)
    }, [publisher, audioDisabled])

    // respond to mic source changes
    useEffect(() => {
      if (publisher) {
        const activeDeviceId = publisher
          .getAudioSource?.()
          ?.getSettings().deviceId
        if (micSourceId && activeDeviceId !== micSourceId) {
          publisher.setAudioSource(micSourceId)
        }
      }
    }, [publisher, micSourceId])

    //respond to audio source device changes
    useEffect(() => {
      // browser will automatically switch between default devices and the MediaStreamTrack will be retained (say you turn on a new pair of headphones and they connect and become the default audio in/out source), but if you are on a non-default device and turn it off, the MediaStreamTrack will end, and we will have to switch to a new one.
      if (publisher) {
        const audioTrack = publisher.getAudioSource()

        // currently used
        let publisherMicSrc = ((): Pick<
          DeviceInfo,
          'deviceId' | 'label'
        > | null => {
          const track = audioTrack
          const { deviceId } = track.getSettings()
          if (!track || !deviceId) return null
          return {
            label: track.label,
            deviceId,
          }
        })()

        // if not in the new list, we need to pick a new one
        if (!micSources.some((v) => v.deviceId === publisherMicSrc?.deviceId)) {
          const nextDevice: DeviceInfo | undefined =
            micSources.find((v) => v.deviceId === 'default') || micSources[0]
          if (!nextDevice) {
            notify({
              severity: 'error',
              message: `Your ${LABELS.audioinput} ${audioTrack.label} was disconnected. No other video devices are available.`,
            })
          } else {
            publisher.setAudioSource(nextDevice.deviceId)
            publisherMicSrc = nextDevice
          }
        }

        // if publisher does not equal state, update state
        if (
          publisherMicSrc &&
          publisherMicSrc.deviceId !== getState().app.videoState.micSourceId
        ) {
          dispatch(actions.changeMicSource(publisherMicSrc.deviceId))
          notify({
            severity: 'info',
            message: `${LABELS.audioinput} switched to ${publisherMicSrc.label}`,
          })
        }
      }
    }, [publisher, micSources, dispatch, notify])

    /**
     *
     * Effects to keep the video sources up to date
     *
     * */

    // respond to video disable/enable
    useEffect(() => {
      publisher?.publishVideo(!videoDisabled)
    }, [publisher, videoDisabled])

    // respond to vid source changes
    useEffect(() => {
      if (publisher) {
        const activeDeviceId = publisher
          .getVideoSource()
          ?.track?.getSettings().deviceId
        if (videoSourceId && activeDeviceId !== videoSourceId) {
          publisher.setAudioSource(videoSourceId)
        }
      }
    }, [publisher, videoSourceId])

    //respond to vid source device changes
    useEffect(() => {
      // browser will automatically switch between default devices and the MediaStreamTrack will be retained (say you turn on a new pair of headphones and they connect and become the default audio in/out source), but if you are on a non-default device and turn it off, the MediaStreamTrack will end, and we will have to switch to a new one.
      if (publisher) {
        // currently used
        let publisherVidSrc = ((): Pick<
          DeviceInfo,
          'deviceId' | 'label'
        > | null => {
          const track = publisher.getVideoSource()?.track
          const { deviceId } = track?.getSettings() || {}
          if (!track || !deviceId) return null
          return {
            label: track.label,
            deviceId,
          }
        })()

        // if not in the new list, we need to pick a new one
        if (
          !videoSources.some((v) => v.deviceId === publisherVidSrc?.deviceId)
        ) {
          const nextDevice =
            videoSources.find((v) => v.deviceId === 'default') ||
            videoSources[0]
          if (!nextDevice) {
            notify({
              severity: 'error',
              message: `Your ${LABELS.videoinput} ${
                publisherVidSrc?.label || ''
              } was disconnected. No other video devices are available.`,
            })
          } else {
            publisher.setVideoSource(nextDevice.deviceId)
            publisherVidSrc = nextDevice
          }
        }

        // if publisher does not equal state, update state
        if (
          publisherVidSrc?.deviceId &&
          publisherVidSrc.deviceId !== getState().app.videoState.videoSourceId
        ) {
          dispatch(actions.changeVideoSource(publisherVidSrc.deviceId))
          notify({
            severity: 'info',
            message: `${LABELS.videoinput} switched to ${publisherVidSrc.label}`,
          })
        }
      }
    }, [publisher, videoSources, dispatch, notify])

    const value = useMemo(() => ({ publisher, videoContainerRef }), [publisher])
    return (
      <PublisherContext.Provider value={value}>
        <ConfirmationDialog />
        {children}
      </PublisherContext.Provider>
    )
  },
)

export const usePublisher = () => {
  const context = useContext(PublisherContext)
  return context.publisher
}

export const usePublisherVideoContainerRef = () => {
  const context = useContext(PublisherContext)
  return context.videoContainerRef
}

export const usePublisherContext = () => useContext(PublisherContext)
