import { useEffect, useState } from 'react'
import { useTranslation } from '@valerahealth/ui-translation'
import { useFormContext } from 'react-hook-form'
import { type SxProps } from '@mui/system'
import { type Theme } from '@mui/material'
import { Button, CircularProgress, Zoom } from '@mui/material'
import { Check as CheckIcon, WarningAmber } from '../../icons'
import { useReadOnlyForm } from '../utils'

export type SaveButtonProps = {
  /** allows override of the form context read only */
  readOnly?: boolean
  /** if the form passed validation but failed when saving to an external api */
  isError: boolean
  /** if the form saved to an external API successfully */
  isSuccess: boolean
  disabled?: boolean
  /** defaults to the translation of 'save' */
  label?: string
  sx?: SxProps<Theme>
}

export function SaveButton({
  isError,
  label,
  isSuccess,
  disabled,
  sx,
  readOnly,
  ...props
}: SaveButtonProps) {
  const [t] = useTranslation()
  const { formState } = useFormContext()
  const [showCheckIcon, setShowCheckIcon] = useState(false)
  const readOnlyForm = useReadOnlyForm().readOnly
  const hideButton = readOnly !== undefined ? readOnly : readOnlyForm

  useEffect(() => {
    if (isSuccess) {
      setShowCheckIcon(true)
      const id = setTimeout(() => {
        setShowCheckIcon(false)
      }, 2000)
      return () => clearTimeout(id)
    }
    setShowCheckIcon(false)
  }, [setShowCheckIcon, isSuccess])

  const failedValidation = !!Object.keys(formState.errors).length
  return hideButton ? null : (
    <Button
      sx={sx}
      type="submit"
      size="large"
      color={
        isError
          ? 'error'
          : failedValidation
          ? 'warning'
          : isSuccess
          ? 'success'
          : 'secondary'
      }
      disabled={
        disabled ||
        formState.isSubmitting ||
        !Object.keys(formState?.dirtyFields || {}).length
      }
      startIcon={
        formState.isSubmitting ? (
          <CircularProgress color="inherit" size={15} />
        ) : failedValidation ? (
          <WarningAmber />
        ) : isSuccess ? (
          <Zoom in={showCheckIcon} unmountOnExit timeout={350}>
            <CheckIcon />
          </Zoom>
        ) : null
      }
      {...props}
    >
      {label || t('save')}
    </Button>
  )
}
