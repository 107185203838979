import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import OT, { OTError, Publisher, Session } from '@opentok/client'
import {
  Box,
  SxProps,
  useNotify,
  notificationActions,
} from '@valerahealth/ui-components'
import { GetCallCredentailsRes } from 'api'
import { VideoContainer } from 'components/AudioVideoControls'
import { useReduxDispatch, useReduxSelector } from 'store'
import {
  selectOwnParticipantInfo,
  selectParticipantInfo,
} from 'store/selectors'
import { getCallEndRoute } from 'routes/utils'
import { usePublisherContext } from 'components/PublisherVideoContext'
import { getParticipantName } from 'components/participant/utils'
import { actions as callStatsActions } from 'store/callStatsSlice'
import { CallCounter } from 'components/CallCounter'
import { Chat } from 'components/Chat'
import { VideoDisabledOverlay } from './VideoDisabledOverlay'
import { VideoCallControlBar } from './ControlBar'
import {
  Layout,
  VideoState,
  defaultVideoState,
  getVideoContainerSxProps,
  handlePubliserStreamDestroyed,
  usePublishToSession,
} from './utils'
import { ParticipantVideo } from './ParticipantVideo'

export function VideoCall({
  token,
  openTokSessionId,
  apiKey,
  sessionId,
}: GetCallCredentailsRes) {
  const dispatch = useReduxDispatch()
  const notify = useNotify()
  const navigate = useNavigate()
  const handleError = useCallback(
    (error?: Error) => {
      if (error) {
        console.error(error)
        notify({
          severity: 'warning',
          message: error.message,
        })
      }
    },
    [notify],
  )
  // create open tok session, once connected, instantiate the publisher, one patient connected, connect publisher to session.
  const {
    publisher: cameraPublisher,
    videoContainerRef: publishingVideoContainerRef,
  } = usePublisherContext()
  const participant = useReduxSelector(selectParticipantInfo)!
  const me = useReduxSelector(selectOwnParticipantInfo)!
  const publisherMicMuted = useReduxSelector(
    (state) => state.app.videoState.audioDisabled,
  )
  const publisherVideoDisabled = useReduxSelector(
    (state) => state.app.videoState.videoDisabled,
  )
  const streamingVideoContainerRef = useRef<HTMLDivElement>(null)
  const streamingScreenshareContainerRef = useRef<HTMLDivElement>(null)
  const publishingScreenshareContainerRef = useRef<HTMLDivElement>(null)

  const [videoState, setVideoState] = useState<VideoState>(defaultVideoState)
  // https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state, if a variable isnt added as a dependency to an effect the effect will reference a stale value,
  const videoStateRef = useRef(videoState)
  videoStateRef.current = videoState

  const {
    session,
    participantScreenshareSubscriber,
    ownScreensharePublisher,
    participantVideoSubscriber,
    participantConnection,
  } = videoState

  const {
    streamingVideoStyle,
    streamingScreenshareStyle,
    publishingVideoStyle,
    publishingScreenshareStyle,
  }: Record<string, SxProps> = useMemo(() => {
    return getVideoContainerSxProps(
      !!ownScreensharePublisher,
      !!participantScreenshareSubscriber,
    )
  }, [participantScreenshareSubscriber, ownScreensharePublisher])

  const toggleScreenShare = useCallback(() => {
    if (!session) return
    if (ownScreensharePublisher) {
      setVideoState((s) => ({ ...s, ownScreensharePublisher: null }))
      session.unpublish(ownScreensharePublisher)
      ownScreensharePublisher.destroy()
    } else {
      const publisher = OT.initPublisher(
        publishingScreenshareContainerRef.current!,
        {
          width: '100%',
          height: '100%',
          resolution: '1920x1080',
          frameRate: 15,
          showControls: false,
          publishAudio: false,
          publishVideo: true,
          audioSource: null,
          videoSource: 'screen',
          scalableScreenshare: true,
          videoContentHint: 'detail',
          style: {
            nameDisplayMode: 'off',
          },
          insertMode: 'append',
        },
        (error) => {
          console.log(error)
          // if user cancels the screenshare dialog an error is returned
          if (error) return
          setVideoState((s) => ({ ...s, ownScreensharePublisher: publisher }))
          publisher.on(
            'streamDestroyed',
            handlePubliserStreamDestroyed(session, notify),
          )
          publisher.on('accessDenied', (event) => {
            console.log('publisher.accessDenied', event)
          })
          publisher.on('destroyed', (event) => {
            console.log('publisher.destroyed', event)
          })
          publisher.on('mediaStopped', (event) => {
            // user stopped sharing by external means
            console.log('publisher.mediaStopped', event)
            setVideoState((s) => ({ ...s, ownScreensharePublisher: null }))
            session.unpublish(publisher)
            publisher.destroy()
          })
        },
      )
    }
  }, [session, ownScreensharePublisher, notify])

  // MAIN EFFECT - Initilizes session and listens to connections and subscription events
  useEffect(() => {
    const createSessionConnectCallback =
      (
        session: Session,
        cameraPublisher: Publisher,
        pubStreamDestroyedCb: ReturnType<typeof handlePubliserStreamDestroyed>,
      ) =>
      (error?: OTError) => {
        handleError(error)
        if (error) return
        setVideoState((s) => ({ ...s, session }))
        cameraPublisher.on('streamDestroyed', pubStreamDestroyedCb)

        /**
         *
         * maintains participantConnected - Fires for both you and anyone else that connects
         *
         */
        session.on('connectionCreated', (event) => {
          // this is my connection
          console.log(
            'connectionCreated',
            event,
            event.connection.connectionId === session.connection?.connectionId
              ? 'Own Connection'
              : "Participant's connection",
            new Date().toString(),
          )
          if (
            event.connection.connectionId === session.connection?.connectionId
          )
            return

          // mark the participant as having connected
          setVideoState((s) => ({
            ...s,
            participantConnection: event.connection,
          }))
          // mark the call as having started
          dispatch(callStatsActions.callStarted({ sessionId }))
        })

        /**
         * Only Fires for others leaving
         */
        session.on('connectionDestroyed', (event) => {
          console.log('connectionDestroyed', event)

          // reset participant state
          setVideoState((s) => ({
            ...s,
            participantConnection: null,
            participantScreenshareSubscriber: null,
            participantVideoSubscriber: null,
            participantMicDisabled: false,
            participantVideoDisabled: false,
          }))

          // mark the call as having ended (will get restarted if they join again)
          dispatch(callStatsActions.callEnded({ sessionId }))

          if (event.reason === 'networkDisconnected') {
            // Let the other user know the other participant had network issues
            notify({
              message: `${getParticipantName(
                participant,
              )} disconnected due to network issues`,
              severity: 'warning',
            })
          }
        })

        session.on('sessionDisconnected', (event) => {
          console.log('sessionDisconnected', event)
          // skip ending call if for network issues
          if (event.reason !== 'networkDisconnected') {
            navigate(getCallEndRoute(event.reason))
          } else {
            // reset because at this point everything needs to be rebooted
            setVideoState(defaultVideoState)

            window.addEventListener(
              'online',
              () => {
                console.log('window online event triggered')
                // dismiss previous sticky notifications
                dispatch(notificationActions.dismiss())
                notify({
                  severity: 'success',
                  message: 'Your connection has been restored.',
                })
                // opentok seems to get into a broken state. easiest to just reload the page
                window.location.reload()
              },
              { once: true },
            )
          }
        })

        session.on('sessionReconnecting', (event) => {
          notify({
            severity: 'warning',
            message:
              'Your connection has been interupted. Attempting to reconnect...',
            sticky: true,
          })
          console.log('sessionReconnecting', event)
        })
        session.on('sessionReconnected', (event) => {
          // dismiss previous sticky notifications
          dispatch(notificationActions.dismiss())
          notify({
            severity: 'success',
            message: 'Your connection has been restored.',
          })
          console.log('sessionReconnected', event)
        })

        /**
         *
         * END maintain participantConnected
         *
         */

        /**
         * Maintain new Subscriptions
         */
        session.on('streamCreated', (event) => {
          console.log(
            'streamCreated',
            event,
            cameraPublisher.stream?.streamId === event.stream.streamId ||
              ownScreensharePublisher?.stream?.streamId ===
                event.stream.streamId
              ? `Own ${event.stream.videoType} Stream`
              : `Parcipants ${event.stream.videoType} Stream`,
            new Date().toString(),
          )

          // if its one of our own streams return
          if (
            cameraPublisher.stream?.streamId === event.stream.streamId ||
            videoStateRef.current.ownScreensharePublisher?.stream?.streamId ===
              event.stream.streamId
          )
            return

          const { videoType } = event.stream

          const target =
            videoType === 'camera'
              ? streamingVideoContainerRef.current!
              : streamingScreenshareContainerRef.current!

          // if currently subscribed to the same type, unsubscribe. This may happen if mobile connection drops, then they leave the video call, regain connection, and rejoin... tokbox may not detect the old stream is dropped until the 60 second timeout.
          if (
            videoType === 'camera' &&
            videoStateRef.current.participantVideoSubscriber
          ) {
            session.unsubscribe(
              videoStateRef.current.participantVideoSubscriber,
            )
          }
          if (
            videoType !== 'camera' &&
            videoStateRef.current.participantScreenshareSubscriber
          ) {
            session.unsubscribe(
              videoStateRef.current.participantScreenshareSubscriber,
            )
          }

          const subscriber = session.subscribe(
            event.stream,
            target,
            {
              insertMode: 'append',
              width: '100%',
              height: '100%',
              fitMode: 'contain',
              showControls: false,
              preferredFrameRate: 15,
              style: {
                nameDisplayMode: 'on',
              },
            },
            (error) => {
              handleError(error)
              if (error) return

              const updates: Partial<VideoState> =
                videoType === 'camera'
                  ? {
                      participantVideoSubscriber: subscriber,
                    }
                  : {
                      participantScreenshareSubscriber: subscriber,
                    }

              setVideoState((s) => ({ ...s, ...updates }))

              subscriber?.on('destroyed', () => {
                setVideoState((s) => ({
                  ...s,
                  // reverse the state update that was used when created
                  ...Object.fromEntries(
                    Object.keys(updates).map((v) => [v, null]),
                  ),
                }))
              })
            },
          )
        })
      }

    if (cameraPublisher) {
      const session = OT.initSession(apiKey, openTokSessionId)
      const pubStreamDestroyedCb = handlePubliserStreamDestroyed(
        session,
        notify,
      )

      session.connect(
        token,
        createSessionConnectCallback(
          session,
          cameraPublisher,
          pubStreamDestroyedCb,
        ),
      )

      return () => {
        cameraPublisher.off('streamDestroyed', pubStreamDestroyedCb)
        session.off()
        session.disconnect()
        dispatch(callStatsActions.callEnded({ sessionId }))
        setVideoState((s) => ({ ...s, session: null }))
      }
    }
    // some references are only used in event callbacks. we dont want to rerun the whole effect when they change
    // eslint-disable-next-line
  }, [
    cameraPublisher,
    token,
    openTokSessionId,
    apiKey,
    handleError,
    notify,
    dispatch,
    sessionId,
  ])

  usePublishToSession(cameraPublisher, session, !!participantConnection)
  usePublishToSession(ownScreensharePublisher, session, !!participantConnection)

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1,
        height: '100vh',
        width: '100vw',
        bgcolor: (theme) => theme.palette.background.paper,
        display: 'grid',
        gridTemplateRows: `auto 1fr`,
        gridTemplateColumns: `1fr auto auto 1fr auto`,
        gridTemplateAreas: `
          "${Layout.logo} ${Layout.vid1} ${Layout.vid2} . ${Layout.chat}"
          "${Layout.main} ${Layout.main} ${Layout.main} ${Layout.main} ${Layout.chat}"
        `,
        alignContent: 'stretch',
        justifyContent: 'stretch',
      }}
    >
      <Box
        // Header background
        sx={{
          bgcolor: 'rgba(0,0,0,0.7)',
          color: (theme) => theme.palette.common.white,
          boxShadow: (theme) => theme.shadows[4],
          gridColumn: '1 / span 4',
          gridRow: '1',
        }}
      />
      <Box
        // Logo
        sx={{
          height: '1.5rem',
          width: 'auto',
          margin: '2rem',
          justifySelf: 'start',
          alignSelf: 'center',
          gridArea: Layout.logo,
        }}
        component="img"
        src="https://cdn.valerahealth.com/images/valera-logo-white.svg"
        alt="Valera Health"
      />
      <VideoContainer
        id="publisherVideoShare"
        micMuted={publisherMicMuted}
        ref={publishingVideoContainerRef}
        sx={publishingVideoStyle}
      >
        <VideoDisabledOverlay
          videoDisabled={publisherVideoDisabled}
          participant={me}
        />
      </VideoContainer>
      <VideoContainer
        id="publisherScreenShare"
        ref={publishingScreenshareContainerRef}
        sx={publishingScreenshareStyle}
      />
      <VideoContainer
        id="streamingScreenShare"
        ref={streamingScreenshareContainerRef}
        sx={streamingScreenshareStyle}
      />
      <ParticipantVideo
        ref={streamingVideoContainerRef}
        sx={streamingVideoStyle}
        subscriber={participantVideoSubscriber}
        session={session}
        participantConnected={!!participantConnection}
        participant={participant}
      />
      <VideoCallControlBar
        isScreenSharing={!!ownScreensharePublisher}
        toggleScreenShare={toggleScreenShare}
        participantConnection={participantConnection}
        session={session}
      />
      <CallCounter
        sessionId={sessionId}
        sx={{
          gridRowStart: 2,
          gridColumnStart: 1,
          alignSelf: 'flex-start',
          justifySelf: 'flex-start',
          p: 1,
          m: 1,
          color: (theme) => theme.palette.common.white,
          backgroundColor: 'rgba(0,0,0,0.4)',
          borderRadius: '2px',
          zIndex: 3,
        }}
      />

      <Chat
        sx={{
          gridArea: Layout.chat,
        }}
      />
    </Box>
  )
}
