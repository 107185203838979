import { SxProps, Theme, Typography } from '@valerahealth/ui-components'
import { useEffect, useState } from 'react'
import { useReduxSelector } from 'store'
import { selectCallStats } from 'store/selectors'

export function CallCounter({
  sessionId,
  sx,
}: {
  sessionId: string
  sx?: SxProps<Theme>
}) {
  const { callStarted, callEnded } = useReduxSelector((state) =>
    selectCallStats(state, sessionId),
  )

  const [count, setCount] = useState<null | number>(null)
  useEffect(() => {
    if (callStarted && !callEnded) {
      const interval = setInterval(() => {
        setCount(Date.now() - callStarted)
      }, 1000)
      return () => {
        clearInterval(interval)
        setCount(null)
      }
    }
  }, [callStarted, callEnded])

  const time = callStarted && callEnded ? callEnded - callStarted : count

  if (!time) return null

  const minutes = Math.floor(time / 1000 / 60)
  const seconds = Math.floor(time / 1000) % 60

  return (
    <Typography sx={sx}>
      {minutes < 10 ? `0${minutes}` : minutes}:
      {seconds < 10 ? `0${seconds}` : seconds}
    </Typography>
  )
}
