import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
  useNotify,
} from '@valerahealth/ui-components'
import { Close, Send } from '@valerahealth/ui-components/icons'
import { getErrorDetails, telehealthApi } from 'api'
import { useReduxDispatch, useReduxSelector } from 'store'
import { selectCallCredentials, selectUserId } from 'store/selectors'
import { actions } from 'store/appSlice'
import { useEffect, useState } from 'react'
import { ChatMessage } from './ChatMessage'

export function Chat({ sx }: { sx?: SxProps<Theme> }) {
  const isChatOpen = useReduxSelector((state) => state.app.isChatOpen)
  const dispatch = useReduxDispatch()
  const toggleChat = () => dispatch(actions.toggleChat())
  const notify = useNotify()
  const { roomId = '', sessionId = '' } =
    useReduxSelector(selectCallCredentials) || {}
  const userId = useReduxSelector(selectUserId)

  const [message, setMessage] = useState('')

  const [sendMessage, sendMessageRes] =
    telehealthApi.useSendChatMessageMutation({
      selectFromResult: ({ isLoading, startedTimeStamp }) => ({
        isLoading,
        startedTimeStamp,
      }),
    })

  const handleSendMessage = async () => {
    const val = message.trim()
    if (!val) return
    setMessage('')

    const res = await sendMessage({
      roomId,
      sessionId,
      message: val,
    })

    if ('error' in res) {
      notify({
        severity: 'error',
        message:
          ('data' in res.error ? res.error.data.message : res.error.message) ||
          'Failed to send message.',
      })
      setMessage(val)
    }
  }
  const { data, isLoading, error } = telehealthApi.useGetChatMessagesQuery(
    {
      roomId,
      sessionId,
    },
    {
      skip: !roomId || !sessionId,
      selectFromResult: ({ data, isLoading, error }) => ({
        data,
        isLoading,
        error,
      }),
    },
  )

  useEffect(() => {
    if (error) {
      notify({
        message: getErrorDetails(
          error,
          'There was an error retrieving chat messages',
        ),
        severity: 'error',
      })
    }
  }, [error, notify])

  if (!roomId || !sessionId) return null

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        width: isChatOpen ? 'auto' : '0px',
        minWidth: isChatOpen ? '25rem' : '0px',
        maxWidth: '32rem',
        transition: 'width 0.5s',
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Stack
        sx={{
          height: '100%',
          width: '100%',
          gap: 1,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={1}
        >
          <Typography variant="h4">Chat</Typography>
          <IconButton onClick={toggleChat}>
            <Close />
          </IconButton>
        </Stack>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column-reverse',
            flex: '1 1 100%',
            overflow: 'auto',
          }}
        >
          <Stack
            sx={{
              flex: '1 1 100%',
              justifyContent: 'flex-end',
              gap: 2,
              px: 1,
              minWidth: 'fit-content',
            }}
          >
            {isLoading && <CircularProgress sx={{ alignSelf: 'center' }} />}
            {data?.map((d) => {
              return <ChatMessage messageItem={d} key={d.id} />
            })}
            {sendMessageRes.isLoading && (
              <ChatMessage
                isSending
                messageItem={{
                  createdAt: sendMessageRes.startedTimeStamp || new Date(),
                  body: sendMessageRes.originalArgs?.message || '',
                  senderId: userId || '',
                }}
              />
            )}
          </Stack>
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
          p={1}
        >
          <TextField
            autoComplete="off"
            fullWidth
            autoFocus
            multiline
            value={message}
            onChange={(e) => {
              const { value } = e.target
              if (value.endsWith('\n')) return
              setMessage(value)
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                handleSendMessage()
              }
              e.stopPropagation()
            }}
          />
          <IconButton
            color="primary"
            isLoading={sendMessageRes.isLoading}
            onClick={handleSendMessage}
          >
            <Send />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  )
}
