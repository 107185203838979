import { forwardRef } from 'react'
import {
  type LinkProps as RouterLinkProps,
  useHref,
  useLinkClickHandler,
} from 'react-router-dom'
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'

export type LinkProps = RouterLinkProps & MuiLinkProps

/* mashup of react router link + MUI Link, plus allows links to external urls if to starts with ^http.* */
const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ onClick, replace = false, state, target, to, ...rest }, ref) => {
    const isRemoteHref =
      typeof to === 'string' &&
      (to.startsWith('http') ||
        to.startsWith('mailto:') ||
        to.startsWith('tel:'))

    const localHref = useHref(to)
    const href = isRemoteHref ? to : localHref

    const handleClick = useLinkClickHandler(href, {
      replace,
      state,
      target,
    })

    return (
      <MuiLink
        {...rest}
        href={href as string}
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          onClick?.(event)
          if (event.defaultPrevented || isRemoteHref) return
          handleClick(event)
        }}
        ref={ref}
        target={target}
      />
    )
  },
)

export default Link
