export default {
  gridFilterInputLabel: '价值',
  appsList: 'Valera预约',
  UserAccount: {
    formTitle: '更新账户信息',
    accountFormMenuItemLabel: '账户',
    introduction: '您好,{{name}}',
    iconTitle: '账户菜单',
  },
  UserAccountForm: {
    updateSuccess: '您的账户设置已更新。',
    currentPassword: '当前密码',
    proposedPassword: '新密码',
    repeatProposedPassword: '重复新密码',
    passwordsDoNotMatch: '密码不匹配',
    mustContainNumber: '{{field}}必须包含数字。',
    mustContainSpecialCharacter: '{{field}}必须包含特殊字符。',
    mustContainUppercase: '{{field}}必须包含大写字符。',
    mustContainLowercase: '{{field}}必须包含小写字符。',
    updatePasswordFailure: '无法更新密码。',
    updatePasswordSuccess: '您的密码已成功更新。',
    incorrectCurrentPassword: '您的当前密码不正确。',
  },
  FileUpload: {
    allowedFileTypes: '允许的文件类型: {{fileTypes}}',
    placeholder: '点击上传文件',
    dialogTitle: '上传{{field}}的文件',
    invalidFileName: '无效的文件名。',
    invalidCharacters:
      '文件名中不允许有下列字符: # % & $ < > * ? \\ / { } $ ! \' " + ` | = : @',
    invalidStartOfFile: '文件开头必须是字母或数字字符',
    invalidEndOfFile: '文件结尾必须是字母、数字或括号字符',
    invalidEmptyFileName: '需要文件名',
    Dropzone: {
      helperText: '<click>点击以上传</click>或拖放',
      validationText: '{{accept, list(type: disjunction)}}',
      hasErrorsTitle: '有一个或多个错误',
      maxSize: '最大{{maxSize, bytes}}',
      minSize: '最小{{minSize, bytes}}',
      'file-invalid-type': '无效的文件类型',
      'file-too-large': '文件太大',
      'file-too-small': '文件太小',
      tooManyFiles_one: '只允许一个文件',
      tooManyFiles_other: '最多允许{{count}}个文件',
    },
  },
  authMonitor: {
    signedOut: '您已成功登出。',
    sessionExpired: '您的会话已过期。',
    ctaSignBackIn: '重新登入',
    inactivityWarningTitle: '不活动警告',
    inactivityWarningMessage:
      '如果您保持不活动状态,那么将在{{seconds}}秒后退出。',
  },
  notificationsMoreInfo: '更多信息',
  persistentDataGrid: {
    defaultView: '默认视图',
    isDefault: '是默认值',
    setAsDefault: '设为默认值',
    editView: '编辑视图',
    presetViewsGroupLabel: '预设视图',
    savedViewsGroupLabel: '您保存的视图',
    createNewSelectionOption: '创建新的"{{name}}"视图',
    savedViewsTextFieldLabel: '保存的视图',
    savedViewsTextFieldPlaceholder: '选择视图',
    viewDialogTitleAdd: '添加新的网格视图',
    viewDialogTitleEdit: '编辑{{name}}视图',
    viewDialogNameIsEmptyErr: '姓名是必填字段。',
    viewDialodNameIsNotUniqueErr: '名为"{{name}}"的视图已存在。',
  },
  editableDataGrid: {
    actionsColumn: '操作',
    genericInvalidRowMessage: '这一行有问题。',
  },
  removeSort: '移除',
  ascendingString: 'A-Z',
  descendingString: 'Z-A',
  ascendingNumber: '数字',
  descendingNumber: '数字',
  ascendingDate: '日期',
  descendingDate: '日期',
  ascendingCount: '计数',
  descendingCount: '计数',
  CommentAuthor: '评论人: {{author}}',
  Protected: {
    title: '拒绝访问',
    message:
      '您没有权限查看此页。请发送电子邮件至provider-support@valerahealth.com请求访问。',
    description: '您需要"{{Permission}}"访问权限才能查看此页。',
  },
  export: '导出',
  density: '密度',
  emailSupport: '电子邮箱支持',
  PatientTypeAhead: {
    placeholder: '在此搜索患者',
  },
}
