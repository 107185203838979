import { useEffect } from 'react'
import { debounce } from '@valerahealth/ui-components/utils'
import { getState, useReduxDispatch, useReduxSelector } from 'store'
import { actions } from 'store/appSlice'
import { useNotify } from '@valerahealth/ui-components'
import LABELS from 'locales/en'
import { selectSpeakerSources } from 'store/selectors'

export async function getDevices() {
  try {
    return {
      audioVideoSources: (await navigator.mediaDevices.enumerateDevices()).map(
        ({ deviceId, groupId, kind, label }) => ({
          deviceId,
          groupId,
          kind,
          label,
        }),
      ),
      speakerSource: await OT.getActiveAudioOutputDevice(),
    }
  } catch (e) {
    // most likely a permission denied errro which we have other listeners for
    console.error(e)
    return {}
  }
}

export function DeviceChangeListener() {
  const dispatch = useReduxDispatch()
  const notify = useNotify()
  const speakerSourceId = useReduxSelector(
    (state) => state.app.videoState.speakerSourceId,
  )

  useEffect(() => {
    const deviceChangeListener = debounce(
      async () => {
        console.log('navigator.mediaDevices deviceChange event')
        const { audioVideoSources, speakerSource } = await getDevices()

        if (
          speakerSource?.deviceId &&
          speakerSource?.deviceId !== getState().app.videoState.speakerSourceId
        ) {
          notify({
            severity: 'info',
            message: `${LABELS.audioouput} switched to ${speakerSource.label}`,
          })
        }
        dispatch(
          actions.setVideoState({
            audioVideoSources,
            speakerSourceId: speakerSource?.deviceId ?? undefined,
          }),
        )
      },
      750,
      {
        trailing: true,
        leading: false,
      },
    )

    navigator.mediaDevices.addEventListener(
      'devicechange',
      deviceChangeListener,
    )

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        deviceChangeListener,
      )
    }
  }, [dispatch, notify])

  // update the speaker on mount to whats selected in state
  useEffect(() => {
    OT.getActiveAudioOutputDevice().then((device) => {
      // there is a chance the device in redux state is no longer is available
      if (
        speakerSourceId &&
        selectSpeakerSources(getState()).some(
          (v) => v.deviceId === speakerSourceId,
        ) &&
        device.deviceId !== speakerSourceId
      ) {
        OT.setAudioOutputDevice(speakerSourceId)
      } else if (device.deviceId && device.deviceId !== speakerSourceId) {
        dispatch(actions.changeSpeakerSource(device.deviceId))
      }
    })
  }, [dispatch, speakerSourceId])

  return null
}
