import { ReactNode } from 'react'
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@valerahealth/ui-components'
import { TelehealthParticipant } from 'api'
import { useReduxSelector } from 'store'
import { selectUserId } from 'store/selectors'
import { ParticipantAvatar } from './Avatar'
import { getParticipantName } from './utils'

export function ParticipantListItem({
  participant,
  children,
}: {
  participant: TelehealthParticipant
  children?: ReactNode | ReactNode[]
}) {
  const name = getParticipantName(participant)
  const currentUserId = useReduxSelector(selectUserId)

  return (
    <ListItem>
      <ListItemAvatar>
        <ParticipantAvatar participant={participant} />
      </ListItemAvatar>
      <ListItemText
        primary={`${name} ${participant.id === currentUserId ? '(You)' : ''}`}
        secondary={participant.email}
      />
      {children}
    </ListItem>
  )
}
