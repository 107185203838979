import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@valerahealth/ui-components'
import { useSearchParams } from 'react-router-dom'

export const ProviderEndCall = () => {
  const [params] = useSearchParams()
  const reason = params.get('reason')

  return (
    <Dialog open>
      <DialogTitle>Your Video Session Has Ended</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {reason === 'forceDisconnected'
            ? 'An interuption in service disconnected the call. If this was not intentional, please close the window and start a new video session to continue.'
            : 'You may now close your browser window.'}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
