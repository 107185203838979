import {
  Fab,
  FabProps,
  Stack,
  Typography,
  TypographyProps,
} from '@valerahealth/ui-components'

export function LabeledFab({
  label,
  children,
  labelProps,
  ...rest
}: { label?: string; labelProps?: TypographyProps } & FabProps) {
  return (
    <Stack display="inline-flex" gap={0.5} alignItems="center">
      <Fab size="small" {...rest}>
        {children}
      </Fab>
      {label && (
        <Typography variant="caption" color="inherit" {...labelProps}>
          {label}
        </Typography>
      )}
    </Stack>
  )
}
