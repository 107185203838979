import { forwardRef, useEffect, useState } from 'react'
import {
  CircularProgress,
  SxProps,
  Typography,
  throttle,
} from '@valerahealth/ui-components'
import { Dimensions, Event, Session, Subscriber, Stream } from '@opentok/client'
import {
  VideoContainer,
  VideoContainerOverlay,
} from 'components/AudioVideoControls'
import { TelehealthParticipant } from 'api'
import { getParticipantName } from 'components/participant/utils'
import { VideoDisabledOverlay } from './VideoDisabledOverlay'
type ParticipantVideoProps = {
  sx?: SxProps
  subscriber: Subscriber | null
  session: Session | null
  participantConnected: boolean
  participant: TelehealthParticipant
}
export const ParticipantVideo = forwardRef<
  HTMLDivElement,
  ParticipantVideoProps
>(({ sx, subscriber, session, participantConnected, participant }, ref) => {
  const [state, setState] = useState({
    hasAudio: !!(subscriber?.stream?.hasAudio || true),
    hasVideo: !!(subscriber?.stream?.hasVideo || true),
    disconnected: false,
  })

  const [audioLevel, setAudioLevel] = useState(0)

  // subscriber event handlers
  useEffect(() => {
    const handleAudioLevelChange = throttle(
      (
        event: Event<'audioLevelUpdated', Subscriber> & {
          audioLevel: number
        },
      ) => {
        setAudioLevel(event.audioLevel)
      },
      50,
    )

    const handlers = {
      videoDisabled: (event: Event<'videoDisabled', Subscriber>) => {
        console.log(
          'participantVideoSubscriber.videoDisabled',
          event,
          new Date().toString(),
        )
        setState((s) => ({ ...s, hasVideo: false }))
        subscriber?.on('audioLevelUpdated', handleAudioLevelChange)
      },
      videoEnabled: (event: Event<'videoEnabled', Subscriber>) => {
        console.log(
          'participantVideoSubscriber.videoEnabled',
          event,
          new Date().toString(),
        )
        setState((s) => ({ ...s, hasVideo: true }))
        subscriber?.off('audioLevelUpdated', handleAudioLevelChange)
      },
      connected: (event: Event<'connected', Subscriber>) => {
        console.log(
          'participantVideoSubscriber.connected',
          event,
          new Date().toString(),
        )
        setState((s) => ({ ...s, disconnected: false }))
      },
      disconnected: (event: Event<'disconnected', Subscriber>) => {
        console.log(
          'participantVideoSubscriber.disconnected',
          event,
          new Date().toString(),
        )
        setState((s) => ({ ...s, disconnected: true }))
      },
    }

    if (subscriber) {
      subscriber.on(handlers)
      setState({
        disconnected: !subscriber.stream?.connection,
        hasAudio: !!subscriber.stream?.hasAudio,
        hasVideo: !!subscriber.stream?.hasVideo,
      })

      return () => {
        subscriber.off(handlers)
      }
    }
  }, [subscriber])

  // session event handlers
  useEffect(() => {
    function handleStreamPropertyChanged(
      event: Event<'streamPropertyChanged', Session> & {
        stream: Stream
      } & (
          | {
              changedProperty: 'hasAudio'
              oldValue: boolean
              newValue: boolean
            }
          | {
              changedProperty: 'hasVideo'
              oldValue: boolean
              newValue: boolean
            }
          | {
              changedProperty: 'videoDimensions'
              oldValue: Dimensions
              newValue: Dimensions
            }
        ),
    ) {
      if (subscriber?.stream?.streamId === event.stream.streamId) {
        console.log(
          'participantVideoSubscriber.streamPropertyChanged',
          event,
          new Date().toString(),
        )
        if (event.changedProperty === 'hasAudio') {
          setState((s) => ({ ...s, hasAudio: event.newValue }))
        }
        if (event.changedProperty === 'hasVideo') {
          setState((s) => ({ ...s, hasVideo: event.newValue }))
        }
      }
    }

    if (subscriber && session) {
      session.on('streamPropertyChanged', handleStreamPropertyChanged)
      return () => {
        session.off('streamPropertyChanged', handleStreamPropertyChanged)
      }
    }
  }, [subscriber, session])

  useEffect(() => {}, [subscriber])

  return (
    <VideoContainer
      id="streamingVideoContainer"
      //Streamer Video Share
      ref={ref}
      sx={sx}
      micMuted={!state.hasAudio}
    >
      {
        // No subscriber present, participant is not publishing to session
        !subscriber && (
          <VideoContainerOverlay sx={{ flexDirection: 'column' }}>
            <Typography gutterBottom fontSize="1em" textAlign="center">
              {!participantConnected
                ? `Waiting for ${
                    participant
                      ? getParticipantName(participant)
                      : 'Participant'
                  } to
            Join`
                : `${
                    participant
                      ? getParticipantName(participant)
                      : 'Participant'
                  } Joined.`}
            </Typography>
            <CircularProgress size="2em" />
          </VideoContainerOverlay>
        )
      }

      {
        //subscriber is connected to session but their audio/video stream has diconnected (happens when they have network issues)
        subscriber && state.disconnected && (
          <VideoContainerOverlay sx={{ flexDirection: 'column' }}>
            <Typography fontSize="1em" textAlign="center">
              {`${getParticipantName(participant)} disconnected`}
            </Typography>
            <CircularProgress size="2em" />
          </VideoContainerOverlay>
        )
      }

      {
        // subscriber has turned off video
        subscriber && !state.disconnected && (
          <VideoDisabledOverlay
            videoDisabled={!state.hasVideo}
            participant={participant}
            avatarProps={{
              sx: {
                boxShadow: (theme) =>
                  `0px 0px ${audioLevel * 30}px ${theme.palette.withAlpha(
                    theme.palette.primary.main,
                    Math.min(audioLevel * 2, 0.8),
                  )}`,
              },
            }}
          />
        )
      }
    </VideoContainer>
  )
})
