import { type Overwrite } from 'utility-types'
import { ClinicalAges } from '../../shared/constants'
import {
  type Reference,
  ResourceType,
  type SearchableReference,
  type SearchableTaskFragment,
  type TaskFragment,
  InputMaybe,
  PatientMetaDataInput,
  CommunityIdentity,
  Ethnicity,
  GenderIdentity,
  LegalSex,
  ProviderServiceType,
  Race,
  DayOfWeek,
} from '../../shared/generated.types'

export const searchableRefToRef = ({
  id,
  ...rest
}: SearchableReference): Reference => ({
  ...rest,
  _id: id || '',
  type: ResourceType.Caremanager, // dummy value, we never use the value here
})

export const refToSearchableRef = ({
  _id,
  type,
  ...rest
}: Reference): SearchableReference => ({
  ...rest,
  id: _id,
})

export const searchableTaskToRegularTask = (
  st: SearchableTaskFragment,
): TaskFragment => ({
  ...st,
  _id: st.id,
  patientId: st.patient?.id || '',
  assignee: searchableRefToRef(st.assignee),
  createdBy: searchableRefToRef(st.createdBy),
  comments: undefined,
})

export const taskToSearchableTask = (
  {
    _id: id,
    assignee,
    createdBy,
    comments,
    ...rest
  }: Omit<TaskFragment, 'patientPreferences'>,
  patient?: InputMaybe<PatientMetaDataInput> | undefined,
): SearchableTaskFragment => ({
  id,
  assignee: refToSearchableRef(assignee),
  createdBy: refToSearchableRef(createdBy),
  patient,
  recentComments: [
    ...(comments?.map((c) => ({ text: c.text, time: c.time })) || []),
  ],
  ...rest,
})

export enum DayPart {
  morning = 'Morning',
  afternoon = 'Afternoon',
  evening = 'Evening',
}

export const PatientPreferencesField = {
  serviceType: 'serviceTypes',
  insurancePlan: 'insurancePlans',
  locations: 'serviceLocations',
  legalSex: 'legalSex',
  genderIdentity: 'genderIdentities',
  race: 'races',
  ethnicity: 'ethnicities',
  languages: 'languages',
  spirituality: 'spiritualities',
  clinicalAges: 'clinicalAges',
  clinicalSpecialties: 'clinicalSpecialties',
  clinicalModalities: 'clinicalModalities',
  communityIdentity: 'communityIdentities',
  time: 'time',
  duration: 'durationMinutes',
  dayOfWeek: 'dayOfWeek',
  dayPart: 'dayPart',
} as const

export type PatientPreferenceValue = {
  [PatientPreferencesField.serviceType]?: ProviderServiceType[]
  [PatientPreferencesField.insurancePlan]?: string[]
  [PatientPreferencesField.locations]?: string[]
  [PatientPreferencesField.legalSex]?: LegalSex[]
  [PatientPreferencesField.genderIdentity]?: GenderIdentity[]
  [PatientPreferencesField.race]?: Race[]
  [PatientPreferencesField.ethnicity]?: Ethnicity[]
  [PatientPreferencesField.languages]?: string[]
  [PatientPreferencesField.spirituality]?: string[]
  [PatientPreferencesField.clinicalAges]?: ClinicalAges[]
  [PatientPreferencesField.clinicalSpecialties]?: string[]
  [PatientPreferencesField.clinicalModalities]?: string[]
  [PatientPreferencesField.communityIdentity]?: CommunityIdentity[]
  [PatientPreferencesField.time]?: string[]
  [PatientPreferencesField.duration]?: number[]
  [PatientPreferencesField.dayOfWeek]?: DayOfWeek[]
  [PatientPreferencesField.dayPart]?: DayPart[]
}

export enum TaskJsonVersion {
  PatientPreferences_V1 = 'patientPreferences_V1',
}

interface ITaskJson {
  type: TaskJsonVersion
  value: any
}

export interface TaskPatientPreferences extends ITaskJson {
  type: TaskJsonVersion.PatientPreferences_V1
  value: PatientPreferenceValue
}

/** to be a union with other types in the future */
export type TaskJSON = TaskPatientPreferences
export const isTaskPatientPreferences = (
  val: TaskJSON | null,
): val is TaskPatientPreferences =>
  val?.type === TaskJsonVersion.PatientPreferences_V1

export const parseTaskJSON = (val?: string | null): TaskJSON | null => {
  if (!val) return null
  try {
    const parsed = JSON.parse(val) as unknown
    if (!parsed || typeof parsed !== 'object' || !('type' in parsed))
      return null

    switch (parsed.type) {
      case TaskJsonVersion.PatientPreferences_V1:
        return parsed as TaskPatientPreferences
      // Other cases to be added in future... i.e.
      default:
        return null
    }
  } catch (e) {
    console.error(e)
    return null
  }
}

export type ParsedTaskFragment = Overwrite<
  TaskFragment,
  {
    patientPreferences: TaskPatientPreferences | null
  }
>

export const parseTaskFragment = ({
  patientPreferences,
  ...rest
}: TaskFragment): ParsedTaskFragment => ({
  ...rest,
  patientPreferences: parseTaskJSON(patientPreferences),
})
