/* eslint no-param-reassign:0 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type CallStatsInfo = {
  callStarted: number | null
  callEnded: number | null
}

export type CallStatsState = {
  [sessionId: string]: CallStatsInfo
}

const initialState: CallStatsState = {}

const callStatsSlice = createSlice({
  name: 'callStats',
  initialState,
  reducers: {
    callStarted: (state, { payload }: PayloadAction<{ sessionId: string }>) => {
      // can only start a call once
      if (!state[payload.sessionId]) {
        state[payload.sessionId] = {
          callStarted: Date.now(),
          callEnded: null,
        }
        return
      }
      // but clear the end if this gets called after a call has started (someone rejoinin)
      state[payload.sessionId]!.callEnded = null
    },
    callEnded: (state, { payload }: PayloadAction<{ sessionId: string }>) => {
      const callStats = state[payload.sessionId]
      // can end only if started and has not already ended
      if (callStats?.callStarted && !callStats?.callEnded) {
        callStats.callEnded = Date.now()
      }
    },
  },
})

export const { actions, reducer, name: reducerPath } = callStatsSlice

export type AppActionPayloads = ReturnType<
  (typeof actions)[keyof typeof actions]
>

export default callStatsSlice
