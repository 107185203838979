import {
  Avatar,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@valerahealth/ui-components'
import { TelehealthParticipant } from 'api'
import { getParticipantInitials, getParticipantName } from './utils'

export type ParticipantAvatarProps = {
  sx?: SxProps<Theme>
  participant: TelehealthParticipant
  showName?: boolean
}
export function ParticipantAvatar({
  participant: { picture, ...rest },
  sx,
  showName,
}: ParticipantAvatarProps) {
  return (
    <Stack gap={0.5} alignItems="center">
      <Avatar src={picture || undefined} sx={sx}>
        {getParticipantInitials(rest)}
      </Avatar>
      {showName && (
        <Typography fontSize="1em" variant="caption" textAlign="center">
          {getParticipantName(rest)}
        </Typography>
      )}
    </Stack>
  )
}
