import { Suspense } from 'react'
import i18next, { InitOptions } from 'i18next'
import {
  I18nextProvider,
  initReactI18next,
  I18nextProviderProps,
} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'
import { makeDebugUtils, type TranslationProviderPropsType } from './utility'

const rountToTwo = (numb: number) =>
  Math.round((numb + Number.EPSILON) * 100) / 100

export function TranslationProvider(props: TranslationProviderPropsType) {
  const {
    ns,
    defaultNS,
    fallbackNS,
    debug,
    children,
    staticResources,
    loadResources,
  } = props
  const i18n = i18next.createInstance()

  makeDebugUtils(props)

  const i18nOptions: InitOptions = {
    // language with country code will always fallback to the base language - i.e. es-AR will fallback to es
    fallbackLng: process.env.IS_LOCAL
      ? undefined // allows us to make sure were definding translations properly, but in non local environments we want fallbacks
      : {
          // we coded zh to be simplified chinese so we are mapping all countries that speak traditional to the specific traditional chinese file
          zh: ['zh-CHS', 'en'],
          'zh-CHT': ['zh-TW', 'zh-CHS', 'en'],
          'zh-Hant': ['zh-TW', 'zh-CHS', 'en'],
          'zh-HK': ['zh-TW', 'zh-CHS', 'en'],
          'zh-MO': ['zh-TW', 'zh-CHS', 'en'],
          default: ['en'],
        },
    ns,
    defaultNS: defaultNS || 'default',
    fallbackNS: fallbackNS || 'default',
    debug,
    partialBundledLanguages: true,
    detection: {
      order: [
        // users own selection gets saved to localstorage
        'localStorage',
        // ?lng=CODE explicity set in url
        'querystring',
        // browser set to another language
        'navigator',
      ],
    },
    cache: ['localStorage'],
    interpolation: {
      escapeValue: false,
    },
    resources: staticResources,
  }

  if (loadResources) i18n.use(resourcesToBackend(loadResources))

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(i18nOptions, (err) => {
      if (err) console.error(err)
    })
  i18n.services.formatter?.add('bytes', (value?: number | null) => {
    if (!value) return ''
    switch (true) {
      case value >= 1099511627776:
        return `${rountToTwo(value / 1099511627776)}TB`
      case value >= 1073741824:
        return `${rountToTwo(value / 1073741824)}GB`
      case value >= 1048576:
        return `${rountToTwo(value / 1048576)}MB`
      case value >= 1024:
        return `${rountToTwo(value / 1024)}KB`
      default:
        return `${value} Bytes`
    }
  })

  const i18nextProviderProp: I18nextProviderProps = {
    children,
    i18n,
    defaultNS,
  }
  return (
    <Suspense fallback="...Loading Translations">
      <I18nextProvider {...i18nextProviderProp}>{children}</I18nextProvider>
    </Suspense>
  )
}
