import {
  reducerPath,
  reducer,
  actions,
  useNotify,
  useDismiss,
} from './reduxSlice'

export { default as Notifications } from './Notifications'
export { useNotify }
export { useDismiss }
export const notificationActions = actions
export const notificationsReducer = {
  [reducerPath]: reducer,
}
