import phone from 'phone'
import { ControlledTextField, type ControlledTextFieldProps } from './TextField'

export type IntlPhoneInputProps = Omit<ControlledTextFieldProps, 'type'>

export function IntlPhoneInput({ validate, ...props }: IntlPhoneInputProps) {
  return (
    <ControlledTextField
      {...props}
      type="text"
      validate={{
        ...(typeof validate === 'function' ? { validate } : validate),
        isValidPhoneNumber: (value) => !value || phone(value).isValid,
      }}
    />
  )
}
