import phone from 'phone'
import { type FilterOptionsState } from '@mui/material'
import {
  PROVIDER_TYPE,
  PayerSummaryFrag,
  type PayerFragment,
  type ProviderMinimalFragment,
} from '@valerahealth/rtk-query'
import { type Overwrite } from 'utility-types'
import { type TimeOption } from './date'

export const removeNonNumbers = (val: any) => String(val).replace(/\D/g, '')

export const removeNonAlphanumeric = (val: string) =>
  String(val)
    .replace(/[^0-9a-z]/gi, '')
    .toLowerCase()

export function formatPhoneNumber(phoneNumberString: any) {
  const cleaned = removeNonNumbers(phoneNumberString)
  const match = cleaned.match(/^(\d{0,3})(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `${match[1] ? `+${match[1]} ` : ''}(${match[2]}) ${match[3]}-${
      match[4]
    }`
  }
  return ''
}

export function maskPhoneNumber(phoneNumberString: any) {
  let cleaned = String(phoneNumberString)
  const hasCountryCode = cleaned.startsWith('+')
  cleaned = removeNonNumbers(cleaned)
  if (hasCountryCode) {
    // remove numbers from the front to remove country code
    cleaned = cleaned.slice(-10)
  }
  if (cleaned.length > 10) {
    // else remove numbers from the back
    cleaned = cleaned.slice(0, 10)
  }
  const chars = `${cleaned}__________`
  return `(${chars.slice(0, 3)}) ${chars.slice(3, 6)}-${chars.slice(6, 10)}`
}

export function parseIntlPhoneNumber(phoneNumber?: string | null) {
  if (!phoneNumber) return ''
  const result = phone(phoneNumber)
  if (!result.isValid) return ''
  if (result.countryIso2 === 'US') {
    // only attempt to mask us phone numbers
    return maskPhoneNumber(result.phoneNumber)
  }
  return result.phoneNumber
}

export function maskSSN(ssnValue: any) {
  let cleaned = String(ssnValue)
  cleaned = removeNonNumbers(cleaned)
  if (cleaned.length > 9) {
    cleaned = cleaned.slice(0, 9)
  }
  const chars = `${cleaned}_________`
  return `${chars.slice(0, 3)}-${chars.slice(3, 5)}-${chars.slice(5, 9)}`
}

export function setIntlPhoneNumber(phoneNumber?: string | null) {
  return (phoneNumber && phone(phoneNumber).phoneNumber) || ''
}

/** takes a phone number with any formatting and returns +1\d{10} */
export function unmaskPhoneNumber(phone: string) {
  const phoneRaw = phone.replace(/[^\d]/g, '')
  return phoneRaw && `+1${phoneRaw.slice(0, 10)}`
}

/* counts the values in an array or object, recursively */
export function recurseCountPrimitives(val: any[] | Record<any, any>): number {
  return Object.values(val).reduce((sum, value) => {
    if (value && typeof value === 'object') {
      return sum + recurseCountPrimitives(value)
    }
    return sum + 1
  }, 0)
}

/** returns true if all entries in filter object are found on target object */
export function objectsOverlap(
  target: Record<string, any>,
  filter: Record<string, any>,
) {
  return Object.entries(filter).every(
    ([key, value]) => key in target && target[key] === value,
  )
}

export function providerFullName(
  fName: string,
  lName: string,
  profession?: string,
) {
  return `${fName} ${lName}${profession ? `, ${profession}` : ''}`
}
export const basicProfileToFullName = ({
  firstName,
  lastName,
  licenseType,
}: {
  firstName: string
  lastName: string
  licenseType?: string
}) => providerFullName(firstName, lastName, licenseType)

export const mapLicenseToType = (licenseType: string) =>
  ['MD', 'NP'].includes(licenseType.toUpperCase())
    ? PROVIDER_TYPE.PRESCRIBER
    : PROVIDER_TYPE.THERAPIST

export function providerToFullName<T extends ProviderMinimalFragment>(
  provider: T,
) {
  return providerFullName(
    provider.firstName ?? '',
    provider.lastName ?? '',
    provider.employment?.profession ?? '',
  )
}

/* takes 'a string' and returns 'A String' */
export function titleCase(string: string) {
  return string
    .split(' ')
    .map((s) => `${s.charAt(0).toUpperCase()}${s.substring(1)}`)
    .join(' ')
}

export function getInitials(firstName: string, lastName: string) {
  return `${firstName[0]}${lastName[0]}`.toUpperCase()
}

export function sortOnProperty<DataType extends {}, Key extends keyof DataType>(
  data: DataType[],
  key: Key,
  order: 'asc' | 'desc' = 'asc',
  // converts a type into a value that can be sorted using ><==
  format?: (v: DataType[Key]) => string | number,
) {
  const flip = order === 'asc' ? 1 : -1

  return data.slice().sort((a, b) => {
    const aVal = format?.(a[key]) || a[key]
    const bVal = format?.(b[key]) || b[key]
    return aVal > bVal ? flip : aVal < bVal ? flip * -1 : 0
  })
}

let id = 0
export function getUniqueId() {
  id += 1
  return id.toString()
}

export const byteScale = {
  kb: 1024,
  mb: 1048576,
  gb: 1073741824,
  tb: 1099511627776,
}

/** takes a number and units and returns the byte value */
export function toBytes(num: number, unit: keyof typeof byteScale) {
  return num * byteScale[unit]
}
const rountToTwo = (numb: number) =>
  Math.round((numb + Number.EPSILON) * 100) / 100

export function bytesToStr(value?: number | null) {
  if (!value) return ''
  switch (true) {
    case value >= byteScale.tb:
      return `${rountToTwo(value / byteScale.tb)}TB`
    case value >= byteScale.gb:
      return `${rountToTwo(value / byteScale.gb)}GB`
    case value >= byteScale.mb:
      return `${rountToTwo(value / byteScale.mb)}MB`
    case value >= byteScale.kb:
      return `${rountToTwo(value / byteScale.kb)}KB`
    default:
      return `${value} Bytes`
  }
}

export function getNDigitsRandomNumber(n = 1) {
  const min = 10 ** (n - 1)
  const max = 10 ** n - 1
  return Math.floor(Math.random() * (max - min) + min)
}

export const isPlainObject = (val: any): val is { [x: string]: unknown } =>
  !!val && typeof val === 'object' && val.constructor === Object

export const isPlainArray = (val: any): val is unknown[] =>
  !!val && typeof val === 'object' && val.constructor === Array

export function removeUndefinedKeysRecursive<T extends Object>(v: T): T {
  return Object.fromEntries(
    Object.entries(v)
      .filter(([, v]) => v !== undefined)
      .map(([k, v]) =>
        isPlainObject(v)
          ? [k, removeUndefinedKeysRecursive(v as Object)]
          : [k, v],
      ),
  ) as T
}

export function emptyStrToNullRecursive<
  T extends { [k: string]: unknown } | unknown[],
>(v: T): T {
  function processValue(v: unknown) {
    return v === ''
      ? null
      : isPlainObject(v) || Array.isArray(v)
      ? emptyStrToNullRecursive(v)
      : v
  }
  return (
    Array.isArray(v)
      ? v.map(processValue)
      : Object.fromEntries(
          Object.entries(v).map(([k, v]) => [k, processValue(v)]),
        )
  ) as T
}

/** iterates through values of objects or arrays recursively and trims any string values */
export function trimStringsRecursive<
  T extends { [k: string]: unknown } | unknown[],
>(v: T): T {
  function processValue(v: unknown) {
    return typeof v === 'string'
      ? v.trim()
      : isPlainObject(v) || Array.isArray(v)
      ? trimStringsRecursive(v)
      : v
  }
  return (
    Array.isArray(v)
      ? v.map(processValue)
      : Object.fromEntries(
          Object.entries(v).map(([k, v]) => [k, processValue(v)]),
        )
  ) as T
}

/** takes each key of defaultData (make sure it has all keys defined!) and if the value at that key is '', null, undefined it returns the default value specified in defaultData, will recurse into sub objects but not arrays */
export function withDefaultValues<
  DataType extends { [x: string | number]: unknown },
  DefaultValueType extends { [x: string | number]: unknown } = DataType,
>(
  data: DataType | null | undefined,
  defaultData: DefaultValueType,
): Overwrite<DataType, DefaultValueType> {
  function processValue(value: unknown, defaultValue: unknown) {
    try {
      return [null, undefined, ''].includes(value as any)
        ? defaultValue
        : isPlainObject(defaultValue)
        ? withDefaultValues(value as DataType, defaultValue as DefaultValueType)
        : value
    } catch (e) {
      console.error(e)
      return value
    }
  }
  return Object.fromEntries(
    Object.entries(defaultData).map(([key, defaultValue]) => [
      key,
      processValue(data?.[key], defaultValue),
    ]),
  ) as Overwrite<DataType, DefaultValueType>
}

/** recursively returns the properties in updated that are different from the original */
export function difference<T extends { [key: string]: unknown }>(
  updated: T,
  original: Partial<T>,
) {
  return Object.entries(updated).reduce((sum, entry) => {
    const [key, value] = entry
    const orig = original[key]
    if (isPlainObject(value) && isPlainObject(orig)) {
      // if they are both plain objects, recurse
      sum[key] = difference(value, orig)
    } else if (
      isPlainArray(value) &&
      isPlainArray(orig) &&
      value.some((v, i) => v !== orig[i])
    ) {
      // arrays where the contents are different based on strict equality
      sum[key] = value
    } else if (value !== original[key]) {
      // if they are different keep the updated value
      sum[key] = value
    }
    return sum
  }, {} as { [key: string]: unknown }) as Partial<T>
}

export function differenceFlow<T extends { [key: string]: unknown }>(
  original: T,
) {
  return function curry(updated: T) {
    return difference(updated, original)
  }
}

export function payerNameWithState(
  payer: PayerFragment | PayerSummaryFrag | undefined,
) {
  return payer ? `${payer.stateCode} - ${payer.name}` : ''
}

export const filterTimeOptions = (
  options: TimeOption[],
  { inputValue }: FilterOptionsState<TimeOption>,
): TimeOption[] => {
  if (!inputValue) return options
  const filterValue = inputValue.replace(/[^0-9a-z]/gi, '').toLowerCase()
  return options.filter(({ display }) => {
    return display
      .replace(/[^0-9a-z]/gi, '')
      .toLowerCase()
      .includes(filterValue)
  })
}

export const formatCurrency = (
  value: string | number,
  options?: Intl.NumberFormatOptions & {
    trailingZeroDisplay?: 'auto' | 'stripIfInteger'
  },
  locales?: string | string[],
): string | null => {
  if (!value) return null

  const { trailingZeroDisplay, currencySign, ...otherOptions } = options || {}

  const config = new Intl.NumberFormat(locales || 'en-US', {
    style: 'currency',
    currency: 'USD',
    ...otherOptions,
  })

  const parts = config.formatToParts(
    typeof value !== 'number' ? Number(value) : value,
  )

  const types: string[] = []
  if (currencySign === undefined) types.push('currency')
  if (trailingZeroDisplay === 'stripIfInteger') {
    const fraction = Number(parts.find((p) => p.type === 'fraction')?.value)
    if (fraction === 0) types.push(...['decimal', 'fraction'])
  }

  return parts
    .filter((p) => !types.includes(p.type))
    .map((p) => p.value)
    .join('')
}
