import { type ReadCallback, type Resource, type ResourceKey } from 'i18next'
import en from './locales/en'
import es from './locales/es'
import zhCHS from './locales/zh-CHS'
import zhTW from './locales/zh-TW'

export {
  Trans,
  Translation,
  useTranslation,
  withTranslation,
} from 'react-i18next'

export type TranslationProviderPropsType = {
  ns?: Array<string>
  defaultNS?: string
  fallbackNS?: string | string[]
  children: React.ReactNode
  /** can lazy load imports for other languages */
  loadResources?: (
    language: string,
    namespace: string,
    callback?: ReadCallback,
  ) => Promise<ResourceKey | boolean | null | undefined> | void
  /** always pass english as static language */
  staticResources: Resource
  debug?: boolean
}

/* prevents one from adding translations already present in the common locals exported by this package */
export type NonCommonTranslations = {
  [key in keyof typeof en]?: never
} & Record<string, any>

/** deprecate - use common translations as a fallback namespace instead */
export function withCommonEnTranslations(translations: NonCommonTranslations) {
  return {
    ...translations,
    ...en,
  }
}

/** deprecate - use common translations as a fallback namespace instead */
export function withCommonEsTranslations(translations: NonCommonTranslations) {
  return {
    ...translations,
    ...es,
  }
}

/** deprecate - use common translations as a fallback namespace instead */
export function withCommonZhCHSTranslations(
  translations: NonCommonTranslations,
) {
  return {
    ...translations,
    ...zhCHS,
  }
}

/** deprecate - use common translations as a fallback namespace instead */
export function withCommonZhTWTranslations(
  translations: NonCommonTranslations,
) {
  return {
    ...translations,
    ...zhTW,
  }
}

export function get(v: StringMap, path: string[]) {
  let value: StringMap | string | undefined = v
  for (const key of path) {
    if (typeof value !== 'object' || value === null) {
      value = undefined
      break
    }
    value = value[key]
  }
  return value
}

export function set(obj: StringMap, val: string, path: string[]) {
  path.reduce((sum, key, i) => {
    if (i === path.length - 1) {
      sum[key] = val
      return sum
    }
    sum[key] = typeof sum[key] === 'object' ? sum[key]! : {}
    return sum[key] as StringMap
  }, obj)
  return obj
}
type StringMap = { [x: string]: string | StringMap }
declare global {
  interface Window {
    VAH_COMPILE_MISSING_TRANSLATIONS: (
      locales?: string[],
    ) => Promise<StringMap | undefined>
  }
}

export const makeDebugUtils = (
  options: Partial<TranslationProviderPropsType>,
) => {
  window.VAH_COMPILE_MISSING_TRANSLATIONS = async (locales) => {
    if (!locales) {
      console.log('Function requires a list of locales as the argument')
    } else {
      const promises: any[] = []
      const missing: StringMap = {}
      if (options.staticResources?.en) {
        Object.entries(options.staticResources.en).forEach(
          ([namespace, translations]) => {
            locales.forEach(async (locale) => {
              function reduceKey(
                prefix: string,
                value: StringMap,
                lng: StringMap,
              ) {
                Object.entries(value).forEach(([key, value]) => {
                  if (typeof value !== 'string') {
                    reduceKey(`${prefix}${key}.`, value, lng)
                  } else {
                    const tval = get(lng, `${prefix}${key}`.split('.'))
                    if (!tval) {
                      set(missing, value, [
                        namespace,
                        locale,
                        ...`${prefix}${key}`.split('.'),
                      ])
                    }
                  }
                })
              }
              const lng =
                options.staticResources?.[locale]?.[namespace] ||
                new Promise<boolean | ResourceKey | null | undefined>((res) => {
                  if (typeof options.loadResources !== 'function') {
                    res(undefined)
                  } else {
                    res(options.loadResources(locale, namespace)!)
                  }
                })
              if (lng) {
                console.log(
                  `\tCompiling translations for namespace: ${namespace}, locale:${locale}`,
                )
                promises.push(lng)
                reduceKey(
                  '',
                  translations as StringMap,
                  (await lng) as StringMap,
                )
              } else {
                console.log(
                  `No translation file found for ${locale} ${namespace}`,
                )
              }
            })
          },
        )
        return Promise.all(promises).then(() => {
          console.log(missing)
          return missing
        })
      }
    }
  }
}
