import { useLayoutEffect } from 'react'
import { useReduxDispatch } from 'store'
import { actions } from 'store/appSlice'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@valerahealth/ui-components'
import { useNavigate } from 'react-router-dom'
import { getRoutePaths } from '../utils'
import { PatientSignInForm } from './Form'

export default function PatientSignInModal() {
  const dispatch = useReduxDispatch()

  const navigate = useNavigate()

  const {
    rid: roomId,
    t: token,
    pid: patientId,
    sid: sessionId,
  } = Object.fromEntries(new URLSearchParams(window.location.search).entries())

  useLayoutEffect(() => {
    // if auth requirements are met, save to redux
    if (token && roomId && patientId) {
      dispatch(
        actions.setPatientAuth({
          roomId,
          patientId,
          token,
        }),
      )
    }

    // if all requiremenets are met go directly to waiting room
    if (roomId && token && patientId && sessionId) {
      navigate(
        getRoutePaths({
          roomId,
          sessionId,
        }).patientWaitingRoom,
      )
    }
  }, [roomId, token, patientId, sessionId, dispatch, navigate])

  if (!roomId)
    return (
      <Dialog open hideBackdrop>
        <DialogTitle>Error</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            The URL is invalid. Please try relaunching the video session.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )

  return <PatientSignInForm roomId={roomId} />
}
