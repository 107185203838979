import { createSelector } from 'reselect'
import { RootState } from 'store'
import {
  CALL_CREDENTIALS_FIXED_CACHE_KEY,
  TelehealthCallInfo,
  telehealthApi,
} from 'api'
import { CallStatsInfo } from './callStatsSlice'

export const selectUserId = (state: RootState) =>
  state.auth.session?.user.careManagerId || state.app.patientAuth?.patientId

/** assumption is this is only ever called once */
export const selectCallInfo = createSelector(
  (state: RootState): string | undefined =>
    telehealthApi.util.selectInvalidatedBy(state, ['ROOM_INFO'])[0]
      ?.queryCacheKey,
  (state: RootState) => state,
  (cacheKey, state) => {
    if (!cacheKey) return null
    return (state.telehealthApi.queries[cacheKey]?.data ??
      null) as TelehealthCallInfo | null
  },
)

/** assumption is this is only ever called once */
export const selectCallCredentials = createSelector(
  telehealthApi.endpoints.getCallCredentials.select({
    fixedCacheKey: CALL_CREDENTIALS_FIXED_CACHE_KEY,
    requestId: undefined,
  }),
  ({ data }) => {
    return data
  },
)

export const selectOwnParticipantInfo = createSelector(
  selectCallInfo,
  selectUserId,
  (callInfo, userId) => {
    return callInfo?.participants.find((p) => p.id === userId)
  },
)

export const selectParticipantInfo = createSelector(
  selectCallInfo,
  selectUserId,
  (callInfo, userId) => {
    return callInfo?.participants.find((p) => p.id !== userId)
  },
)

export const selectMicSources = createSelector(
  ({ app }: RootState) => app.videoState.audioVideoSources,
  (devices) =>
    devices
      .filter((v) => v.kind === 'audioinput')
      .sort((a, b) => a.label.localeCompare(b.label)),
)

export const selectVideoSources = createSelector(
  ({ app }: RootState) => app.videoState.audioVideoSources,
  (devices) =>
    devices
      .filter((v) => v.kind === 'videoinput')
      .sort((a, b) => a.label.localeCompare(b.label)),
)

export const selectSpeakerSources = createSelector(
  ({ app }: RootState) => app.videoState.audioVideoSources,
  (devices) =>
    devices
      .filter((v) => v.kind === 'audiooutput')
      .sort((a, b) => a.label.localeCompare(b.label)),
)

export const selectCurrentMicSrcOption = createSelector(
  ({ app }: RootState) => app.videoState.micSourceId,
  selectMicSources,
  (id, options) => options.find((v) => v.deviceId === id) ?? null,
)

export const selectCurrentVideoSrcOption = createSelector(
  ({ app }: RootState) => app.videoState.videoSourceId,
  selectVideoSources,
  (id, options) => options.find((v) => v.deviceId === id) ?? null,
)

export const selectCurrentSpeakerSrcOption = createSelector(
  ({ app }: RootState) => app.videoState.speakerSourceId,
  selectSpeakerSources,
  (id, options) => options.find((v) => v.deviceId === id) ?? null,
)

const defaultCallStats: CallStatsInfo = {
  callStarted: null,
  callEnded: null,
}

export const selectCallStats = createSelector(
  ({ callStats }: RootState) => callStats,
  (state: RootState, sessionId: string) => sessionId,
  (callStats, sessionId) => callStats[sessionId] || defaultCallStats,
)

export const selectCallDuration = createSelector(
  selectCallCredentials,
  (state: RootState) => state.callStats,
  (callInfo, stats) => {
    if (!callInfo) return 0
    const { callStarted, callEnded } = stats[callInfo.sessionId] || {}
    if (!callStarted || !callEnded) return 0
    return callEnded - callStarted
  },
)
